import axios from "axios"

import { NotificationModel } from '../../types'

export const APP_URL = process.env.REACT_APP_API_URL

export async function getUserNotifications(userId: number) {
  const API_URL = `${APP_URL}/user/${userId}/notifications`

  return await axios.get(API_URL)
}

export async function markAsReadNotification(notification: NotificationModel) {
  const API_URL = `${APP_URL}/notification/${notification.id}/${!!notification.read_at ? 'mark-as-unread' : 'mark-as-read'}`

  return await axios.put(API_URL)
}

export async function deleteNotification(notificationId: number) {
  const API_URL = `${APP_URL}/notification/${notificationId}/delete`
  const notifications: NotificationModel[] = await axios.delete(API_URL)

  return notifications
}