import React from 'react'
import { useLocation } from 'react-router'
import clsx from 'clsx'
import { checkIsActive, KTSVG } from '../../../helpers'
import { Link } from 'react-router-dom'

type Props = {
  to: string
  title: string
  icon?: string
  target?: string
  fontIcon?: string
  hasArrow?: boolean
  hasBullet?: boolean,
  data?: string
}

const MenuItem: React.FC<Props> = ({
  to,
  title,
  icon,
  target,
  fontIcon,
  hasArrow = false,
  hasBullet = false,
  data
}) => {
  const { pathname } = useLocation()

  return (
    <div className='menu-item me-lg-1'>
      {
        target === "_blank" ?
          <form target='_blank' action={to} method="post">
            <input style={{ display: 'none' }} placeholder='hello' type="text" name="FromCustomerIdShipping" value={data} />
            <input className='menu-link py-3' type="submit" value={title} />
          </form> :
          <Link
            to={to}
            className={clsx('menu-link py-3', {
              active: checkIsActive(pathname, to),
            })}
            target={target}
          >
            {hasBullet && (
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
            )}

            {icon && (
              <span className='menu-icon'>
                <KTSVG path={icon} className='svg-icon-2' />
              </span>
            )}

            {fontIcon && (
              <span className='menu-icon'>
                <i className={clsx('bi fs-3', fontIcon)}></i>
              </span>
            )}

            <span className='menu-title'>{title}</span>

            {hasArrow && <span className='menu-arrow'></span>}
          </Link>
      }
    </div>
  )
}

export { MenuItem }
