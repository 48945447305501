import React, { useState, FC, useEffect } from 'react'

type Props = {
  data: { name: string; code: string }[]
  defaultValue: string
  dirUp: boolean
  selectedValue: (value: string) => void
  disable?: boolean
  isSearchable?: boolean
  showButton?: boolean;
  onShowButtonClick?: () => void; 
}

export const CustomDropDown: FC<Props> = ({
  defaultValue,
  dirUp,
  selectedValue,
  disable,
  isSearchable,
  showButton = false,
  onShowButtonClick,
  ...props
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [selected, setSelected] = useState<string | undefined>('');
  const [keywords, setKeywords] = useState<string>('');
  const [data, setData] = useState<any>(props.data);

  const handleSelect = (item: { name: string; code: string }) => {
    selectedValue(item.code);
    setSelected(item.name);
    setShow(false);
  };

  const handleSearch = (e: any) => {
    const keywords = e.target.value;
    setKeywords(keywords);
    setData(props.data.filter(({ name }) => name.toUpperCase().includes(keywords.toUpperCase())));
  };
  useEffect(() => {
    // This is so the props data refreshes after new items have been added
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
    const defaultState = data.find((item: { code: string }) => item.code === defaultValue);
    setSelected(defaultState?.name);
  }, [data, defaultValue]);

  return (
    <div className={`position-relative select2-body ${disable && 'pe-none'}`}>
      <span className='select2-container' onClick={() => setShow(!show)}>
        <span className='select2-selection select2-selection--single form-select form-select-solid min-h-43px fw-bolder'>
          <div className='select2-search select2-search--dropdown'>{selected}</div>
        </span>
      </span>
      {show && (
        <div
          className={`select2-container select2-container--bootstrap5 search-dropdown-container ${
            dirUp && 'bottom-100'
          }`}
        >
          <div className='select2-dropdown'>
            {isSearchable && (
              <div className='select2-search select2-search--dropdown px-2'>
                <input
                  type='text'
                  className='select2-search__field w-100'
                  value={keywords}
                  onChange={handleSearch}
                />
              </div>
            )}
            <span className='select2-results'>
              <ul className='select2-results__options'>
                {/* Button to create a new billing company*/}
                {showButton && (
                  <li className='select2-results__option select2-results__option--selectable'>
                    <button type='button' className='btn btn-primary' onClick={onShowButtonClick} >
                      Create new billing company
                    </button>
                  </li>
                )}
                {data?.map((item: { code: string; name: string }, index: any) => (
                  <li
                    key={index}
                    className={`${
                      selected === item.name && 'select2-results__option--selected'
                    } select2-results__option select2-results__option--selectable`}
                    onClick={() => handleSelect(item)}
                  >
                    {item.name}
                  </li>
                ))}
              </ul>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
