import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../setup';
import { Accordion } from 'react-bootstrap-v5'
import { Customer, UserModel, BillingCompany } from '../../../../types'
import { getAllCustomerLocationUpdate, getRoutingInfo, getAllCustomerIds } from '../TerritoryMapCrud';
import CustomToggle from '../../../components/CustomToggle';
import { KTSVG } from '../../../../_metronic/helpers';
import { SearchDropDown as CustomerSearchDropDown} from '../../../components/SearchDropDown';
import DatePicker from 'react-datepicker'
import ShowPinsToggle from './showPinsCheckBox'

const updateAllCustomerLatitudeLongitude = async () => //DANGEROUS, should only be used once.
{
  //TODO: pull all customers and update their latitude, longitute and LatitudeLongitudeUpdated on the SQL.
  const googleResponse = await (getAllCustomerLocationUpdate());
}

const getRoutingInfoFromBackend = async () =>
  {
    const testRoute = {
      origin: "40.1739,-83.5274",
      destination: "44.9375,-93.2010",
      waypoints: ["41.5868,-93.6250", "39.7817,-89.6501"] // Add waypoints if needed
    };

    const googleResponse = await(getRoutingInfo(testRoute))

    console.log("Test Route: ", googleResponse)
  }


interface mapFilterProps {
  isDev?: boolean,
  showPins:boolean,
  setShowPins:(checked: boolean) => void;
  colorOptions: {colorRange:number, colorDomain:number},
  changeColorRange?: (newOption: number) => Promise<void>;
  changeColorMode?: (newOption: number) => Promise<void>;
  handleCancelCustomer: () => void
  handleFilter: (filters: any, fromSearch: boolean) => Promise<void>;
}

const MapFilter: React.FC<mapFilterProps> = ({ 
  isDev=false,
  showPins,
  changeColorRange,
  changeColorMode,
  setShowPins,
  handleCancelCustomer,
  handleFilter,
}) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const [status, setStatus] = useState<string>('active');
  const [isReset, setIsReset] = useState<boolean>(false)
  const [isNBS, setIsNBS] = useState<boolean>(false)
  const [customers, setCustomers] = useState<Customer[]>([{ id: '', name: '' }])
  const [startDate, setStartDate] = useState<any>('')
  const [endDate, setEndDate] = useState<any>()
  const [selectedCustomers, setSelectedCustomers] = useState<
    { id: string; name: string | undefined }[]
  >([])
  const handleStatusChange = (newStatus: string) => {
    setStatus(newStatus);
  };
  const handleCustomeCancel = (customerId: string) => {
    setSelectedCustomers(selectedCustomers.filter((customer) => customer.id !== customerId))
    handleCancelCustomer()
  }
  const handleCustomerSelect = (customer: { id: string; name: string | undefined }) => {
    const isExist = selectedCustomers.find((c) => c.id === customer.id)

    if (!isExist) setSelectedCustomers([...selectedCustomers, ...[customer]])
    setIsReset(false)
  }
  const handleReject = () => {
    setSelectedCustomers([])
    setStartDate("")
    setEndDate("")
    setIsReset(true)
    setShowPins(false)

    handleFilter({ status },false)
  }
  useEffect(() => {
    const fetchCustomerIds = async () => {
      const customerIds = await getAllCustomerIds();
      console.log(customerIds);
      const transformedCustomers = customerIds.data.map((customer: any) => ({
        id: customer.customerId,
        name: customer.companyName
      }));

      setCustomers(transformedCustomers);
      
    };
  
    fetchCustomerIds();
  }, []);
 
  return (
    <div className="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
      </div>
      <div className='separator border-gray-200'></div>
      <div className='px-7 py-5'>
        <Accordion defaultActiveKey='0'>
          <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
            <CustomToggle eventKey='filter-0'>Customer ID</CustomToggle>
          </div>
          <Accordion.Collapse eventKey='filter-0' className='ms-8'>
            <>
              <div>
                {selectedCustomers?.map((customer, index) => {
                  return (
                    <div
                      key={index}
                      className='d-flex justify-content-between px-3 py-2 background-light-dark my-2 cursor-pointer'
                    >
                      <span>
                        {customer.id} - {customer?.name}
                      </span>
                      <span
                        className='text-hover-primary cursor-pointer'
                        onClick={() => handleCustomeCancel(customer.id)}
                      >
                        <KTSVG
                          path='/media/icons/duotone/Navigation/Close.svg'
                          className='svg-icon-1'
                        />
                      </span>
                    </div>
                  )
                })}
              </div>
              <CustomerSearchDropDown
                data={customers}
                edit={isReset ? 'reset' : ''}
                isNBS={isNBS}
                selectedValue={(customer) => handleCustomerSelect(customer)}
              />
            </>
          </Accordion.Collapse>
          <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
            <CustomToggle eventKey='filter-1'>Active Date</CustomToggle>
          </div>
          <Accordion.Collapse eventKey='filter-1'>
            <>
              <div className='mb-10'>
                <label className='form-label fw-bold'>From:</label>
                <DatePicker
                  className='form-control'
                  placeholderText='Select a date'
                  selected={startDate}
                  dateFormat='MM/dd/yy hh:mma'
                  showTimeSelect
                  onChange={(date) => {
                    setStartDate(date)
                  }}
                />
              </div>
              <div className='mb-10'>
                <label className='form-label fw-bold'>To:</label>
                <DatePicker
                  className='form-control'
                  placeholderText='Select a date'
                  selected={endDate}
                  dateFormat='MM/dd/yy hh:mma'
                  showTimeSelect
                  onChange={(date) => setEndDate(date)}
                />
              </div>
            </>
          </Accordion.Collapse>
        </Accordion>
      </div>
      <div className='separator border-gray-200'></div>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Map Options</div>
      </div>
      <div className='separator border-gray-200'></div>
      <div className='px-7 py-5'>
        <Accordion defaultActiveKey='map-1'>
          <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
            <CustomToggle eventKey='map-0'>Coloring Options</CustomToggle>
          </div>
          <Accordion.Collapse eventKey='map-0'>
            <>
              {/* Add your Color Options content here */}
              <div style={{ whiteSpace: 'pre-line' }}>
                {`Coloring Options ~Coming Soon~
                
                 These modes will change what range of colors is used for the heatmap.
                 Currently the only color option available is Yellow-Blue-Red, which should be as colorblind friendly as possible!
                 Let us know if you have issues with this coloring scheme!
                `}
              </div>
            </>
          </Accordion.Collapse>
          <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
            <CustomToggle eventKey='map-3'>Color Modes</CustomToggle>
          </div>
          <Accordion.Collapse eventKey='map-3'>
            <>
              {/* Add your Color Options content here */}
              <div style={{ whiteSpace: 'pre-line' }}>
                {`Color Modes ~Coming Soon~
                
                These modes will change how the coloring is calculated.
                Currently the only mode is based on State Rev / Average`}
              </div>
            </>
          </Accordion.Collapse>
          <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
            <CustomToggle eventKey='map-1'>Show Pins</CustomToggle>
          </div>
          <Accordion.Collapse eventKey='map-1'>
            <>
              <div> <ShowPinsToggle isChecked={showPins} handleChange={setShowPins} /> </div>
            </>
          </Accordion.Collapse>
        </Accordion>
      </div>
      <div className='separator border-gray-200'></div>
      <div className='d-flex justify-content-end px-7 py-5'>
        <button
          type='reset'
          className='btn btn-sm btn-white btn-active-light-primary me-2'
          data-kt-menu-dismiss='true'
          onClick={handleReject}
        >
          Reset
        </button>
        {isDev && (
          <button className='btn btn-sm btn-primary' onClick={updateAllCustomerLatitudeLongitude}>
            Load Customer Lat Lon
          </button>
        )}
        {isDev && (
          <button className='btn btn-sm btn-primary' onClick={getRoutingInfoFromBackend}>
            Get Routing Info.
          </button>
        )}
        <button
          className='btn btn-sm btn-primary'
          style={{ marginLeft: '10px' }}
          onClick={() => handleFilter({ startDate, endDate, selectedCustomers }, false)}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default MapFilter;