import React, { useState, useEffect } from 'react'
import { exportAssets } from '../AssetManagementCrud'
import { Modal } from 'react-bootstrap-v5'
import { KTSVG } from '../../../../_metronic/helpers'
import { ExtensionDropDown } from './ExtensionDropDown'
import { getAllCustomerIds } from '../AssetManagementCrud'
import { SearchDropDown } from './SearchDropDown'
import moment from 'moment'

type Props = {
  show: boolean
  isNBS: boolean
  customerProp: { id: string; name: string | undefined }[]
  onHide: any
  getAssets: () => void
  extensions: string[]
}

const ExportAssetsModal: React.FC<Props> = (props) => {
  const { show, isNBS, customerProp, onHide, extensions } = props
  const [extension, setExtension] = useState<string>(extensions[0])
  const [customerId, setCustomerId] = useState<{ id: string; name: string | undefined }>({
    id: '',
    name: '',
  })
  const [customers, setCustomers] = useState<{ id: string; name: string | undefined }[]>([])
  const [validate, setValidate] = useState<boolean>(false)
  const [submit, setSubmit] = useState<boolean>(false)

  const handleSubmit = () => {
    setValidate(false)

    if (customerId.id === '') return setValidate(true)

    setSubmit(true)

    const exportSetting = {
      customer_id: customerId.id,
      extension: extension,
    }

    exportAssets(exportSetting).then((res) => {
      let url = window.URL.createObjectURL(res.data)
      let a = document.createElement('a')
      a.href = url
      a.download = `${customerId.id} Assets ${moment().format('YYYY-MM-DD')}.${
        extension === 'CSV' ? 'csv' : 'xlsx'
      }`
      a.click()
      setSubmit(false)
      onHide(false)
    })
  }

  const handleSelectExtension = (eqType: string) => {
    setExtension(eqType)
  }

  const selectCustomer = (value: { id: string; name: string | undefined }) => {
    console.log(value)
    setCustomerId(value)
  }

  useEffect(() => {
    if (isNBS)
      getAllCustomerIds().then((res) => {
        setCustomers(res.data)
      })
    else {
      setCustomers(customerProp)
    }
  }, [isNBS, customerProp])

  return (
    <Modal
      size='lg'
      show={show}
      dialogClassName='w-50'
      aria-labelledby='contained-modal-title-vcenter'
      onHide={onHide}
      centered
    >
      <Modal.Header>
        <Modal.Title className='w-100' id='contained-modal-title-vcenter'>
          <div className='d-flex justify-content-between'>
            <h2>Export Assets</h2>
            <span className='text-hover-primary cursor-pointer' onClick={onHide}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='pt-10 pb-15 px-lg-17'>
        <div className='d-flex flex-column scroll-y me-n7 pe-7 h-250px max-height-60'>
          <div className='fv-row mb-7 fv-plugins-icon-container'>
            <label className='required fw-bold fs-6 mb-2'>Customer ID</label>
            <SearchDropDown data={customers} edit='' isNBS={false} selectedValue={selectCustomer} />
          </div>
          <div className='fv-row mb-7 fv-plugins-icon-container'>
            <label className='fw-bold fs-6 mb-2 pt-1 required'>Select Export Format: </label>
            <ExtensionDropDown
              extension={extension}
              extensions={extensions}
              selectExtension={handleSelectExtension}
            />
          </div>
        </div>
        <div className={`fv-row mb-7 fv-plugins-icon-container ${validate ? 'd-block' : 'd-none'}`}>
          <label className='fs-6 mb-2 pt-1 text-danger'>
            Customer id was not selected, please select customer id.
          </label>
        </div>
        <div className='text-center pt-15'>
          <button type='button' className='btn btn-light me-3' onClick={() => onHide(true)}>
            Discard
          </button>
          <button
            type='submit'
            className={`btn ${submit ? 'btn-light' : 'btn-primary'}`}
            onClick={handleSubmit}
          >
            Submit
            {submit && (
              <div className='spinner-border w-17px h-17px ms-2 text-primary' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export { ExportAssetsModal }
