import React, { useState, useEffect, FC } from 'react'
import { Customer } from '../../../../types'

type Props = {
  data: Customer[]
  edit: string
  isNBS: boolean
  selectedValue: (values: Customer) => void
}

const SearchDropDown: FC<Props> = (props) => {
  const [show, setShow] = useState<boolean>(false)
  const [data, setData] = useState<Customer[]>(props.data)
  const [searchValue, setSearchValue] = useState<string>('')
  const [selected, setSelected] = useState<Customer | undefined>({ id: '', name: '' })

  const handleSearch = (e: any) => {
    const input = e.target.value?.toUpperCase()

    setShow(true)
    setSearchValue(e.target.value)
    setData(
      props.data?.filter((value) => {
        return `${value.id} - ${value.name}`.toUpperCase().includes(input) === true
      })
    )
  }

  const handleSelect = (item: Customer) => {
    props.selectedValue(item)
    setSelected(item)
    setShow(false)
    setSearchValue(`${item.id} - ${item.name}`)
  }

  useEffect(() => {
    setData(props.data)

    if (props.edit !== '') {
      const editCustomer = props.data.find((customer: Customer) => customer.id === props.edit)

      setSelected(editCustomer)
      setSearchValue(`${editCustomer?.id} - ${editCustomer?.name}`)
    }
  }, [props])

  return (
    <div className='position-relative'>
      <span className='select2-container' onClick={() => setShow(!show)}>
        <span className='select2-selection select2-selection--single form-select form-select-solid fw-bolder'>
          <div className='select2-search select2-search--dropdown'>
            <input
              type='text'
              className='select2-search__field form-control form-control-solid w-100'
              value={searchValue}
              onChange={handleSearch}
            />
          </div>
        </span>
      </span>
      <div
        className={`select2-container select2-container--bootstrap5 search-dropdown-container ${
          !show && 'd-none'
        }`}
      >
        <div className='select2-dropdown'>
          <span className='select2-results'>
            <ul className='select2-results__options'>
              {data.length === 0 ? (
                <li key='empty' className='select2-results__option'>
                  No Customer
                </li>
              ) : (
                data?.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className={`${
                        selected?.id === item.id && 'select2-results__option--selected'
                      } select2-results__option select2-results__option--selectable`}
                      onClick={() => handleSelect(item)}
                    >
                      {item.id} - {item?.name}
                    </li>
                  )
                })
              )}
            </ul>
          </span>
        </div>
      </div>
    </div>
  )
}

export { SearchDropDown }
