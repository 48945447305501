export const getResourceName = (resource: string):string => {
  if (!resource) return '';

  const piece = resource.split('/')

  const resourceName = piece[piece.length - 1]

  return resourceName
}

export const getResourcePath = (resource: string):string[] => {
  if (!resource) return [];

  let directories = resource.split('/')
  let realPath: string[] = []

  directories.forEach((directory: string, index: number) => {
    if (index !== directories.length)
      realPath.push(directory)
  });

  return realPath
}

export const convertByteTo = (bytes: number, decimals = 2):string => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}