import { Field, Form, Formik } from 'formik'
import React, { useMemo } from 'react'
import * as Yup from 'yup'
import { ContactModel } from '../../../../types'
import { editNBSContacts, getNBSContacts } from '../ContactsCRUD'
import toast from 'react-hot-toast'
import { getErrorMessage } from '../../../../helper'
import * as contactRedux from '../ContactsRedux'
import { useDispatch } from 'react-redux'
import { TextField } from '@material-ui/core'

type Props = {
  type: 'New' | 'Edit' | 'View' | 'Delete' | 'None'
  contacts: ContactModel | {}
  handleClose: () => void
}

const NBSContactEditSchema = Yup.object().shape({
  FirstName: Yup.string()
    .required('First Name is required')
    .max(20, 'First Name must be less than 21 characters'),
  LastName: Yup.string()
    .required('Last Name is required')
    .max(20, 'Last Name must be less than 20 characters'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required')
    .max(50, 'Email must be less than 51 characters'),
  Phone: Yup.string().max(20, 'Phone must be less than 21 characters'),
  Title: Yup.string().max(25, 'Title must be less than 26 characters'),
  // Additional fields...
})

const ContactContent = (props: Props) => {
  const { type, contacts, handleClose } = props
  const dispatch = useDispatch()
  const initialValue: ContactModel | any = useMemo(() => {
    switch (type) {
      case 'Edit':
      case 'View':
        return { ...contacts }
        break
      case 'New':
        return {
          ...contacts,
          email: '',
          FirstName: '',
          LastName: '',
          Phone: '',
          Title: '',
        }
      default:
        break
    }
  }, [type, contacts])

  const handleSubmit = (data: ContactModel) => {
    console.log(data)
    editNBSContacts(data)
      .then((res) => {
        handleClose()
        dispatch(contactRedux.actions.getNBSContacts(data.CustomerID))
      })
      .catch((err) => {
        toast.error(getErrorMessage(err))
      })
      .finally()
  }
  return (
    <Formik
      validationSchema={NBSContactEditSchema}
      onSubmit={handleSubmit}
      initialValues={initialValue}
    >
      {({ handleSubmit, setFieldValue, errors, touched, values }) => (
        <Form>
          {/* <Field as='input' name='dbkey' className='d-none' /> */}
          <div className='d-flex flex-column scroll-y me-n7 pe-7 max-height-60'>
            <div className='row fv-plugins-icon-container'>
              <div className='col-lg-12 mb-5'>
                <label className='fw-bold fs-6 required mb-2'>First Name</label>
                <Field
                  as='input'
                  name='FirstName'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  disabled={type === 'View'}
                  //   readOnly={false}
                />
                {touched.FirstName && errors.FirstName ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.FirstName}</div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className='row fv-plugins-icon-container'>
              <div className='col-lg-12 mb-5'>
                <label className='fw-bold fs-6 required mb-2'>Last Name</label>
                <Field
                  as='input'
                  name='LastName'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  disabled={type === 'View'}

                  //   readOnly={false}
                />{' '}
                {touched.LastName && errors.LastName ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.LastName}</div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className='row fv-plugins-icon-container'>
              <div className='col-lg-12 mb-5'>
                <label className='fw-bold fs-6 mb-2'>Email</label>
                <Field
                  as='input'
                  name='email'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  disabled={type === 'View'}

                  //   readOnly={false}
                />{' '}
                {touched.email && errors.email ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.email}</div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className='row fv-plugins-icon-container'>
              <div className='col-lg-12 mb-5'>
                <label className='fw-bold fs-6 mb-2'>Phone</label>
                <Field
                  as='input'
                  name='Phone'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  disabled={type === 'View'}

                  //   readOnly={false}
                />{' '}
                {touched.Phone && errors.Phone ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.Phone}</div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className='row fv-plugins-icon-container'>
              <div className='col-lg-12 mb-5'>
                <label className='fw-bold fs-6 mb-2'>Title</label>
                <Field
                  as='input'
                  name='Title'
                  className='form-control form-control-solid mb-3 mb-lg-0'
                  disabled={type === 'View'}

                  //   readOnly={false}
                />{' '}
                {touched.Title && errors.Title ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.Title}</div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className='text-center pt-15'>
            <button type='button' className='btn btn-light me-3' onClick={handleClose}>
              Discard
            </button>
            {type !== 'View' && (
              <button type='submit' className='btn btn-primary'>
                Submit
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ContactContent
