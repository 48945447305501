import React from 'react'
import { BillingCompany } from '../../../../types'

type Props = {
  headers: string[]
  row: BillingCompany
}

const Table: React.FC<Props> = ({ headers, row }) => {
  return (
    <table className='table table-row-dashed table-row-gray-300 align-middle  gs-0 gy-4'>
      <thead>
        <tr className='fw-bold fs-6'>
          {headers.map((header, index) => {
            return <th key={index}>{header}</th>
          })}
        </tr>
      </thead>
      <tbody>
        {row && (
          <tr>
            <td>{row.BillingCompanyID}</td>
            <td>{row.CompanyName}</td>
            <td>{row.BillAttn_email}</td>
            <td>{row.BillPhone}</td>
            <td>{row.BillFax}</td>
            <td>{row.BillAddress}</td>
            <td>{row.BillCity}</td>
            <td>{row.BillState}</td>
            <td>{row.BillZip}</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default Table
