import React from 'react'
import PropTypes from 'prop-types'
import { default as ReactSelect } from 'react-select'

const MySelect = (props) => {
  let customerIdsOptions = []
  let initialValue = []
  if (props.options && props.options.length) {
    customerIdsOptions = props.options.map((id) => ({
      value: id.customerId,
      label: `${id.customerId} - ${id.companyName}`,
    }))

    props.options.forEach((id) => {
      if (props.value.includes(id.customerId)) {
        initialValue.push({
          value: id.customerId,
          label: `${id.customerId} - ${id.companyName}`,
        })
      }
    })
  }

  return (
    <ReactSelect
      {...props}
      isMulti
      value={initialValue}
      options={[props.allOption, ...customerIdsOptions]}
      menuColor='red'
      onChange={(selected) => {
        let selectedOptions = selected
        if (
          selected !== null &&
          selected.length > 0 &&
          selected[selected.length - 1].value === props.allOption.value
        ) {
          selectedOptions = customerIdsOptions
        }
        const selectedCustomerIds = selectedOptions.map((id) => id.value)
        return props.onChange(selectedCustomerIds)
      }}
      styles={{ marginRight: 16 }}
    />
  )
}

MySelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
}

MySelect.defaultProps = {
  allOption: {
    label: 'Select all',
    value: '*',
  },
}

export default MySelect
