import axios from 'axios'

export const APP_URL = process.env.REACT_APP_API_URL
export const ASSET_MANAGEMENT_URL = `${process.env.REACT_APP_API_URL}/asset-management`

export function getAssets(filter: {}) {
  return axios.post(`${ASSET_MANAGEMENT_URL}/get-assets`, { filter })
}

export function updateAssets(newAsset: {}) {
  return axios.post(`${ASSET_MANAGEMENT_URL}/update-assets`, { newAsset })
}

export function importAssets(importSetting: any) {
  let formData = new FormData()

  formData.append('extension', importSetting.extension)
  formData.append('mode', importSetting.mode)

  for (let i = 0; i < importSetting.attachFiles.files.length; i++) {
    formData.append(`files[${i}]`, importSetting.attachFiles.files[i])
  }

  Object.keys(importSetting.attachFiles).forEach((key) =>
    formData.append(key, importSetting.attachFiles[key])
  )

  return axios.post(`${ASSET_MANAGEMENT_URL}/import-assets`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function saveAssets(importing: any) {
  let formData = new FormData()

  formData.append('user_id', importing.userId)
  formData.append('customerIds', importing.customerIds)
  formData.append('mode', importing.mode)
  formData.append('assets', importing.assets)

  return axios.post(`${ASSET_MANAGEMENT_URL}/save-assets`, { importing })
}

export function exportAssets(exportSetting: {}) {
  return axios.post(
    `${ASSET_MANAGEMENT_URL}/export-assets`,
    { exportSetting },
    {
      responseType: 'blob',
    }
  )
}

export function deleteAsset(dbkey: number) {
  return axios.post(`${ASSET_MANAGEMENT_URL}/delete-assets`, { dbkey })
}

export function getAllCustomerIds() {
  return axios.get(`${ASSET_MANAGEMENT_URL}/get-customers`)
}
