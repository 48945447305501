import React from 'react'
import { Dropdown } from 'react-bootstrap-v5'
import { QuoteModel } from '../../../../types'

import { IAuthState } from '../../auth'
import * as levelHelper from '../../../../helper/level.helper'
import { PersistPartial } from 'redux-persist/es/persistReducer'

type Props = {
  data: QuoteModel
  auth: IAuthState & PersistPartial
  handleUpdatePayment: (data: QuoteModel) => void
  handleViewPayment: (data: QuoteModel) => void
  handleCompletePay: (data: QuoteModel) => void
}

const ActionDropDown: React.FC<Props> = (props) => {
  const { data, auth, handleUpdatePayment, handleViewPayment, handleCompletePay } = props
  return (
    <Dropdown key={data.dbkey}>
      <Dropdown.Toggle id='dropdown-basic' variant='light' size='sm'>
        Actions
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {data.statusCode === 'pd' ? (
          <Dropdown.Item href='#' onClick={() => handleViewPayment(data)}>
            View Payment Method
          </Dropdown.Item>
        ) : (
          <Dropdown.Item href='#' onClick={() => handleUpdatePayment(data)}>
            Update Payment Method
          </Dropdown.Item>
        )}

        {levelHelper.isNBS(auth.user?.type) && data.statusCode === 'stbb' && (
          <Dropdown.Item href='#' onClick={() => handleCompletePay(data)}>
            Complete and Pay
          </Dropdown.Item>
        )}
        <Dropdown.Item
          href={`${process.env.REACT_APP_NBS_ONLINE_URL}processing_pdf.php?dbkey_quote_b=${data.dbkey}&order_type=quote_portal&pdf=y`}
          target='_blank'
        >
          View
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export { ActionDropDown }
