import React, { useState, FC, useEffect } from 'react'

type Props = {
  data: { name: string; code: string }[]
  defaultValue: string
  dirUp: boolean
  selectedValue: (value: string) => void
  disable?: boolean
}

export const StatesDropDown: FC<Props> = ({
  data,
  defaultValue,
  dirUp,
  selectedValue,
  disable,
}) => {
  const [show, setShow] = useState<boolean>(false)
  const [selected, setSelected] = useState<string | undefined>('')

  const handleSelect = (item: { name: string; code: string }) => {
    selectedValue(item.code)
    setSelected(item.name)
    setShow(false)
  }

  useEffect(() => {
    const defaultState = data.find((d) => d.code === defaultValue)
    setSelected(defaultState?.name)
  }, [data, defaultValue])

  return (
    <div className={`position-relative select2-body ${disable && 'pe-none'}`}>
      <span className='select2-container' onClick={() => setShow(!show)}>
        <span className='select2-selection select2-selection--single form-select form-select-solid min-h-43px fw-bolder'>
          <div className='select2-search select2-search--dropdown'>{selected}</div>
        </span>
      </span>
      <div
        className={`select2-container select2-container--bootstrap5 search-dropdown-container ${
          dirUp && 'bottom-100'
        } ${!show && 'd-none'}`}
      >
        <div className='select2-dropdown'>
          <span className='select2-results'>
            <ul className='select2-results__options'>
              {data?.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={`${
                      selected === item.name && 'select2-results__option--selected'
                    } select2-results__option select2-results__option--selectable`}
                    onClick={() => handleSelect(item)}
                  >
                    {item.name}
                  </li>
                )
              })}
            </ul>
          </span>
        </div>
      </div>
    </div>
  )
}
