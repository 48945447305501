import React, { useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { useSelector, shallowEqual } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import toast from 'react-hot-toast'
import { KTSVG } from '../../../_metronic/helpers'
import { UserModel } from '../../../types'
import { RootState } from '../../../setup'
import { sendNotification } from '../../services/service'
import { getErrorMessage } from '../../../helper'

type Props = {
  show: boolean
  onHide: () => void
  receiver: UserModel | undefined
  type: string
}

const SendNotification: React.FC<Props> = (props) => {
  const { show, onHide, receiver, type } = props
  const [submit, setSubmit] = useState<boolean>(false)

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const initialValues = {
    subject: '',
    description: '',
  }

  const handleSubmit = (values: any) => {
    console.log(values, receiver)

    setSubmit(true)
    const payload = {
      ...values,
      userId: receiver?.id,
      email: receiver?.email,
      type: type,
    }

    sendNotification(payload)
      .then((res) => {
        setSubmit(false)
        toast.success(res.data)
        onHide()
      })
      .catch((error) => {
        const errMsg = getErrorMessage(error)
        toast.error(errMsg)
      })
  }

  return (
    <Modal
      size='lg'
      show={show}
      dialogClassName='w-50'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title className='w-100' id='contained-modal-title-vcenter'>
          <div className='d-flex justify-content-between'>
            <h2>Send Notification</h2>
            <span className='text-hover-primary cursor-pointer' onClick={onHide}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='pt-10 pb-15 px-lg-17'>
        <Formik onSubmit={handleSubmit} initialValues={initialValues}>
          {({ handleSubmit, errors, touched, values }) => (
            <Form>
              <div className='d-flex flex-column me-n7 pe-7'>
                <div className='row mb-7'>
                  <label className='fw-bold fs-4 text-center mb-2'>
                    {`Hello ${user.firstname} ${user.lastname}. Please enter the information below. When you select submit the notification will be send to ${receiver?.firstname} ${receiver?.lastname}`}
                  </label>
                </div>
                <div className='row fv-plugins-icon-container'>
                  <div className='col-lg-12 mb-7'>
                    <label className='fw-bold fs-6 required mb-2'>Subject</label>
                    <Field
                      as='input'
                      name='subject'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                    />
                    {touched.subject && errors.subject ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>Card Number is required</div>
                      </div>
                    ) : null}
                  </div>
                  <div className='col-lg-12 mb-7'>
                    <label className='fw-bold fs-6 mb-2'>Description</label>
                    <Field
                      as='textarea'
                      rows={8}
                      name='description'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                    />
                  </div>
                </div>
              </div>
              <div className='text-center pt-15'>
                <button type='button' className='btn btn-light me-3' onClick={() => onHide()}>
                  Discard
                </button>
                <button type='submit' className={`btn ${submit ? 'btn-light' : 'btn-primary'}`}>
                  Submit
                  {submit && (
                    <div className='spinner-border w-17px h-17px ms-2 text-primary' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export { SendNotification }
