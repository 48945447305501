import React, { useEffect, useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { Accordion } from 'react-bootstrap-v5'
import { BillingCompany, UserModel } from '../../../../types'
import { SearchDropDown } from './SearchDropDown'
import { RootState } from '../../../../setup'
import CustomToggle from '../../../components/CustomToggle'
import * as levelHelper from '../../../../helper/level.helper'
import 'react-datepicker/src/stylesheets/datepicker.scss'
import { KTSVG } from '../../../../_metronic/helpers'

type FilterDropDownProps = {
  handleFilter: (filter: any) => void
  handleCancelCompany: () => void
  companies: BillingCompany[]
  initialFilterStatus?: any
}

const FilterDropDown: React.FC<FilterDropDownProps> = ({
  handleFilter,
  handleCancelCompany,
  companies,
}) => {
  const [isNBS, setIsNBS] = useState<boolean>(false)
  const [isReset, setIsReset] = useState<boolean>(false)
  const [selectedCompanies, setSelectedCompanies] = useState<BillingCompany[]>([])

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const handleSelect = (company: BillingCompany) => {
    const isExist = selectedCompanies.find((c) => c.BillingCompanyID === company.BillingCompanyID)

    if (!isExist) setSelectedCompanies([...selectedCompanies, ...[company]])
    setIsReset(false)
  }

  const handleCancel = (companyId: string) => {
    setSelectedCompanies(
      selectedCompanies.filter((company) => company.BillingCompanyID !== companyId)
    )
    handleCancelCompany()
  }

  const handleReject = () => {
    setIsReset(true)
    setSelectedCompanies([])
    handleFilter({
      isReset: true,
    })
  }

  useEffect(() => {
    setIsNBS(levelHelper.isNBS(user?.type))
  }, [user])

  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
      </div>
      <div className='separator border-gray-200'></div>
      <div className='px-7 py-5'>
        <Accordion defaultActiveKey='0'>
          {isNBS && (
            <>
              <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
                <CustomToggle eventKey='filter-0'>Company ID</CustomToggle>
              </div>
              <Accordion.Collapse eventKey='filter-0' className='ms-8'>
                <>
                  <div>
                    {selectedCompanies?.map((company, index) => {
                      return (
                        <div
                          key={index}
                          className='d-flex justify-content-between px-3 py-2 background-light-dark my-2 cursor-pointer'
                        >
                          <span>
                            {company.BillingCompanyID} - {company?.CompanyName}
                          </span>
                          <span
                            className='text-hover-primary cursor-pointer'
                            onClick={() => handleCancel(company.BillingCompanyID)}
                          >
                            <KTSVG
                              path='/media/icons/duotone/Navigation/Close.svg'
                              className='svg-icon-1'
                            />
                          </span>
                        </div>
                      )
                    })}
                  </div>
                  <SearchDropDown
                    data={companies}
                    edit={isReset ? 'reset' : ''}
                    isNBS={isNBS}
                    selectedValue={(company) => handleSelect(company)}
                  />
                </>
              </Accordion.Collapse>
            </>
          )}
          <div className='d-flex justify-content-end'>
            <button
              type='reset'
              className='btn btn-sm btn-white btn-active-light-primary me-2'
              data-kt-menu-dismiss='true'
              onClick={handleReject}
            >
              Reset
            </button>
            <button
              type='button'
              className='btn btn-sm btn-primary'
              data-kt-menu-dismiss='true'
              onClick={() => {
                setIsReset(false)
                handleFilter({
                  selectedCompanies: selectedCompanies,
                })
              }}
            >
              Apply
            </button>
          </div>
        </Accordion>
      </div>
    </div>
  )
}

export default FilterDropDown
