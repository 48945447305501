import { createTheme } from '@material-ui/core'

export const theme = createTheme({
  palette: {
    primary: { main: '#009EF7', contrastText: '#fff' },
  },
  typography: {
    fontFamily: 'Poppins, Helvetica, "sans-self"',
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'capitalize',
        fontSize: '14px',
        fontWeight: 600,
      },
    },
  },
})
