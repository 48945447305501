import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
import React, { useMemo } from 'react'
import {
  AccreditReports,
  IArrayEquipmentTest,
} from '../../../../types/accredit-reports/AccreditReportsTypes'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { CheckIsAdjusted } from '../helper/PdfStylingCheck'
import { number } from 'card-validator'
const styles = StyleSheet.create({
  header: {
    display: 'flex',
    flexDirection: 'row',
  },
  cell1: {
    border: '1px solid black',
    textAlign: 'center',
    alignItems: 'center',
    width: '30%',
    padding: 2,
  },
  cell2: {
    border: '1px solid black',
    textAlign: 'center',
    alignItems: 'center',
    width: '15%',
    padding: 2,
  },
  cell3: {
    border: '1px solid black',
    textAlign: 'center',
    alignItems: 'center',
 
    width: '15%',
    padding: 2,
  },
  cell4: {
    border: '1px solid black',
    textAlign: 'center',
    alignItems: 'center',
 
    width: '30%',
    padding: 2,
  },
  cell5: {
    border: '1px solid black',
    textAlign: 'center',
    alignItems: 'center',
 
    width: '10%',
    padding: 2,
  },
  big_cell: {
    alignItems: 'center',
    justifyContent: 'center',
  },
 
  body: {
    display: 'flex',
    flexDirection: 'row',
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
})
type Props = { data: IArrayEquipmentTest }
 
const PerformanceCheckTable1 = (props: Props) => {
  const { data } = props
  // Calculate tolerance_q2_q3 inside the component
  const tolerance_q2_q3 = `${data.TOL4}`
 
  const formatTolerance = (tol: string) => {
    if (tol == '0') {
      return '0-0'
    }
    if (tol.includes('-')) {
      return `${tol}`
    }
  }
  var decimalP = 0
  function getDifferenceAbs(toleranceString: string): string {
    // Match two decimal numbers (with optional negative signs) separated by a dash
    const matches = toleranceString.match(/(-?\d+\.\d+) - (-?\d+\.\d+)/);
 
    if (!matches) return "0"; // Handle case where no match is found
 
    // Parse the first and second numbers, keeping the negative signs if present
    const num1 = Number(matches[1]);
    const num2 = Number(matches[2]);
 
    // Determine the number of decimal places based on the second number
    const decimalPlaces = (matches[2].split('.')[1] || '').length;
 
    // Calculate the absolute difference and divide by 2
    const difference = Math.abs(num1 - num2) / 2;
 
    // Format the difference with the same precision as the second number
    return difference.toFixed(decimalPlaces);
  }
 
 
  function getDecimalPartAsNonDecimal(num: string | number): number {
    // Convert the input to a string if it isn't already
    const numString = num.toString().replace('.', '');
 
    // Return the number as a non-decimal integer
    return parseInt(numString, 10);
  }
 
  const checkTolerance = (
    division: string,
    cornerLoadTolerance: string,
    weightOne: string,
    weightTwo: string
  ) => {
    var secondDecimal = getDifferenceAbs(cornerLoadTolerance);
    console.log(secondDecimal)
    var secondDecimalPart = 0; // Default value
    if (secondDecimal !== null) {
      var secondDecimalPart = getDecimalPartAsNonDecimal(secondDecimal);
   }
    if (
      Math.abs(Number(weightOne)) > Number(secondDecimalPart) ||
      Math.abs(Number(weightTwo)) > Number(secondDecimalPart)
    ) {
      return false
    } else {
      return true
    }
  }
    // Extract the second decimal from tolerance_q2_q3
    const secondDecimal = getDifferenceAbs(tolerance_q2_q3);
    var secondDecimalPart = 0; // Default value
    if (secondDecimal !== null) {
      var secondDecimalPart = getDecimalPartAsNonDecimal(secondDecimal);
   }
    let inTolerance = true;
    // Assuming data is an object with the required properties
    if (data && secondDecimal !== null) {
      // Check if any of the values' decimal parts are greater than the second decimal part
      if (Math.abs(Number(data.CornerLoadFrontAsLeft)) > secondDecimalPart ||
      Math.abs(Number(data.CornerLoadBackAsLeft)) > secondDecimalPart ||
      Math.abs(Number(data.CornerLoadLeftAsLeft)) > secondDecimalPart ||
      Math.abs(Number(data.CornerLoadRightAsLeft)) > secondDecimalPart) {
        inTolerance = false;
     }
    }
  return (
    <View style={styles.root}>
      <View>
        <View style={styles.header}>
          <View style={styles.cell1}>
            <Text style={{ textAlign: 'center' }}>Mass Value</Text>
          </View>
          <View style={styles.cell2}>
            <Text>As-Found</Text>
          </View>
          <View style={styles.cell3}>
            <Text>In-Tolerancee</Text>
          </View>
          <View style={styles.cell4}>
            <Text>Tolerance</Text>
          </View>
          <View style={styles.cell5}>
            <Text>As-Left</Text>
          </View>
        </View>
 
        <View style={styles.body}>
          <View style={styles.cell1}>
            <Text>Quadrant 2 / Quadrant 3</Text>
          </View>
          <View style={styles.cell2}>
            <Text>{`${data?.CornerLoadFront || '0'}/${data.CornerLoadBack || '0'}`}</Text>
          </View>
          <View style={styles.cell3}>
            <Text
              style={{
                color: checkTolerance(
                  data.Division,
                  data.TOL4,
                  data.CornerLoadFront,
                  data.CornerLoadBack
                )
                  ? 'green'
                  : 'red',
              }}
            >
              {checkTolerance(
                data.Division,
                data.TOL4,
                data.CornerLoadFront,
                data.CornerLoadBack
              )
                ? 'Yes'
                : 'No'}
            </Text>
          </View>
          <View style={styles.cell4}>
            <Text> {formatTolerance(tolerance_q2_q3) ?? '0'}</Text>
          </View>
          <View style={styles.cell5}>
            <Text>{`${data?.CornerLoadFrontAsLeft || 'null'}/${
              data.CornerLoadBackAsLeft || 'null'
            }`}</Text>
          </View>
        </View>
        <View style={styles.body}>
          <View style={styles.cell1}>
            <Text>Quadrant 1 / Quadrant 4</Text>
          </View>
          <View style={styles.cell2}>
            <Text>{`${data?.CornerLoadLeft || '0'}/${data.CornerLoadRight || '0'}`}</Text>
          </View>
          <View style={styles.cell3}>
            <Text
              style={{
                color: checkTolerance(
                  data.Division,
                  data.TOL4,
                  data.CornerLoadLeft,
                  data.CornerLoadRight
                )
                  ? 'green'
                  : 'red',
              }}
            >
              {checkTolerance(
                data.Division,
                data.TOL4,
                data.CornerLoadLeft,
                data.CornerLoadRight,
              )
                ? 'Yes'
                : 'No'}
            </Text>
          </View>
          <View style={styles.cell4}>
            <Text>
              <Text> {formatTolerance(tolerance_q2_q3) ?? '0'}</Text>
            </Text>
          </View>
          <View style={styles.cell5}>
            <Text>{`${data?.CornerLoadLeftAsLeft || 'null'}/${
              data.CornerLoadRightAsLeft || 'null'
            }`}</Text>
          </View>
        </View>
      </View>
      <View style={[styles.cell4, styles.big_cell]}>
        <Text>
            In Tolerance <Image src={toAbsoluteUrl('/media/arrow.png')} />:{' '}
            <span style={{ color: inTolerance ? 'green' : 'red' }}>{inTolerance ? 'Yes' : 'No'}</span>
        </Text>
        <Text>
          Adjusted Corner Load:{' '}
          <span>
            {CheckIsAdjusted(
              [
                data.CornerLoadFront,
                data.CornerLoadBack,
                data.CornerLoadLeft,
                data.CornerLoadRight,
              ],
              [
                data.CornerLoadFrontAsLeft,
                data.CornerLoadBackAsLeft,
                data.CornerLoadLeftAsLeft,
                data.CornerLoadRightAsLeft,
              ]
            )}
          </span>
        </Text>
      </View>
    </View>
  )
}
 
export default PerformanceCheckTable1