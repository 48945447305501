import axios from 'axios'

export const USER_SETTINGS_URL = `${process.env.REACT_APP_API_URL}/user/settings`

export const getUserSettings = async (key: string) => {
  const response = await axios.get(`${USER_SETTINGS_URL}`, {params: {key}})

  return response.data
}

export const setUserSettings = async (key: string, value: string) => {
  return await axios.post(`${USER_SETTINGS_URL}`, {key, value})
}
