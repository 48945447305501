import { Action } from '@reduxjs/toolkit'
import { put, takeLatest } from 'redux-saga/effects'
import { QuoteModel } from '../../../types'
import { getSalesQuotes } from './salesQuotesCrud'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  SalesQuotesRequested: '[Request Sales Quotes] Action',
  SalesQuotesLoaded: '[Load Sales Quotes] Action',
  SetQuoteNumberSlug: '[Set Quote Number Slug on Sales Quote] Action',
}

const initialQuotesState: ISalesQuotesState = {
  loading: false,
  salesQuotes: [],
  quoteNumberSlug: null,
  totals: 0,
}

export interface ISalesQuotesState {
  loading: boolean
  salesQuotes: any
  quoteNumberSlug: any
  totals: number
}

export const reducer = (
  state: ISalesQuotesState = initialQuotesState,
  action: ActionWithPayload<ISalesQuotesState>
) => {
  switch (action.type) {
    case actionTypes.SalesQuotesRequested: {
      return {
        ...state,
        loading: true,
      }
    }

    case actionTypes.SalesQuotesLoaded: {
      return {
        ...state,
        loading: false,
        ...action.payload,
      }
    }

    case actionTypes.SetQuoteNumberSlug: {
      const quoteNumberSlug = action.payload?.quoteNumberSlug
      return {
        ...state,
        quoteNumberSlug,
      }
    }

    default:
      return state
  }
}

export const actions = {
  getSalesQuotes: (searchFilter: any = {}) => ({
    type: actionTypes.SalesQuotesRequested,
    payload: { searchFilter },
  }),

  salesQuotesLoaded: (salesQuotes: QuoteModel[], totals: number) => ({
    type: actionTypes.SalesQuotesLoaded,
    payload: { salesQuotes, totals },
  }),

  setQuoteNumberSlug: (quoteNumberSlug: any) => ({
    type: actionTypes.SetQuoteNumberSlug,
    payload: { quoteNumberSlug },
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.SalesQuotesRequested, function* salesQuotesRequested(action: any) {
    const { searchFilter } = action.payload
    const {
      data: { salesQuotes, totals },
    } = yield getSalesQuotes(searchFilter)

    yield put(actions.salesQuotesLoaded(salesQuotes, totals))
  })
}
