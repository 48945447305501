import React, { useContext } from 'react'
import { AccordionContext, useAccordionToggle } from 'react-bootstrap-v5'
import clsx from 'clsx'

const CustomToggle = (props: any) => {
  const activeEventKey = useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(props.eventKey, () => { props.callback && props.callback(props.eventKey) })
  const isCurrentEventKey = activeEventKey === props.eventKey

  return (
    <span
      onClick={decoratedOnClick}
      className={clsx('custom-toggle-button text-reset cursor-pointer', { 'collapsed': !isCurrentEventKey })}
    >
      {props.children}
    </span>
  );
}

export default CustomToggle