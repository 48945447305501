import { Action } from '@reduxjs/toolkit'
import { put, takeLatest } from 'redux-saga/effects'
import { UserModel } from '../../../../types'
import { getCustomers } from './customerCRUD'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  CustomersRequested: '[Request Customers] Action',
  CustomersLoaded: '[Load Customers] Action',
  CustomersOfUserRequested: '[Request Customers Of User] Action',
}

const initialCustomerState: ICustomersState = {
  customers: [],
  loading: false,
}

export interface ICustomersState {
  customers: any
  loading: boolean
}

export const reducer = (
  state: ICustomersState = initialCustomerState,
  action: ActionWithPayload<ICustomersState>
) => {
  switch (action.type) {
    case actionTypes.CustomersRequested: {
      return { ...state, loading: true }
    }

    case actionTypes.CustomersLoaded: {
      const customers = action.payload?.customers
      return { customers, loading: false }
    }

    default:
      return state
  }
}

export const actions = {
  getCustomers: (
    customerIds: string[] | undefined,
    status = ['active', 'inactive', 'invited'],
    filters = {}
  ) => ({
    type: actionTypes.CustomersRequested,
    payload: { customerIds, status, filters },
  }),
  customersLoaded: (customers: UserModel[]) => ({
    type: actionTypes.CustomersLoaded,
    payload: { customers },
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.CustomersRequested, function* customersRequested({ payload }: any) {
    const { customerIds, status, filters } = payload

    const { data: customers } = yield getCustomers({ customerIds, status, filters })
    yield put(actions.customersLoaded(customers))
  })
}
