import React, { useState } from 'react';
import { Calendar, DateObject } from 'react-multi-date-picker';

interface DateSelectionFilterDropDownProps {
  specificMonth: string;
  selectAllCheckbox: React.RefObject<HTMLInputElement>;
  handleCheckAll: (selectAllCheckbox: React.RefObject<HTMLInputElement>, dates: (string[] | null[]) ) => void;
  show: boolean;
}

const DateSelectionFilterDropDown: React.FC<DateSelectionFilterDropDownProps> = ({ 
    handleCheckAll, 
    specificMonth, 
    selectAllCheckbox, 
    show
}) => {
  const [selectedDates, setSelectedDates] = useState<[DateObject | null, DateObject | null]>([null, null]);

    // Convert the specificMonth (JavaScript Date) to a DateObject
    const specificMonthAsDateObject = new DateObject(specificMonth);
  
  const handleDateChange = (e: DateObject[]) => {
    setSelectedDates(e as [DateObject | null, DateObject | null]);
    if (e[0] && e[1]) {
        if(selectAllCheckbox.current) {selectAllCheckbox.current.checked = false}
        handleCheckAll(selectAllCheckbox, [null,null])

        if(selectAllCheckbox.current) {selectAllCheckbox.current.checked = true}
      handleCheckAll(selectAllCheckbox, [
        e[0].format('YYYY-MM-DD'),
        e[1].format('YYYY-MM-DD'),
      ]);
    }
  };

  return (
    <div
        className='menu menu-sub menu-sub-dropdown w-250px w-md-300px'
        data-kt-menu='true'
        style={{
            display: show ? 'block' : 'none',  // Control visibility with CSS display
            backgroundColor: 'white', // Make sure it is visible
            border: '1px solid black', // Add a border for visibility
            zIndex: 9999, // Set a high z-index to ensure visibility
            position: 'absolute', // Position it relative to its parent
            top: '100%', // Position it below the trigger button
            right: '0', // Align it with the right edge of the parent container
        }}
    >
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Select Dates</div>
      </div>
      <div className='separator border-gray-200'></div>
      <div className='px-7 py-5'>
        <div className='mb-10'>
          <label className='form-label fw-bold'>Select Date Range:</label>
          <Calendar
            value={selectedDates}
            onChange={handleDateChange}
            range // Enable range selection
            currentDate={specificMonthAsDateObject}
          />
        </div>
      </div>
      <div className='d-flex justify-content-end'>
        <input type='checkbox' id='filterCheckbox' className='d-none' />
        <button
          type='button'
          className='btn btn-sm btn-primary'
          onClick={() => handleDateChange(selectedDates)}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default DateSelectionFilterDropDown;
