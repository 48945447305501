import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core'
import React from 'react'
import ContactContent from './ContactContent'
import { ContactModel } from '../../../../types'
import { deleteNBSContact, getNBSContacts } from '../ContactsCRUD'
import toast from 'react-hot-toast'
import { getErrorMessage } from '../../../../helper'
import * as contactRedux from '../ContactsRedux'
import { useDispatch } from 'react-redux'
import { Modal } from 'react-bootstrap-v5'
import { KTSVG } from '../../../../_metronic/helpers'

type Props = {
  type: 'Edit' | 'View' | 'Delete' | 'None' | 'New'
  handleClose: () => void
  contacts: ContactModel | {}
}

const ContactDialog = (props: Props) => {
  const { type, handleClose, contacts } = props
  const dispatch = useDispatch()
  const handleDelete = (contact: ContactModel) => {
    deleteNBSContact(contact.dbkey)
      .then((res) => {
        toast.success(res.data.message)
        dispatch(contactRedux.actions.getNBSContacts(contact.CustomerID))
      })
      .catch((err) => {
        toast.error(getErrorMessage(err))
      })
      .finally(() => handleClose())
  }

  return (
    <Modal size='lg' show={type !== 'None'} onHide={handleClose} centered>
      <Modal.Header>
        <Modal.Title className='w-100' id='contained-modal-title-vcenter'>
          <div className='d-flex justify-content-between'>
            <h2>Contact {type}</h2>
            <span className='text-hover-primary cursor-pointer' onClick={handleClose}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='pt-10 pb-15 px-lg-17'>
        {type === 'Delete' ? (
          <>
            <Typography>Are you sure ? </Typography>
            <Grid container justifyContent='center' style={{ marginTop: 6 }}>
              <Button
                variant='contained'
                color='primary'
                style={{ marginRight: 4 }}
                onClick={() => handleDelete(contacts as ContactModel)}
              >
                Yes
              </Button>
              <Button variant='contained' color='secondary' onClick={handleClose}>
                No
              </Button>
            </Grid>
          </>
        ) : (
          <ContactContent {...props} />
        )}
      </Modal.Body>
    </Modal>
  )
}

export default ContactDialog
