import React, { useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'
import { Modal } from 'react-bootstrap-v5'
import { Formik, Form, Field } from 'formik'
import { KTSVG } from '../../../../_metronic/helpers'
import { SelectDropDown } from '../../../components/SelectDropDown'
import { CustomerModel } from '../../../../types'
import { STATES } from '../../../../data/constants'
import { CustomDropDown } from './CustomDropDown'
import TiptapEditor from '../../../components/TiptapEditor/TipTapEditor'

type Props = {
  show: boolean
  customer: CustomerModel
  onHide: any
}

const CustomerEditSchema = Yup.object().shape({})

const ViewCustomerModal: React.FC<Props> = (props) => {
  const { show, customer, onHide } = props

  const initialValues = useMemo(() => {
    return {
      customerId: customer?.customerId ?? '',
      companyId: customer?.companyId ?? '',
      shipCompanyName: customer?.shipCompanyName ?? '',
      taxExempt: customer?.taxExempt === 1 ? 'Yes' : 'No',
      status: customer?.status ?? '',
      contactFirstName: customer?.contactFirstName ?? '',
      contactLastName: customer?.contactLastName ?? '',
      idShipping: customer?.idShipping ?? '',
      shipPhoneNumber: customer?.shipPhoneNumber ?? '',
      shipFaxNumber: customer?.shipFaxNumber ?? '',
      shipAddress: customer?.shipAddress ?? '',
      shipAddress2: customer?.shipAddress2 ?? '',
      shipCity: customer?.shipCity ?? '',
      shipState: customer?.shipState ?? '',
      shipZip: customer?.shipZip ?? '',
      shipNotes: customer?.shipNotes ?? '',
      billingCompanyName: customer?.billingCompanyName ?? '',
      billContact: customer?.billContact ?? '',
      billPhoneNumber: customer?.billPhoneNumber ?? '',
      billFaxNumber: customer?.billFaxNumber ?? '',
      billAddress: customer?.billAddress ?? '',
      billAddress2: customer?.billAddress2 ?? '',
      billCity: customer?.billCity ?? '',
      billState: customer?.billState ?? '',
      billZip: customer?.billZip ?? '',
      // billNotes: customer?.billNotes ?? '',
      netTerms: customer?.netTerms ?? 30,
      email: customer?.email ?? '',
      creator: customer?.creator ?? '',
      updator: customer?.updator ?? '',
      createdAt: customer?.createdAt ?? '',
      updatedAt: customer?.updatedAt ?? '',
    }
  }, [customer])

  const [shipToggle, setShipToggle] = useState<boolean>(false)
  const [billToggle, setBillToggle] = useState<boolean>(false)
  const [billState, setBillState] = useState<string>('')

  useEffect(() => {
    setBillState(initialValues.billState)
  }, [initialValues])

  return (
    <Modal
      size='lg'
      show={show}
      aria-labelledby='contained-modal-title-vcenter'
      onHide={() => onHide(false)}
      centered
    >
      <Modal.Header>
        <Modal.Title className='w-100' id='contained-modal-title-vcenter'>
          <div className='d-flex justify-content-between'>
            <h2>View Customer</h2>
            <span className='text-hover-primary cursor-pointer' onClick={() => onHide(false)}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='pt-10 pb-15 px-lg-17'>
        <Formik
          validationSchema={CustomerEditSchema}
          onSubmit={() => {}}
          initialValues={initialValues}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <div className='d-flex flex-column scroll-y me-n7 pe-7 max-height-60'>
                <div className='d-flex justify-content-between mb-7 fv-plugins-icon-container'>
                  <h5 className='fw-bold mb-2'>{`Customer ID: ${customer?.customerId}`}</h5>
                  <h5 className='fw-bold mb-2'>{`Customer Shipping ID: ${customer?.idShipping}`}</h5>
                </div>
                <div className='row fv-plugins-icon-container'>
                  <div className='col-lg-6 mb-7'>
                    <label className='fw-bold fs-6 required mb-2'>Company ID</label>
                    <Field
                      as='input'
                      name='companyId'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      readOnly
                    />
                  </div>
                  {/* <div className='col-lg-4 mb-7'>
                    <label className='fw-bold fs-6 mb-2'>Tax Exempt</label>
                    <SelectDropDown
                      data={['Yes', 'No']}
                      defaultValue={initialValues.taxExempt}
                      dirUp={false}
                      selectedValue={(value: string | number) => setFieldValue('taxExempt', value)}
                      disable={true}
                    />
                    <Field
                      as='input'
                      name='taxExempt'
                      className='form-control form-control-solid mb-3 d-none'
                    />
                  </div> */}
                  <div className='col-lg-6 mb-7'>
                    <label className='fw-bold fs-6 mb-2'>Active</label>
                    <SelectDropDown
                      data={['active', 'inactive']}
                      defaultValue={initialValues.status}
                      dirUp={false}
                      selectedValue={(value: string | number) => setFieldValue('status', value)}
                      disable={true}
                    />
                    <Field
                      as='input'
                      name='status'
                      className='form-control form-control-solid d-none'
                    />
                  </div>
                  {/* <div className='col-lg-4 mb-7'>
                    <label className='fw-bold fs-6 mb-2'>Net Terms</label>
                    <SelectDropDown
                      data={[30, 35, 45, 60, 90]}
                      defaultValue={initialValues.netTerms}
                      dirUp={false}
                      selectedValue={(value: string | number) => setFieldValue('netTerms', value)}
                      disable={true}
                    />
                    <Field
                      as='input'
                      name='netTerms'
                      className='form-control form-control-solid d-none'
                    />
                  </div> */}
                  <div className='col-lg-8 mb-7'>
                    <label className='fw-bold fs-6 mb-2'>Email Address</label>
                    <Field
                      as='input'
                      name='email'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      readOnly
                    />
                  </div>
                </div>
                <div
                  onClick={() => setShipToggle(!shipToggle)}
                  className='d-flex mb-5 cursor-pointer'
                >
                  <h3>Shipping Information</h3>
                  <KTSVG
                    path={`/media/icons/duotone/Navigation/${
                      shipToggle ? 'Angle-up' : 'Angle-down'
                    }.svg`}
                    className='svg-icon-1'
                  />
                </div>
                <div className={`${shipToggle ? 'd-none' : 'd-block'} ps-5`}>
                  <div className='row fv-plugins-icon-container'>
                    <div className='col-lg-12 mb-7'>
                      <label className='fw-bold fs-6 mb-2'>Company Name</label>
                      <Field
                        as='input'
                        name='shipCompanyName'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        readOnly
                      />
                    </div>
                  </div>
                  <div className='row fv-plugins-icon-container'>
                    <div className='col-lg-6 mb-7'>
                      <label className='fw-bold fs-6 mb-2'>Contact First Name</label>
                      <Field
                        as='input'
                        name='contactFirstName'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        readOnly
                      />
                    </div>
                    <div className='col-lg-6 mb-7'>
                      <label className='fw-bold fs-6 mb-2'>Contact Last Name</label>
                      <Field
                        as='input'
                        name='contactLastName'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        readOnly
                      />
                    </div>
                  </div>
                  <div className='row fv-plugins-icon-container'>
                    <div className='col-lg-6 mb-7'>
                      <label className='fw-bold fs-6 mb-2'>Phone Number</label>
                      <Field
                        as='input'
                        name='shipPhoneNumber'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        readOnly
                      />
                    </div>
                    <div className='col-lg-6 mb-7'>
                      <label className='fw-bold fs-6 mb-2'>Fax Number</label>
                      <Field
                        as='input'
                        name='shipFaxNumber'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        readOnly
                      />
                    </div>
                  </div>
                  <div className='row fv-plugins-icon-container'>
                    <div className='col-lg-12 mb-7'>
                      <label className='fw-bold fs-6 mb-2'>Address Line1</label>
                      <Field
                        as='input'
                        name='shipAddress'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        readOnly
                      />
                    </div>
                  </div>
                  <div className='row fv-plugins-icon-container'>
                    <div className='col-lg-12 mb-7'>
                      <label className='fw-bold fs-6 mb-2'>Address Line2</label>
                      <Field
                        as='input'
                        name='shipAddress2'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        readOnly
                      />
                    </div>
                  </div>
                  <div className='row fv-plugins-icon-container'>
                    <div className='col-lg-12 mb-7'>
                      <label className='fw-bold fs-6 mb-2'>City</label>
                      <Field
                        as='input'
                        name='shipCity'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        readOnly
                      />
                    </div>
                  </div>
                  <div className='row fv-plugins-icon-container'>
                    <div className='col-lg-6 mb-7'>
                      <label className='fw-bold fs-6 mb-2'>State</label>
                      <CustomDropDown
                        data={STATES}
                        defaultValue={initialValues.shipState}
                        dirUp={false}
                        selectedValue={(code: string) => {}}
                        disable={true}
                      />
                      <Field
                        as='input'
                        name='shipState'
                        className='form-control form-control-solid d-none'
                      />
                    </div>
                    <div className='col-lg-6 mb-7'>
                      <label className='fw-bold fs-6 mb-2'>Zip Code</label>
                      <Field
                        as='input'
                        name='shipZip'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        readOnly
                      />
                    </div>
                    <div className='col-lg-12 mb-5'>
                      <label className='fw-bold fs-6 mb-2'>Shipping Notes</label>
                      <TiptapEditor
                        content={values.shipNotes}
                        onUpdate={(value) => setFieldValue('shipNotes', value)}
                        isReadOnly={true}
                      />
                      <Field
                        as='input'
                        name='shipNotes'
                        modules={{
                          toolbar: {
                            container: '#toolbar-container',
                          },
                        }}
                        className='form-control form-control-solid mb-3 d-none'
                      />
                    </div>
                  </div>
                </div>
                <div className='d-flex mb-5 cursor-pointer'>
                  <div className='d-flex' onClick={() => setBillToggle(!billToggle)}>
                    <h3>Billing Information</h3>
                    <KTSVG
                      path={`/media/icons/duotone/Navigation/${
                        billToggle ? 'Angle-up' : 'Angle-down'
                      }.svg`}
                      className='svg-icon-1'
                    />
                  </div>
                </div>
                <div className={`${billToggle ? 'd-none' : 'd-block'} ps-5`}>
                  <div className='row fv-plugins-icon-container'>
                    <div className='col-lg-12 mb-7'>
                      <label className='fw-bold fs-6 mb-2'>Company Name</label>
                      <Field
                        as='input'
                        name='billingCompanyName'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        readOnly
                      />
                    </div>
                  </div>
                  <div className='row fv-plugins-icon-container'>
                    <div className='col-lg-12 mb-7'>
                      <label className='fw-bold fs-6 mb-2'>Contact Name</label>
                      <Field
                        as='input'
                        name='billContact'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        readOnly
                      />
                    </div>
                  </div>
                  <div className='row fv-plugins-icon-container'>
                    <div className='col-lg-6 mb-7'>
                      <label className='fw-bold fs-6 mb-2'>Phone Number</label>
                      <Field
                        as='input'
                        name='billPhoneNumber'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        readOnly
                      />
                    </div>
                    <div className='col-lg-6 mb-7'>
                      <label className='fw-bold fs-6 mb-2'>Fax Number</label>
                      <Field
                        as='input'
                        name='billFaxNumber'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        readOnly
                      />
                    </div>
                  </div>
                  <div className='row fv-plugins-icon-container'>
                    <div className='col-lg-12 mb-7'>
                      <label className='fw-bold fs-6 mb-2'>Address Line1</label>
                      <Field
                        as='input'
                        name='billAddress'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        readOnly
                      />
                    </div>
                  </div>
                  <div className='row fv-plugins-icon-container'>
                    <div className='col-lg-12 mb-7'>
                      <label className='fw-bold fs-6 mb-2'>Address Line2</label>
                      <Field
                        as='input'
                        name='billAddress2'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        readOnly
                      />
                    </div>
                  </div>
                  <div className='row fv-plugins-icon-container'>
                    <div className='col-lg-12 mb-7'>
                      <label className='fw-bold fs-6 mb-2'>City</label>
                      <Field
                        as='input'
                        name='billCity'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        readOnly
                      />
                    </div>
                  </div>
                  <div className='row fv-plugins-icon-container'>
                    <div className='col-lg-6 mb-7'>
                      <label className='fw-bold fs-6 mb-2'>State</label>
                      <CustomDropDown
                        data={STATES}
                        defaultValue={billState}
                        dirUp={true}
                        selectedValue={(code: string) => {
                          setFieldValue('billState', code)
                          setBillState(code)
                        }}
                        disable={true}
                      />
                      <Field
                        as='input'
                        name='billState'
                        className='form-control form-control-solid d-none'
                      />
                    </div>
                    <div className='col-lg-6 mb-7'>
                      <label className='fw-bold fs-6 mb-2'>Zip Code</label>
                      <Field
                        as='input'
                        name='billZip'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='pt-15 fw-bold fs-5 mx-4'>
                {
                  <div>
                    Updated {!!values.updator ? 'by ' + values.updator : ''} on {values.updatedAt}
                  </div>
                }

                {
                  <div>
                    Created {!!values.creator ? 'by ' + values.creator : ''} on {values.createdAt}
                  </div>
                }
              </div>
              <div className='text-center pt-15'>
                <button
                  type='button'
                  className='btn btn-primary me-3'
                  onClick={() => onHide(false)}
                >
                  Ok
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export { ViewCustomerModal }
