import React, { useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { KTSVG } from '../../../_metronic/helpers'

type ModalProps = {
  id: string
  title: string
  content: string
  isComment: boolean
  okText: string
  cancelText: string
  isNBS?: boolean
  email?: string
  customerId?: string
  loading: boolean
  show: boolean
  handleOk: (c: Object) => void
  handleCancel: () => void
}

const BaseModal: React.FC<ModalProps> = (props) => {
  const {
    id,
    title,
    content,
    isComment,
    okText,
    cancelText,
    loading,
    show,
    handleOk,
    handleCancel,
  } = props
  const [comment, setComment] = useState<string>('')
  const [isEmail, setIsEmail] = useState<boolean>(true)
  const [isCustomerId, setIsCustomerId] = useState<boolean>(true)

  const handleEmail = (e: any) => {
    setIsEmail(e.target.value)
  }

  const handleCustomerId = (e: any) => {
    setIsCustomerId(e.target.value)
  }

  return (
    <Modal
      className='modal fade'
      id={'kt_base_modal_' + id}
      tabIndex={-1}
      show={show}
      aria-hidden='true'
      onHide={handleCancel}
      centered
    >
      <div className='modal-dialog modal-dialog-centered mw-650px my-0'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>{title}</h2>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              data-bs-dismiss='modal'
              onClick={handleCancel}
            >
              <span className='svg-icon svg-icon-1'>
                <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
              </span>
            </div>
          </div>
          <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
            <div className='text-center'>
              <label className='fs-2 fw-bold form-label'>{content}</label>
            </div>
            {isComment && (
              <div className='w-100 my-3'>
                <label className='d-block'>Add Comments</label>
                <textarea
                  rows={5}
                  className='w-100 p-1'
                  placeholder='Enter comments'
                  onChange={(e) => setComment(e.target.value)}
                ></textarea>
              </div>
            )}
            {props.isNBS && title === 'Add Comment' && (
              <>
                <div className='form-check form-switch form-switch form-check-custom form-check-solid mt-4'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value=''
                    name='isEmail'
                    onChange={handleEmail}
                    defaultChecked={true}
                    disabled={false}
                  />
                  <label className='form-check-label'>Email customer at {props?.email}</label>
                </div>
                <div className='form-check form-switch form-switch form-check-custom form-check-solid mt-4'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value=''
                    onChange={handleCustomerId}
                    name='isNotification'
                    defaultChecked={true}
                    disabled={false}
                  />
                  <label className='form-check-label'>
                    Send notification to customer {props?.customerId}
                  </label>
                </div>
              </>
            )}
            <div className='text-center pt-15'>
              <button
                type='button'
                id={'kt_base_modal_' + id + 'cancel'}
                className='btn btn-light me-3'
                onClick={handleCancel}
              >
                {cancelText}
              </button>
              <button
                type='button'
                id={'kt_base_modal_' + id + 'submit'}
                className='btn btn-primary'
                onClick={() =>
                  handleOk({
                    comment,
                    isEmail,
                    isCustomerId,
                  })
                }
                disabled={loading}
              >
                {!loading ? (
                  <span className='indicator-label'>{okText}</span>
                ) : (
                  <span className='indicator-label'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default BaseModal
