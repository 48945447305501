import { StyleSheet } from '@react-pdf/renderer'

const tab_color = '#eaeffa'

export const styles = StyleSheet.create({
  page: {
    padding: 26,
  },
  view1: {
    border: '1px solid #c0c0c0',
    borderRadius: 3,
    padding: 8,
    backgroundColor: '#fafafa',
  },
  view_space: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  logoView: {
    border: '1px solid #c0c0c0',
    display: 'flex',
    width: '48%',
    borderRadius: 4,
    padding: 6,
    backgroundColor: 'white',
    position: 'relative',
  },
  capText: {
    textTransform: 'uppercase',
    fontFamily: 'Times-Roman',
    fontSize: '11px',
    paddingBottom: 2,
  },
  caption: {
    fontSize: '8px',
  },
  logo: {
    position: 'absolute',
    right: 12,
    bottom: 6,
    width: 70,
    height: 70,
  },
  title: {
    textTransform: 'uppercase',
    fontFamily: 'Times-Roman',
    fontSize: '20px',
    marginTop: '20px',
    textAlign: 'center',
  },
  view_center: {
    // display: 'flex',
    width: '48%',
    // justifyContent: 'center',
  },
  table1: {
    border: '1px solid #c0c0c0',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    display: 'flex',
    flexDirection: 'row',
    width: '120px',
    height: '26px',
  },
  table2: {
    border: '1px solid #c0c0c0',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    display: 'flex',
    flexDirection: 'row',
    width: '120px',
    height: '26px',
  },

  view2: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    height: '80px',
  },
  body1: {
    fontSize: '12px',
  },
  body2: {
    fontSize: '12px',
    fontWeight: 900,
  },
  col1: {
    borderRight: '1px solid #c0c0c0',
    borderTopRightRadius: 4,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50px',
    height: '26px',
    backgroundColor: '#eaeffa',
  },
  col11: {
    borderRight: '1px solid #c0c0c0',
    borderBottomRightRadius: 4,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50px',
    height: '26px',
    backgroundColor: '#eaeffa',
  },
  col2: {
    width: '70px',
    height: '26px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  col3: {
    width: '48%',
    border: '1px solid #c0c0c0',
    borderRadius: 4,
    marginTop: '8px',
    marginBottom: '8px',
    height: '90px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
  },
  col31: {
    width: '34px',
    backgroundColor: '#eaeffa',
    height: '90px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: '1px solid #c0c0c0',
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  dtl_table_view: {
    marginTop: '20px',
  },
  view_footer: {
    position: 'absolute',
    bottom: 150,
    display: 'flex',
    right: 26,
    left: 26,
    textAlign: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  col1_footer: {
    width: '65%',
    border: '1px solid #c0c0c0',
    borderRadius: 4,
    backgroundColor: '#fafafa',
    height: 75,
    padding: 6,
  },
  col2_footer_content: {
    width: '28%',
    height: 78,
  },
  col2_footer: {
    border: '1px solid #c0c0c0',
    borderRadius: 4,
    height: 75,
    width: '33%',
    display: 'flex',
  },
  cell: {
    display: 'flex',
    flexDirection: 'row',
  },
  cell1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    width: 80,
    height: 15,
    borderRight: '1px solid #c0c0c0',
    borderBottom: '1px solid #c0c0c0',
    backgroundColor: '#eaeffa',
  },
  cell2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: 10,
    flexDirection: 'row',
    width: 100,
    height: 15,
    borderBottom: '1px solid #c0c0c0',
    backgroundColor: '#eaeffa',
  },
  cell_text: {
    fontSize: 9,
    textTransform: 'capitalize',
  },
  cell_total_text: {
    fontSize: 9,
    fontFamily: 'Helvetica-Bold',
    fontWeight: 900,
  },
  cell1_no_border: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    width: 80,
    height: 15,
    borderRight: '1px solid #c0c0c0',
    borderRadius: 4,
  },
  cell2_no_border: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: 10,
    flexDirection: 'row',
    backgroundColor: '#fafafa',
    width: 100,
    height: 15,
  },
  text_footer_note: {
    fontSize: 9,
    textTransform: 'uppercase',
    fontFamily: 'Times-Roman',
    textAlign: 'left',
    paddingBottom: 4,
  },
  footer: {
    position: 'absolute',
    right: 26,
    left: 26,
    bottom: 60,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footer_col1: {
    display: 'flex',
    flexDirection: 'row',
  },
  footer_col2: {
    display: 'flex',
    // flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  footer_col11: {
    display: 'flex',
    paddingLeft: 16,
  },
  text_page: {
    fontSize: 8,
    color: '#646464',
    textAlign: 'right',
    paddingBottom: 10,
  },
  address_view: {
    paddingLeft: 20,
    paddingTop: 10,
    width: 300,
  },
  address_text: {
    marginBottom: 3,
    fontFamily: 'Times-Roman',
    fontSize: 9,
    textAlign: 'left',
    textTransform: 'uppercase',
  },
})
