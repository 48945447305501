export const validLink = (url: string): string => {
  let error = ''

  if (url) {
    if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0) {
      error = ''
    } else {
      error = 'Invalid link type'
    }
  }

  return error
}
