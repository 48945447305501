/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import {Link} from 'react-router-dom'
import {capitalize, KTSVG, toServerUrl} from '../../../../_metronic/helpers'

import InvitedCustomerMenu from './menus/InvitedCustomerMenu'
import {UserModel, /*InvitedCustomersProps*/} from '../../../../types'
import {makeStyles} from '@material-ui/core/styles'
import {CUSTOMER_LEVELS, CUSTOMER_STATUS} from '../../../../data/constants'
import {getTimeInCentralTime, getAvatar} from '../../../../helper'
import {getCustomers as getCustomersDirect} from '../../../pages/customer/redux/customerCRUD'
import { IAuthState } from '../../auth'
import { PersistPartial } from 'redux-persist/es/persistReducer'
import { RootState } from '../../../../setup'
import { ConnectedProps, connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Typography } from 'antd'

type ItemProps = {
  detail: UserModel;
  showMenu: boolean;
};

const useStyles = makeStyles((theme) => ({
  disabledLink: {
    pointerEvents: 'none',
  },
}));

interface PaginationInformation {
  currentPage: number;
  perPage: number;
  totalRows: number;
}

interface ReduxStateValueProps {
  customerIds: any;
  auth: IAuthState & PersistPartial;
}

// Define mapStateToProps to map the auth state from Redux to props
const mapStateToProps = (state: RootState) => ({
  customerIds: state.auth?.activeCustomerIds,
  auth: state.auth,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type InvitedCustomersProps = PropsFromRedux & {
  admin: boolean;
};

const InvitedCustomerItem: React.FC<ItemProps> = ({detail, showMenu}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const openMenu = (event: any) => setAnchorEl(event.currentTarget)
  const closeMenu = () => setAnchorEl(null)

  const getHelperText = () => {
    const level = capitalize(detail.level)
    let statusText = 'Invite sent on '
    let time = getTimeInCentralTime(detail.createdAt)
    if (detail.status === CUSTOMER_STATUS.ACTIVE) {
      statusText = capitalize(detail?.status) + ' since '
      time = getTimeInCentralTime(detail.activatedAt)
    }

    return `${level} - ${statusText} ${time}`
  }
  
  return (
    <div className='d-flex align-items-center mb-7'>
      {/* begin::Avatar */}
      <div className='symbol symbol-50px me-5'>
        <img src={toServerUrl('/media/avatar/' + getAvatar(detail.avatar))} className='' alt='' />
      </div>
      {/* end::Avatar */}
      {/* begin::Text */}
      <div className='flex-grow-1'>
        <Link
          to={`/customers/view/${detail.id}`}
          title='View'
          style={{cursor: 'pointer', width: '100%'}}
          className='text-dark fw-bolder text-hover-primary fs-6'
        >
          {`${detail.firstname} ${detail.lastname}`}
        </Link>
        <span className='text-muted d-block fw-bold'>{detail.title}</span>
        <span className='text-muted d-block fw-bold'>{getHelperText()}</span>
      </div>
      {showMenu && (
        <div className='symbol symbol-50px'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={openMenu}
          >
            <KTSVG path='/media/icons/duotone/General/Other1.svg' className='svg-icon-2' />
          </button>

          <InvitedCustomerMenu
            detail={detail}
            anchorEl={anchorEl}
            open={open}
            handleClose={closeMenu}
          />
          {/* end::Menu */}
        </div>
      )}
      {/* end::Text */}
    </div>
  )
}

const InvitedCustomers: React.FC<InvitedCustomersProps> = (props) => {
  const { auth, admin } = props; // Destructure auth and admin from props

  const classes = useStyles()

  const initialFilter = React.useMemo(() => {
    return {
      customerIds: props.auth.activeCustomerIds || [],
      level: Object.values(CUSTOMER_LEVELS),
      status: ['active', 'invited'],
      from: '',
      to: '',
      isReset: false,
    }
  }, [])

  const [activeFilters, setActiveFilter] = useState(initialFilter)
  const [data, setData] = useState<UserModel[]>([])
  const isInitialMount = useRef(true);

  const [paginationInformation, setPaginationInformation] = useState<PaginationInformation>({
    currentPage: 1,
    perPage: 50,
    totalRows:0
  })

  const fetchData = async (fromScroll:boolean) => {
    const response = await getCustomersDirect({pagination:paginationInformation, filters:activeFilters})
    let results = response.data
    if (results.length == 0){return}

    const transformedResults: UserModel[] = results.customers.map((item: any) => ({
      id: item.id,
      firstname: item.firstname,
      lastname: item.lastname,
      email: item.email,
      level: item.level,
      status: item.status,
      customerIds: item.customerIds.map((customerId: any) => ({
          customerId: customerId.customerId,
          companyName: customerId.companyName,
          value: customerId.value
      })),
      lastLogin: item.lastLogin, 
      createdAt: item.createdAt,
      activatedAt: item.activatedAt
    }));

    if(fromScroll)
      {
        setData(prevData => [...prevData, ...transformedResults]);
        setPaginationInformation({
          currentPage: results.Pagination.currentPage + 1,
          perPage: results.Pagination.perPage,
          totalRows:results.Pagination.total
        })
      }
      else
      {
        setData(transformedResults);
        setPaginationInformation({
          currentPage: 1,
          perPage: results.Pagination.perPage,
          totalRows:results.Pagination.total
        })
      }
  }

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } 
    else {
      fetchData(false)
    }
      
  }, [activeFilters])
  
  useEffect(() => {
  //filersSet = true;
  setActiveFilter({
    customerIds: props.auth.activeCustomerIds || [],
    level: Object.values(CUSTOMER_LEVELS),
    status: ['active', 'invited'],
    from: '',
    to: '',
    isReset: false})
  }, [auth])

  return (
    <div className='card card-xl-stretch mb-xl-8'>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bolder text-dark'>Invited Customers</h3>
        <div className='card-toolbar'>
          <Link
            to='/customers/new'
            className={`btn btn-sm btn-light-${
              admin ? 'primary' : 'secondary ' + classes.disabledLink
            }`}
          >
            <KTSVG path='media/icons/duotone/Communication/Add-user.svg' className='svg-icon-3' />
            Invite
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-2 overflow-auto' id="scrollableDiv" style={{ maxHeight: '422px' }}>
        <InfiniteScroll
          dataLength={data.length} // This is important field to render the next data
          next={() => fetchData(true)} // Function to fetch more data
          hasMore={data.length < paginationInformation.totalRows} // If there are more items to be loaded
          loader={<h4>Loading...</h4>} // Loader component
          endMessage={<Typography>There are no more customers to display.</Typography>} // End message when no more data
          scrollThreshold={0.9} // Start loading when 90% of the list is scrolled
          scrollableTarget="scrollableDiv" // Set the scrollable target to the div's ID
        >
          {data.map((customer: UserModel) => (
            <InvitedCustomerItem detail={customer} key={customer.id} showMenu={admin} />
          ))}
        </InfiniteScroll>
        {!data.length && <p>There are no invited customers yet.</p>}
      </div>
      {/* end::Body */}
    </div>
  );
};

export default connector(InvitedCustomers);
