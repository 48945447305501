import { Document, Image, Page, Text, View } from '@react-pdf/renderer'
import React from 'react'
import { styles } from './pdf_styles'
import { IInvoiceHdr } from '../../../../../types/Ivoice.type'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import PdfTable from './pdf-table'

type Props = {
  data: IInvoiceHdr
}

export const convertDate = (data: string) => {
  // Input date string in "yyyy-MM-dd" format

  // Parse the input date string
  var parsedDate = new Date(data)

  // Get the components of the date (year, month, day)
  var year = parsedDate.getFullYear().toString().substring(2) // Extract last two digits of the year
  var month = (parsedDate.getMonth() + 1).toString().padStart(2, '0') // Months are zero-based, so add 1
  var day = parsedDate.getDate().toString().padStart(2, '0')

  // Format the date as "MM/dd/yy"
  var formattedDateStr = month + '/' + day + '/' + year

  return formattedDateStr // Output: "08/31/23"
}
const ViewPDFforInvoice = (props: Props) => {
  const { data } = props

  const getDate = () => {
    // Get the current date
    const currentDate = new Date()

    // Define arrays for day and month names
    const daysOfWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    const monthsOfYear = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]

    // Extract day, month, and year components
    const dayOfWeek = daysOfWeek[currentDate.getDay()]
    const month = monthsOfYear[currentDate.getMonth()]
    const dayOfMonth = currentDate.getDate()
    const year = currentDate.getFullYear()

    // Create the formatted date string
    const formattedDate = `Generated ${dayOfWeek}, ${month} ${dayOfMonth}, ${year}`

    return formattedDate // Output: Generated Tuesday, Sep 05, 2023
  }

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.view1}>
          <View style={styles.view_space}>
            <View style={styles.logoView}>
              <Text style={styles.capText}>Coast 2 Coast Calibrations</Text>
              <Text style={styles.capText}>DBA:C2CAL</Text>
              <Text style={styles.capText}>9150 ISANTI STREET NE</Text>
              <Text style={styles.capText}>BLAINE MN 55449</Text>
              <Text style={[styles.caption, { paddingTop: '30px' }]}>Phone: 952.881.7716</Text>
              <Text style={styles.caption}>Fax: 952.881.7309</Text>
              <Image source={toAbsoluteUrl('/media/logo.png')} style={styles.logo} />
            </View>
            <View style={styles.view_center}>
              <Text style={styles.title}>invoice</Text>
              <View style={styles.view2}>
                <View>
                  <View style={styles.table1}>
                    <View style={styles.col1}>
                      <Text style={styles.body2}>Invoice</Text>
                    </View>
                    <View style={styles.col2}>
                      <Text style={styles.body1}>{data.invoice_number}</Text>
                    </View>
                  </View>
                  <View style={styles.table2}>
                    <View style={styles.col11}>
                      <Text style={styles.body2}>Date</Text>
                    </View>
                    <View style={styles.col2}>
                      <Text style={styles.body1}>{convertDate(data.invoice_date)}</Text>
                    </View>
                  </View>
                </View>
                <View>
                  <View style={styles.table1}>
                    <View style={styles.col1}>
                      <Text style={styles.body2}>Order</Text>
                    </View>
                    <View style={styles.col2}>
                      <Text style={styles.body1}>{data.order_number}</Text>
                    </View>
                  </View>
                  <View style={styles.table2}>
                    <View style={styles.col11}>
                      <Text style={styles.body2}>Date</Text>
                    </View>
                    <View style={styles.col2}>
                      <Text style={styles.body1}>{convertDate(data.order_date)}</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.view_space}>
            <View style={styles.col3}>
              <View style={styles.col31}>
                <Text style={styles.body2}>Bill</Text>
                <Text style={styles.body2}>To</Text>
              </View>
              <View style={styles.address_view}>
                <Text style={styles.address_text}>{data.CompanyID}</Text>
                <Text style={styles.address_text}>{data.CompanyName}</Text>
                <Text style={styles.address_text}>{data.BillAddres}</Text>
                <Text style={styles.address_text}>
                  {data.BillState} {data.BillZip}
                </Text>
                <Text style={styles.cell_text}>United States</Text>
              </View>
            </View>
            <View style={styles.col3}>
              <View style={styles.col31}>
                <Text style={styles.body2}>Ship</Text>
                <Text style={styles.body2}>To</Text>
              </View>
              <View style={styles.address_view}>
                <Text style={styles.address_text}>{data.CustomerID}</Text>
                <Text style={styles.address_text}>{data.ShipAttn}</Text>
                <Text style={styles.address_text}>{data.ShipAddres}</Text>
                <Text style={styles.address_text}>
                  {data.ShipState} {data.ShipZip}
                </Text>
                <Text style={styles.cell_text}>United States</Text>
              </View>
            </View>
          </View>
          <PdfTable
            hdr
            rows={[
              `Terms: ${data.net_terms}`,
              `PO Num: ${data.po_number}`,
              `Ship Date: ${convertDate(data.ship_date)}`,
            ]}
          />
          <PdfTable
            hdr
            rows={[
              `Salesrep: ${data.sales_rep ? data.sales_rep.orguser.firstname : ''} ${
                data.sales_rep ? data.sales_rep.orguser.lastname : ''
              } `,
              `FOB: ${data.FOB}`,
              `Ship Via: ${data.ShipVia}`,
            ]}
          />
        </View>
        <View style={styles.dtl_table_view}>
          <PdfTable
            columns={['Order', 'Ship', 'B/O', 'Item/Description', 'UM', 'Price', 'Extended']}
          />
          {data.invoice_dtls.map((item, index) => (
            <PdfTable
              rows={[
                item.qty_ship,
                item.qty_ship,
                item.qty_bo,
                item.description,
                item.uom,
                item.unit_price,
                item.extended_price,
              ]}
              key={index}
            />
          ))}
        </View>
        <View style={styles.view_footer} fixed>
          <View style={styles.col1_footer}>
            <Text style={styles.text_footer_note}>{data.footer_note}</Text>
          </View>
          <View style={styles.col2_footer}>
            <View style={styles.cell}>
              <View style={styles.cell1}>
                <Text style={styles.cell_text}>
                  {data.invoice_type === 'invoice' ? 'sales amount' : 'credit amount'}
                </Text>
              </View>
              <View style={styles.cell2}>
                <Text style={styles.cell_text}>
                  {data.sales_amount}
                  {data.invoice_type === 'invoice' ? '' : '-'}
                </Text>
              </View>
            </View>
            <View style={styles.cell}>
              <View style={styles.cell1}>
                <Text style={styles.cell_text}>discount</Text>
              </View>
              <View style={styles.cell2}>
                <Text style={styles.cell_text}>{data.discount}</Text>
              </View>
            </View>
            <View style={styles.cell}>
              <View style={styles.cell1}>
                <Text style={styles.cell_text}>Sales Tax</Text>
              </View>
              <View style={styles.cell2}>
                <Text style={styles.cell_text}>{data.sales_tax}</Text>
              </View>
            </View>
            <View style={styles.cell}>
              <View style={styles.cell1}>
                <Text style={styles.cell_text}>Freight Amount</Text>
              </View>
              <View style={styles.cell2}>
                <Text style={styles.cell_text}>
                  {data.freight}
                  {data.invoice_type === 'invoice' ? '' : '-'}
                </Text>
              </View>
            </View>
            {/* <View style={styles.cell2}>
              <Text>world</Text>
            </View> */}
            <View style={styles.cell}>
              <View style={[styles.cell1_no_border, { color: 'white', backgroundColor: 'black' }]}>
                <Text style={styles.cell_text}>Total Due</Text>
              </View>
              <View style={styles.cell2_no_border}>
                <Text style={styles.cell_total_text}>
                  {data.invoice_total}
                  {data.invoice_type === 'invoice' ? '' : '-'}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.footer}>
          <View style={styles.footer_col1}>
            <View style={styles.footer_col1}>
              <Text style={styles.cell_text}>Please remit to:</Text>
            </View>
            <View style={styles.footer_col11}>
              <Text style={styles.text_footer_note}>COAST 2 COAST CALIBRATIONS</Text>
              <Text style={styles.text_footer_note}>HIGHLAND BANK ACH INFORMATION</Text>
              <Text style={styles.text_footer_note}>ROUTING: 091916378</Text>
              <Text style={styles.text_footer_note}>ACCOUNT: 3314100240</Text>
            </View>
          </View>
          <View style={styles.footer_col2}>
            <View>
              <Text style={styles.text_page}>{getDate()}</Text>
            </View>
            <View>
              <Text
                style={styles.text_page}
                render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
              />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}
export default React.memo(ViewPDFforInvoice)
