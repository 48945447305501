import { Action } from '@reduxjs/toolkit'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  UserSettingsUpdated: '[Update User Settings] Action',
}

const initialUserSettingsState: IUserSettingsState = {
  loading: false,
  play_sound_notification: 0,
}

export interface IUserSettingsState {
  loading: boolean
  play_sound_notification: any
}

export const reducer = (
  state: IUserSettingsState = initialUserSettingsState,
  action: ActionWithPayload<IUserSettingsState>
) => {
  switch (action.type) {
    case actionTypes.UserSettingsUpdated: {
      return {
        ...state,
        ...action.payload,
      }
    }

    default:
      return state
  }
}

export const actions = {
  updateUserSettings: (key: string, value: string) => ({
    type: actionTypes.UserSettingsUpdated,
    payload: { [key]: value },
  }),
}
