/* eslint-disable jsx-a11y/anchor-is-valid */
// This file shows the orders that are ready to be completed and payed. This file as of 3/13/2024 should only show invoiced and billing complete orders with a payment option of credit card
import { FC, useState, useEffect, useCallback } from 'react'
import moment from 'moment'
import toast from 'react-hot-toast'

import { ConnectedProps, connect } from 'react-redux'
import { RootState } from '../../../../setup'
import * as serviceOrderRedux from '../../service-orders/serviceOrdersRedux'
import * as salesOrderRedux from '../../sales-orders/salesOrdersRedux'
import { KTSVG } from '../../../../_metronic/helpers'
import * as levelHelper from '../../../../helper/level.helper'
import { getErrorMessage } from '../../../../helper'

import { chargeQuotePayment } from '../../../services/payment'
import CompletePay from '../../../components/_modal/CompletePay'

import { QuoteModel, PaymentOption } from '../../../../types'
import { ConfirmModal } from '../../../components/_modal/ConfirmModal'

const mapState = (state: RootState) => ({
  ...serviceOrderRedux.actions,
  ...salesOrderRedux.actions,

  serviceOrders: state.serviceOrders.serviceOrders,
  salesOrders: state.salesOrders.salesOrders,

  loadingServiceOrders: state.serviceOrders.loading,
  loadingSalesOrders: state.salesOrders.loading,

  customerIds: state.auth?.activeCustomerIds,
  auth: state.auth,
})

const connector = connect(mapState, {
  ...serviceOrderRedux.actions,
  ...salesOrderRedux.actions,
})

type DashboardCompletedOrdersProps = ConnectedProps<typeof connector>

const DashboardCompletedOrders: FC<DashboardCompletedOrdersProps> = ({
  serviceOrders,
  salesOrders,
  loadingServiceOrders,
  loadingSalesOrders,
  auth,
  customerIds,
  getServiceOrders,
  getSalesOrders,
  ...props
}) => {
  // Requested by oxana 3/13 for cc orders to show as invoiced and billing complete
  const initialFilter = {
    status: ['invoiced', 'iqcc'],
    from: '',
    to: moment().utc().format('Y-MM-DD HH:mm'),
    isReset: false,
  }

  const [keywords, setKeywords] = useState('')

  const [orders, setOrders] = useState<QuoteModel[]>([])
  const [completedOrders, setCompletedOrders] = useState<QuoteModel[]>([])

  const [isNBS] = useState<boolean>(levelHelper.isNBS(auth.user?.type))
  const [nbsCustomerId] = useState<string[]>(['nbs'])
  const [orderId, setOrderId] = useState<number>(0)
  const [quoteNumber, setSetQuoteNumber] = useState<string>('')
  const [customerId, setCustomerId] = useState<string>('')
  const [totalAmount, setTotalAmount] = useState<string | undefined>('')
  const [quoteAction, setQuoteAction] = useState<PaymentOption[]>([])
  const [, setShowOptionsModal] = useState<boolean>(false)
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false)
  const [updateStatus, setUpdateStatus] = useState<boolean>(false)
  const [confirmAlert, setConfirmAlert] = useState({
    show: false,
    content: '',
    onConfirm: () => {},
  })
  const [po2, setPo2] = useState<string>('')

  useEffect(() => {
    if (loadingServiceOrders || loadingSalesOrders) {
      return
    }

    const completedOrders = [...serviceOrders, ...salesOrders].filter((order: QuoteModel) => {
      const { paymentOptions } = order
      if (!paymentOptions) return false

      const [paymentOption] = paymentOptions

      if (!paymentOption) return false

      const { meta } = paymentOption
      if (!meta) return false
      // return true
      return JSON.parse(meta).paymentType == 'cc'
    })
    setCompletedOrders(completedOrders)
  }, [serviceOrders, salesOrders, loadingServiceOrders, loadingSalesOrders])

  useEffect(() => {
    const searchedOrders = completedOrders.filter((quote: QuoteModel) => {
      if (quote.quoteNumber.toLowerCase().includes(keywords.toLowerCase())) {
        return true
      }

      if (quote.salesRep?.toLowerCase().includes(keywords.toLowerCase())) {
        return true
      }

      if (quote.transactionType?.toLowerCase().includes(keywords.toLowerCase())) {
        return true
      }

      if (quote.attention?.toLowerCase().includes(keywords.toLowerCase())) {
        return true
      }

      return false
    })

    setOrders(searchedOrders)
  }, [keywords, completedOrders])

  const getOrders = () => {
    const orderFilter = {
      ...initialFilter,
      customerIds: isNBS ? nbsCustomerId : customerIds,
    }

    getServiceOrders(orderFilter)
    getSalesOrders(orderFilter)
  }

  useEffect(() => {
    getOrders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showConfirmAlert = useCallback((content: string, onConfirm: any) => {
    setConfirmAlert({ show: true, content, onConfirm })
  }, [])

  const handleCompletePay = (data: QuoteModel) => {
    setOrderId(data.dbkey)
    setSetQuoteNumber(data.quoteNumber)
    setTotalAmount(data?.total)
    setCustomerId(data?.customerId)
    setQuoteAction(data.paymentOptions)
    setShowCompleteModal(true)
    setPo2(data.po2)
  }

  const handleCharge = (payload: any) => {
    setUpdateStatus(false)

    chargeQuotePayment(payload)
      .then((res) => {
        getOrders()
        setUpdateStatus(false)
        if (res.data === 'success') toast.success('Charged Customer Profile successfully.')
        else toast.error(res.data)
      })
      .catch((error) => {
        const errMsg = getErrorMessage(error)
        toast.error(errMsg)
      })
      .finally(() => {
        setQuoteAction([])
        setShowCompleteModal(false)
      })
  }

  const handleHide = () => {
    setShowOptionsModal(false)
    setShowCompleteModal(false)
  }
  if (loadingServiceOrders || loadingSalesOrders) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }
  return (
    <>
      {completedOrders.length !== 0 && !loadingServiceOrders && !loadingSalesOrders && (
        <>
          <div className='card card-xxl-stretch mb-5 mb-xl-8'>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5 min-h-50px justify-content-start'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>
                  <span className='position-relative'>
                    <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle mt-n2 top-0 start-50 animation-blink'></span>
                    {completedOrders.length}
                  </span>{' '}
                  Orders to be Paid
                </span>
              </h3>
              <div className='d-flex position-relative'>
                <KTSVG
                  path='/media/icons/duotone/General/Search.svg'
                  className='svg-icon-1 svg-icon-2 position-absolute top-50 ms-2 translate-middle-y mt-0'
                />
                <input
                  type='text'
                  value={keywords}
                  onChange={(e: any) => setKeywords(e.target.value)}
                  className='form-control form-control-flush ps-10 bg-light rounded'
                  name='search'
                  placeholder='Search...'
                />
              </div>
            </div>
            {/* end::Header */}
            <div className='card-body overflow-y-scroll mh-500px mt-5'>
              <div className='row g-6 g-xl-9'>
                {orders.map((quote: any) => {
                  return (
                    <div className='col-md-6 col-xl-6 col-lg-4 mt-2' key={quote.dbkey}>
                      <div className='card border border-1 border-gray-300'>
                        {/* begin::Header */}
                        <div className='card-header border-0 py-4 px-4 bg-light'>
                          <div className='d-flex'>
                            <div className='me-4'>
                              <div className='text-dark fw-bolder fs-6'>Quote Placed</div>
                              <div>{moment(quote.createdAt).format('MMMM DD, YYYY')}</div>
                            </div>
                            <div className='me-4'>
                              <div className='text-dark fw-bolder fs-6'>TOTAL</div>
                              <div>${quote.total ?? 0}</div>
                            </div>
                            {/* <div className='me-4'>
                              <div className='text-dark fw-bolder fs-6'>Your Rep</div>
                              <div style={{ maxWidth: '90px' }} className='text-truncate'>
                                {quote.salesRep}
                              </div>
                            </div> */}
                            <div className=''>
                              <div className='text-dark fw-bolder fs-6'>Type</div>
                              <div>{quote.transactionType}</div>
                            </div>
                          </div>
                          <div>
                            <div className='text-dark fw-bolder fs-6'>
                              Quote# {quote.quoteNumber}
                            </div>
                            <div className='text-end'>
                              <a
                                href={`${process.env.REACT_APP_NBS_ONLINE_URL}processing_pdf.php?dbkey_quote_b=${quote.dbkey}&order_type=order_portal&pdf=y`}
                                target='_blank'
                                rel='noreferrer'
                              >
                                View Details
                              </a>
                            </div>
                          </div>
                        </div>
                        {/* end::Header */}

                        {/* begin::Content */}
                        <div className='card-body p-3 px-9'>
                          <div className='row'>
                            <div className='col-md-7'>
                              {quote.items.length !== 0 && (
                                <div className='text-dark fw-bolder fs-6 pt-7'>
                                  Itemized Details
                                </div>
                              )}
                              {quote.items.map((item: any) => {
                                return (
                                  <div className='row' key={`${quote.dbkey}${item.dbkey}`}>
                                    <div className='col-md-3 mt-5'>
                                      <div>{Number(item.quantity) ? item.quantity : ''}</div>
                                    </div>
                                    <div className='col-md-3 mt-5'>
                                      <div>{item.description}</div>
                                    </div>
                                    <div className='col-md-3 mt-5'>
                                      <div>{item.part_number}</div>
                                    </div>
                                    <div className='col-md-3 mt-5'>
                                      <div>
                                        {Number(item.unit_price) ? '$' + item.unit_price : ''}
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                            <div className='col-md-5 text-end my-5'>
                              <button
                                type='button'
                                className='btn btn-success ms-3'
                                onClick={() => handleCompletePay(quote)}
                              >
                                Complete and Pay
                              </button>
                            </div>
                          </div>
                        </div>
                        {/* end::Content */}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <CompletePay
            submit={handleCharge}
            status={updateStatus}
            show={showCompleteModal}
            onHide={handleHide}
            actions={quoteAction}
            orderId={orderId}
            quoteNumber={quoteNumber}
            totalAmount={totalAmount}
            customerId={customerId}
            showConfirmAlert={showConfirmAlert}
            po2={po2}
          />
          {confirmAlert.show && (
            <ConfirmModal
              content={confirmAlert.content}
              yesText='Confirm'
              noText='Cancel'
              selectYes={() => {
                confirmAlert.onConfirm()
                setConfirmAlert({ ...confirmAlert, show: false })
              }}
              selectNo={() => setConfirmAlert({ ...confirmAlert, show: false })}
            />
          )}
        </>
      )}
    </>
  )
}

export default connector(DashboardCompletedOrders)
