import React, { useCallback, useEffect, useState } from 'react'
import { Card } from 'react-bootstrap-v5'
import { TableColumn } from 'react-data-table-component'
import toast from 'react-hot-toast'
import { getErrorMessage } from '../../../../helper'
import { CustomerFriendlyStatusCodeModel } from '../../../../types/CustomerFriendlyStatusCode'
import { KTSVG } from '../../../../_metronic/helpers'
import { PageTitle } from '../../../../_metronic/layout/core'
import DataTableBase from '../../../components/DataTableBase'

import { CustomerFriendlyStatusCodeActionDropDown } from './components/CustomerFriendlyStatusCodeActionDropDown'
import { EditCustomerFriendlyStatusCodeModal } from './components/EditCustomerFriendlyStatusCodeModal'
import { getCustomerFriendlyStatusCodes } from './CustomerFriendlyStatusCodesCrud'

import { useDebounce } from "use-debounce"

const CustomerFriendlyStatusCodesPage: React.FC = () => {
  const initialValues: CustomerFriendlyStatusCodeModel = {
    dbkey_tables: '',
    dbkey: '',
    description: '',
    customer_freindly_code: '',
    customer_hover_text: '',
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [customerFriendlyStatusCodes, setCustomerFriendlyStatusCodes] = useState<CustomerFriendlyStatusCodeModel[]>([])
  const [, setSelectedData] = useState<CustomerFriendlyStatusCodeModel[]>([])
  const [slug, setSlug] = useState<string>('')
  const [showModal, setShowModal] = useState<boolean>(false)
  const [editCustomerFriendlyStatusCode, setEditCustomerFriendlyStatusCode] = useState<CustomerFriendlyStatusCodeModel>(initialValues)

  const [debouncedSlug] = useDebounce(slug, 1000);

  const handleSearch = (value: string) => {
    setSlug(value)
  }

  const handleEdit = useCallback((data: CustomerFriendlyStatusCodeModel) => {
    setShowModal(true)
    setEditCustomerFriendlyStatusCode(data)
  }, [])

  const handleCloseModal = useCallback((isShowConfirm: boolean | undefined) => {
    // if (isShowConfirm === true) {
    //   setShowConfirm(true)
    // } else {
    //   setShowModal(false)
    // }
    setShowModal(false)
  }, [])

  const handleRowsChange = (state: any) => {
    setSelectedData(state.selectedRows);
  }

  const fetchData = useCallback(() => {
      setIsLoading(true)

      getCustomerFriendlyStatusCodes(debouncedSlug)
      .then((res) => {
        setCustomerFriendlyStatusCodes(res.data)
        setIsLoading(false)
      })
      .catch((error) => {
        const errMsg = getErrorMessage(error)
        toast.error(errMsg)
      })
    }, [debouncedSlug]
  )

  useEffect(() => {
    fetchData()
  }, [fetchData, slug])

  const columns: TableColumn<CustomerFriendlyStatusCodeModel>[] = [
    {
      name: 'ACTIONS',
      cell: row => (
        <CustomerFriendlyStatusCodeActionDropDown data={row} handleEdit={handleEdit} />
      ),
      width: '200px',
      style: {
        minWidth: '200px !important',
        maxWidth: '200px !important',
      },
    },
    {
      name: 'DESCRIPTION',
      selector: (row) => row.description,
      sortable: true,
      cell: row =>
        <div className='d-flex align-items-center'>
          <div className='d-flex flex-column'>
            <span className='text-gray-800 text-hover-primary mb-1'>{row.description}</span>
          </div>
        </div>
      ,
      style: {
        maxWidth: 'unset !important',
        textAlign: 'left'
      }
    },
    {
      name: 'CUSTOMER FRIENDLY CODE',
      selector: (row) => row.customer_freindly_code,
      sortable: true,
      cell: row =>
        <div className='d-flex align-items-center'>
          <div className='d-flex flex-column'>
            <span className='text-gray-800 text-hover-primary mb-1'>{row.customer_freindly_code}</span>
          </div>
        </div>
      ,
      width: '30%',
      style: {
        minWidth: '30% !important',
        maxWidth: '30% !important',
      },
    },
    {
      name: 'CUSTOMER HOVER TEXT',
      selector: (row) => row.customer_hover_text,
      sortable: true,
      cell: row =>
        <div className='d-flex align-items-center'>
          <div className='d-flex flex-column'>
            <span className='text-gray-800 text-hover-primary mb-1'>{row.customer_hover_text}</span>
          </div>
        </div>
      ,
      width: '200px',
      style: {
        minWidth: '200px !important',
        maxWidth: '200px !important',
      },
    },
  ];

  return (
    <>
      <PageTitle breadcrumbs={[]}>Manage Customer Friendly Status Codes</PageTitle>
      <Card className='min-vh-100'>
        <Card.Header className='border-0 pt-10'>
          <Card.Title>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTSVG
                path='/media/icons/duotone/General/Search.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                type='text'
                className='form-control form-control-solid w-250px ps-15'
                name='search'
                onChange={(e) => handleSearch(e.target.value)}
                placeholder='Search Codes'
              />
            </div>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <div className='file-management-table-wrapper'>
            <DataTableBase
              pagination
              columns={columns}
              data={customerFriendlyStatusCodes}
              progressPending={isLoading}
              selectableRows
              onSelectedRowsChange={handleRowsChange}
              onSort={() => {}}
            />
          </div>
        </Card.Body>
      </Card>

      {/* {showConfirm && (
      <ConfirmCustomerFriendlyStatusCodeModal
        content='Are you sure you would like to cancel?'
        yesText='Yes, cancel it!'
        noText='No, return'
        selectYes={handleConfirmCancel}
        selectNo={handleConfirmReturn}
      />
    )} */}

      <EditCustomerFriendlyStatusCodeModal
        show={showModal}
        customerFriendlyStatusCode={editCustomerFriendlyStatusCode}
        setCustomerFriendlyStatusCodes={setCustomerFriendlyStatusCodes}
        onHide={handleCloseModal}
      />
    </>
  )
}

export default CustomerFriendlyStatusCodesPage
