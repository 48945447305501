import { Action } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { QuoteModel } from '../../../types';
import { getServiceQuotes, getTrnTypes } from './serviceQuotesCrud';

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

// Define action types
export const actionTypes = {
  ServiceQuotesRequested: '[Request Service Quotes] Action',
  ServiceQuotesLoaded: '[Load Service Quotes] Action',
  SetQuoteNumberSlug: '[Set Quote Number Slug on Service Quotes]',
  TransactionTypesRequested: '[Request Transaction Types] Action',
  TransactionTypesLoaded: '[Load Transaction Types] Action',
};

// Define the initial state for service quotes
const initialQuotesState: IServiceQuotesState = {
  loading: false,
  serviceQuotes: [],
  quoteNumberSlug: null,
  totals: 0,
  transactionTypes: [],
  transactionTypesLoaded: false,
};

// Define the IServiceQuotesState interface
export interface IServiceQuotesState {
  loading: boolean;
  serviceQuotes: QuoteModel[];
  quoteNumberSlug: string | null;
  totals: number;
  transactionTypes: string[];
  transactionTypesLoaded: boolean;
}

// Reducer to handle actions and update the state
export const reducer = (
  state: IServiceQuotesState = initialQuotesState,
  action: ActionWithPayload<any>
): IServiceQuotesState => {
  switch (action.type) {
    case actionTypes.ServiceQuotesRequested:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ServiceQuotesLoaded:
      return {
        ...state,
        loading: false,
        serviceQuotes: action.payload.serviceQuotes,
        totals: action.payload.totals,
      };
    case actionTypes.SetQuoteNumberSlug:
      return {
        ...state,
        quoteNumberSlug: action.payload.quoteNumberSlug,
      };
    case actionTypes.TransactionTypesRequested:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.TransactionTypesLoaded:
      return {
        ...state,
        loading: false,
        transactionTypes: action.payload.transactionTypes,
        transactionTypesLoaded: true,
      };
    default:
      return state;
  }
};

// Define actions for the service quotes
export const actions = {
  getServiceQuotes: (searchFilter: any = {}) => ({
    type: actionTypes.ServiceQuotesRequested,
    payload: { searchFilter },
  }),
  serviceQuotesLoaded: (serviceQuotes: QuoteModel[], totals: number) => ({
    type: actionTypes.ServiceQuotesLoaded,
    payload: { serviceQuotes, totals },
  }),
  setQuoteNumberSlug: (quoteNumberSlug: any) => ({
    type: actionTypes.SetQuoteNumberSlug,
    payload: { quoteNumberSlug },
  }),
  getTransactionTypes: () => ({
    type: actionTypes.TransactionTypesRequested,
  }),
  transactionTypesLoaded: (transactionTypes: string[]) => ({
    type: actionTypes.TransactionTypesLoaded,
    payload: { transactionTypes },
  }),
};

// Define the shape of the entire Redux state
export interface RootState {
  serviceQuotes: IServiceQuotesState;
}

// Saga function to fetch service quotes
function* serviceQuotesRequested(action: any) {
  const { searchFilter } = action.payload;

  // Fetch service quotes from the API
  const { data: { serviceQuotes, totals } } = yield call(getServiceQuotes, searchFilter);

  // Dispatch action to update the state with fetched service quotes
  yield put(actions.serviceQuotesLoaded(serviceQuotes, totals));
}

// Saga function to fetch transaction types
function* transactionTypesRequested() {
  // Select the current state from the Redux store
  const state: RootState = yield select();

  // Check if transaction types are already loaded
  console.log('TransactionTypesRequested action dispatched');
  if (!state.serviceQuotes.transactionTypesLoaded) {
    // Fetch transaction types from the API
    const { data: transactionTypes } = yield call(getTrnTypes);

    // Dispatch action to update the state with fetched transaction types
    yield put(actions.transactionTypesLoaded(transactionTypes));
  }else
  {
    console.log('TransactionTypesRequested already loaded');
  }
}

// Root saga to manage watcher lifecycle
export function* saga() {
  yield takeLatest(actionTypes.ServiceQuotesRequested, serviceQuotesRequested);
  yield takeLatest(actionTypes.TransactionTypesRequested, transactionTypesRequested);
}
