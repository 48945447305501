import React from 'react';
import { Document, Page, View, Text, Image, StyleSheet, Font } from '@react-pdf/renderer';

// Register both regular and bold fonts using local files
Font.register({
  family: 'Tinos',
  fonts: [
    { src: '/fonts/Tinos/Tinos-Regular.ttf' }, // Local path to Tinos Regular font
    { src: '/fonts/Tinos/Tinos-Bold.ttf', fontWeight: 'bold' }, // Local path to Tinos Bold font
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: '20px 20px 20px 50px', // Increase left padding for a thicker margin
    fontFamily: 'Tinos', // Use Tinos font
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  logo: {
    width: 95,
  },
  companyInfo: {
    textAlign: 'right',
    fontSize: 9, // Increased by 2 points (was 8)
    lineHeight: 1.4,
  },
  title: {
    textAlign: 'center',
    marginTop: 15,
    marginBottom: 15,
    fontSize: 16, // Increased by 2 points (was 14)
    fontWeight: 'bold',
  },
  infoSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 2,
    textAlign: 'left',
  },
  infoColumn: {
    width: '50%',
    fontSize: 11, // Increased by 2 points (was 9)
  },
  infoLabel: {
    fontWeight: 'bold',
  },
  table: {
    width: '100%',
    marginVertical: 10,
    border: '1px solid black',
    borderCollapse: 'collapse',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid black',
    paddingVertical: 2,
  },
  tableCell: {
    border: '1px solid black',
    padding: 4,
    fontSize: 10, // Increased by 2 points (was 9)
    textAlign: 'center',
  },
  leftAlignCell: {
    textAlign: 'left', // Left align for operation descriptions
  },
  remarksSection: {
    marginTop: 20,
  },
  sectionTitle: {
    fontWeight: 'bold',
    fontSize: 14, // Increased by 2 points (was 12)
    marginBottom: 5,
  },
});

const MyMicroscopeDocument = ({ data, commentsOn }: { data: any; commentsOn: boolean }) => {
  // Manually define the operations list from the original PDF
  const operationsList = [
    'Initial inspection of optical performance using a test slide.',
    'Remove oculars, head piece and condenser assembly.',
    'Clean microscope body and mechanical stage.',
    'Inspect mechanical stage for proper operation and clean and lubricate as required.',
    'Clean light source condenser and condenser assembly and phase assembly if present.',
    'Install condenser and phase (if present).',
    'Remove and clean objectives.',
    'Reinstall objectives.',
    'Inspect microscope head and clean interior optical surfaces as required.',
    'Reinstall microscope head.',
    'Remove and clean oculars.',
    'Inspect and clean reticule (if present) as necessary.',
    'Reinstall oculars.',
    'Turn illuminator on and align and position condenser assembly.',
    'Adjust focuser mechanism for proper tension and smoothness. Lubricate as necessary.',
    'If present align phase assembly.',
    'Check and adjust optical alignment as required.',
    'Perform final checkout using a test slide.',
  ];

  // Safely parse operations, or return an empty array if parsing fails or if it's already an array
  let operations = [];

  try {
    // If operations is a string, try parsing it
    operations = typeof data.operations === 'string' ? JSON.parse(data.operations) : data.operations;
  } catch (e) {
    console.error('Error parsing operations:', e);
    operations = [];
  }

  return (
    <Document>
      <Page style={styles.page}>
        {/* Header with Logo and Company Info */}
        <View style={styles.header}>
          <Image src="/media/logos/Coast-To-Coast-Calibrations-Black-NBG.png" style={styles.logo} />
          <View style={styles.companyInfo}>
            <Text style={{ fontSize: 14, fontWeight: 'bold' }}>C2CAL</Text>
            <Text>Coast 2 Coast Calibrations Inc.</Text>
            <Text>9150 Isanti St. NE</Text>
            <Text>Blaine, MN 55449</Text>
            <Text>PHONE: (952)881-7716</Text>
            <Text>(800)722-5398 FAX: (952)881-7309</Text>
            <Text>www.c2cal.com</Text>
          </View>
        </View>

        {/* Title */}
        <Text style={styles.title}>MICROSCOPE MAINTENANCE RECORD</Text>

        {/* Customer and Equipment Information */}
        <View style={styles.infoSection}>
          <View style={styles.infoColumn}>
            <Text>
              <Text style={styles.infoLabel}>Manufacturer: </Text>
              {data.manufacturer || 'N/A'}
            </Text>
            <Text>
              <Text style={styles.infoLabel}>Model: </Text>
              {data.model || 'N/A'}
            </Text>
            <Text>
              <Text style={styles.infoLabel}>Serial Number: </Text>
              {data.serial_number || 'N/A'}
            </Text>
            <Text>
              <Text style={styles.infoLabel}>Equipment ID: </Text>
              {data.equipment_type || 'N/A'}
            </Text>
            <Text>
              <Text style={styles.infoLabel}>Department: </Text>
              {data.dept || 'N/A'}
            </Text>
            <Text>
              <Text style={styles.infoLabel}>Location: </Text>
              {data.location || 'N/A'}
            </Text>
          </View>
          <View style={styles.infoColumn}>
            <Text>
              <Text style={styles.infoLabel}>Customer ID: </Text>
              {data.customer_id || 'N/A'}
            </Text>
            <Text>
              <Text style={styles.infoLabel}>Customer Name: </Text>
              {data.customer_name || 'N/A'}
            </Text>
            <Text>
              <Text style={styles.infoLabel}>Address: </Text>
              {data.address1 || 'N/A'}
            </Text>
            <Text>
              <Text style={styles.infoLabel}>Technician: </Text>
              {data.technician || 'N/A'}
            </Text>
            <Text>
              <Text style={styles.infoLabel}>Date: </Text>
              {data.date || 'N/A'}
            </Text>
          </View>
        </View>

        {/* Maintenance Operations Table */}
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={{ ...styles.tableCell, width: '60%', fontWeight: 'bold' }}>Operation</Text>
            <Text style={{ ...styles.tableCell, width: '20%', fontWeight: 'bold' }}>Completed</Text>
            <Text style={{ ...styles.tableCell, width: '20%', fontWeight: 'bold' }}>Comments</Text>
          </View>
          {
            console.log("OPERATIONS", operations)

          }
          {operationsList.map((operation, index) => (
            <View style={styles.tableRow} key={index}>
              <Text style={{ ...styles.tableCell, width: '60%', ...styles.leftAlignCell }}>
                {index + 1}. {operation} {/* Display operation description */}
              </Text>
              <Text style={{ ...styles.tableCell, width: '20%' }}>
                {operations[index]?.status === 'Y' ? 'Yes' : operations[index]?.status === 'N' ? 'No' : 'N/A'} {/* Display operation status */}
              </Text>
              <Text style={{ ...styles.tableCell, width: '20%' }}>
                {operations[index]?.comment || ''} {/* Display comment */}
              </Text>
            </View>
          ))}
        </View>

        {/* Remarks Section */}
        {commentsOn && data.remarks && (
          <View style={styles.remarksSection}>
            <Text style={styles.sectionTitle}>Remarks:</Text>
            <Text>{data.remarks || ''}</Text>
          </View>
        )}
      </Page>
    </Document>
  );
};

export default MyMicroscopeDocument;
