import axios from 'axios'

const API_ROUTE = process.env.REACT_APP_API_URL
const GET_REPORTS_API_URL = `${API_ROUTE}/calibration-reports`
const GET_CUSTOMER_IDS_API_URL = `${API_ROUTE}/calibration-reports/get-customer-ids`
const GET_GET_CALIBRATION_TYPES = `${API_ROUTE}/calibration-type/get`

export function getCalibrationReports(searchFilter = {}) {
  return axios.get(`${GET_REPORTS_API_URL}`, { params: searchFilter })
}

export function getCalibrationRepotCustomerIds() {
  return axios.get(`${GET_CUSTOMER_IDS_API_URL}`)
}

export function getCalibrationTypes() {
  return axios.get(GET_GET_CALIBRATION_TYPES)
}

export function getMicroscopeReports(data: any) { 
  return axios.get(`${API_ROUTE}/all-reports/getMicroscope`, {
    params: data
  });
}

export function getThermalReports(data: any) { 
  return axios.get(`${API_ROUTE}/all-reports/getThermal`, {
    params: data
  });
}