import axios from "axios";

export const APP_URL = process.env.REACT_APP_API_URL;
export const CONFIGURATION_SETTING_URL = `${process.env.REACT_APP_API_URL}/rcv-approvals`

export function getAllUsers(userId: number) {
  return axios.get(`${CONFIGURATION_SETTING_URL}/get-all-users/${userId}`);
}

export function getReceiveApprovals(section: string, slug: string) {
  return axios.get(`${CONFIGURATION_SETTING_URL}/get-rcv-approvals/${section}/${slug}`);
}

export function addNewUser(newUser: {}) {
  return axios.post(`${CONFIGURATION_SETTING_URL}/add-new-user`, { newUser });
}

export function updateReceiveApproval(update: {}) {
  return axios.post(`${CONFIGURATION_SETTING_URL}/update-rcv-approval`, { update });
}

export function deleteReceiveApproval(dbkey: string) {
  return axios.delete(`${CONFIGURATION_SETTING_URL}/delete-rcv-approval/${dbkey}`);
}

export function deleteReceiveApprovals(dbKeys: string[]) {
  return axios.post(`${CONFIGURATION_SETTING_URL}/delete-rcv-approvals`, { dbKeys });
}