import { Action } from '@reduxjs/toolkit'
import { put, takeLatest } from 'redux-saga/effects'
import { ContactModel } from '../../../types'
import { getNBSContacts } from './ContactsCRUD'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  ContactsRequested: '[Request Contacts] Action',
  ContactsLoaded: '[Load Contacts] Action',
}

const initialContactsState: ContactsState = {
  loading: false,
  nbsContacts: {
    dbkey: 0,
    CustomerID: '',
    email: '',
    FirstName: '',
    LastName: '',
  },
}

export interface ContactsState {
  loading: boolean
  nbsContacts: ContactModel
}

export const reducer = (
  state: ContactsState = initialContactsState,
  action: ActionWithPayload<ContactsState>
) => {
  switch (action.type) {
    case actionTypes.ContactsRequested: {
      return { ...state, loading: true }
    }

    case actionTypes.ContactsLoaded: {
      const nbsContacts = action.payload?.nbsContacts

      return { loading: false, nbsContacts }
    }

    default:
      return state
  }
}

export const actions = {
  getNBSContacts: (CustomerID: string) => ({
    type: actionTypes.ContactsRequested,
    payload: { CustomerID },
  }),
  contactsLoaded: (nbsContacts: ContactModel) => ({
    type: actionTypes.ContactsLoaded,
    payload: { nbsContacts },
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.ContactsRequested, function* contactsRequested({ payload }: any) {
    const { data: nbsContacts } = yield getNBSContacts({
      ...payload,
    })
    yield put(actions.contactsLoaded(nbsContacts))
  })
}
