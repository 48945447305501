import React, { useState, useCallback, useEffect } from 'react';
import toast from 'react-hot-toast';
import { Modal } from 'react-bootstrap-v5';
import { Accordion, Card, Button } from 'react-bootstrap-v5';
import { KTSVG } from '../../../../_metronic/helpers';
import moment from 'moment';
import { downloadAttachment } from '../../service-quotes/serviceQuotesCrud';
import { getMicroscopeReports, getThermalReports} from '../../all-reports/AllReportsCrud';
import { Box, Checkbox, Grid, Typography, Dialog } from '@material-ui/core';
import { CircularProgressWithLabel } from '../../calibration-reports/component/AddCalReportModal';

import { CalibrationReportModel, ICalibrationReportAttachment } from '../../../../types';
import { AccreditReports } from '../../../../types/accredit-reports/AccreditReportsTypes';
import { IEquipmentReportsModel } from '../../../../types/equipment-reports/EquipmentReportModel';
import ReactPDF from '@react-pdf/renderer';
import MyDocument from '../../accredit-reports/components/PdfTemplate';
import MyMicroscopeDocument from "../../equipment-reports/components/MyMicroscopeDocument"
import { QuestionTooltip} from  "./QuestionToolTip"
//"portal_frontendv2\src\app\pages\equipment-reports\components\MyDocument.tsx

import axios from 'axios';
import { GET_REPORTS_API_URL } from '../../accredit-reports/AccreditReportsCrud';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getAllReportDownloads } from '../CustomersCRUD';
import MyThermalDocument from '../../equipment-reports/components/MyThermalDocument';
//import * as otherReportsPDF from "../../../../../../nbs_online/includes/class/pdf_cls.php"; //this was stupid.

interface downloadedReports {
  [key: string]: {
    date: string;
    labFileInfo?: CalibrationReportModel[] | undefined;
    balanceFileInfo?: AccreditReports[] | undefined;
    otherReportsFileInfo?: IEquipmentReportsModel[] | undefined;
  }
}

interface paginationInformation
{
  stopDate: string
  currentMonth: number;
  monthReach: string; //whyyyy?
  stopSearching: boolean
}

type Props = {
  show: boolean;
  onHide: any;
  downloadCustomerId: string;
  isNBS?: boolean;
};

const initialPaginationValues = {stopDate:"-1", currentMonth:0, monthReach:"8", stopSearching:false}

const AllReportsAttachmentModal: React.FC<Props> = (props) => {
  const { show, onHide, downloadCustomerId, isNBS } = props;

  //const reportKeys = Object.keys(downloadedReports);
  const [downloadedReports, setDownloadedReports] = useState<downloadedReports[]>([])
  //const [reportKeys, setReportKeys] = useState<string[]>([])
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [selectedBalanceFiles, setSelectedBalanceFiles] = useState<AccreditReports[]>([]);
  const [selectedOtherFiles, setSelectedOtherFiles] = useState<IEquipmentReportsModel[]>([]);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploading, setUploading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [hasDoneInitialRun, setHasDoneInitialRun] = useState<boolean>(false)
  const [checkedMonths, setCheckedMonths] = useState({});
  const [checkAllLabReports, setCheckAllLabReports] = useState({});
  const [checkAllBalanceReports, setCheckAllBalanceReports] = useState({});
  const [checkAllOtherReports,  setCheckAllOtherReports] = useState({});

  const[fetchLabReports, setFetchLabReports] = useState<boolean>(true)
  const[fetchBalanceReports, setFetchBalanceReports] = useState<boolean>(true)
  const[fetchOtherReports, setFetchOtherReports] = useState<boolean>(false)
  //var hasDoneInitialRun = false
  
  //Infinite Scroll
  const [hasMore, setHasMore] = useState(true); // To track if more reports are available
  const [loading, setLoading] = useState(true);

  const [paginationInformation, setPaginationInformation] = useState<paginationInformation>(initialPaginationValues)

  const [searchString, setSearchString] = useState<string>("")
  const [searchWaiting, setSearchWaiting] = useState<boolean>(false)

  function wait(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const debounce = (func: Function, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: any) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  
  const handleSearch = (value: string) => {
    setSearchString(value);
  }

  const debouncedHandleSearch = useCallback(debounce(handleSearch, 500), []);

  useEffect(() => {
    if(!show) return;
    if(!loading)
      {
        setDownloadedReports([])
        setHasDoneInitialRun((prevValue) => (prevValue && false))
        setHasMore((prevValue) => (prevValue || true))
        //setDownloadedReports([])  
        getDownloadReports(downloadCustomerId, true)
      } else {
        setSearchWaiting(true)
      }
  }, [searchString, fetchLabReports, fetchBalanceReports, fetchOtherReports]);
  
  
  useEffect(() => {
    if(!loading && searchWaiting)
      {
        setSearchWaiting(false)
        setHasDoneInitialRun((prevValue) => (prevValue && false))
        setHasMore((prevValue) => (prevValue || true))
        //setDownloadedReports([])  
        getDownloadReports(downloadCustomerId, true)
      }
  }, [loading]);


  useEffect(() => {
    const scrollableDiv = document.getElementById('scrollableDiv');
    // Ensure the scrollableDiv exists and that content height is less than container height
    if (scrollableDiv && scrollableDiv.scrollHeight <= scrollableDiv.clientHeight && hasMore && hasDoneInitialRun && !searchWaiting) {
      handleFetchMoreReports(); // Manually trigger the fetch
    }
  }, [downloadedReports, hasMore, hasDoneInitialRun, loading]); // Run this effect when reportKeys or hasMore changes

  const handleFetchMoreReports = async () => {
    if (loading || !show || !hasDoneInitialRun) return;

    await getDownloadReports(downloadCustomerId)

  };

  const getDownloadReports = async (searchCustomerId: string, fromSearch:boolean=false) => {
    // Prepare the request object
    setLoading(true);
   
    const request = {
      customerId: [searchCustomerId],
      currentMonth: fromSearch ? initialPaginationValues.currentMonth : paginationInformation.currentMonth,
      monthReach: fromSearch ? initialPaginationValues.monthReach : paginationInformation.monthReach,
      searchString: searchString,
      fromSearch:fromSearch,
      fetchLabReports: fetchLabReports,
      fetchBalanceReports: fetchBalanceReports,
      fetchOtherReports: fetchOtherReports,
    };
    try {
      // Await the response from getAllReportDownloads
      const response = await getAllReportDownloads(request);
  
      if((!hasDoneInitialRun || fromSearch) && response.data.sortedReports.length == 0)
        {
          setHasMore(false)
          setHasDoneInitialRun(true)
        }


      //if(response.data.sortedReports.length == 0){setHasMore(false)}

      setPaginationInformation(response.data.pagination);

      if(fromSearch)
        {
          setDownloadedReports([])
          setDownloadedReports((prevReports) => (response.data.sortedReports))
          //setDownloadedReports(response.data.sortedReports)

        } else{
          setDownloadedReports((prevReports) => ({
            ...prevReports,   // Spread the previous reports
            ...response.data.sortedReports // Append the new reports
          }));
        }
  
      // Print the Data
  
    } catch (error) {
      console.error("Failed to download reports", error);
      toast.error("Failed to download reports");
    }
    console.log("------------------finishing download")
  };

  useEffect(() => {
    if (Object.keys(downloadedReports).length > 0) {
      setHasDoneInitialRun(true); // Perform the action you need after setting
    }
    setLoading(false);
  }, [downloadedReports]);


  useEffect(()=>{
    if(paginationInformation.stopSearching == true)
      {
        setHasMore((prevValue) => (prevValue && false))
      }    
  },[paginationInformation])

  useEffect(()=>{
    if(!show)
      {
        resetValuesToInitial()
      }
      else
      (
        getDownloadReports(downloadCustomerId)
      )
  }, [show])

  /*
  useEffect(()=>{
    resetValuesToInitial()

  },[fetchLabReports,fetchBalanceReports, fetchOtherReports])
*/


  const resetValuesToInitial = () =>
  {
    setHasDoneInitialRun(false)
    //setReportKeys([])
    setSearchString("")
    setDownloadedReports([])
    setHasMore(true)
    setPaginationInformation(initialPaginationValues)
    setSelectedFiles([])
    setSelectedBalanceFiles([])
    setSelectedOtherFiles([])
    setFetchLabReports(true)
    setFetchBalanceReports(true)
    setFetchOtherReports(false)
  }

  const handleFileCheck = (event: any, fileName: any, typeFlag: string, checkedOverride: boolean = false) => {
    const isChecked = event.target.checked;
  
    if (typeFlag == "lab") {
      setSelectedFiles((prevSelectedFiles) =>
        isChecked
          ? [...prevSelectedFiles, fileName]
          : prevSelectedFiles.filter((file) => file !== fileName)
      );
    } else if (typeFlag == "balance") {
      setSelectedBalanceFiles((prevSelectedFiles) =>
        isChecked
          ? [...prevSelectedFiles, fileName]
          : prevSelectedFiles.filter((file) => file !== fileName)
      );
    } else if (typeFlag == "other") {
      setSelectedOtherFiles((prevSelectedFiles) =>
        isChecked
          ? [...prevSelectedFiles, fileName]
          : prevSelectedFiles.filter((file) => file !== fileName)
      );
    }
  };

  const handleCheckAll = (event: any, labFiles: string[] | null=null, balanceFiles: AccreditReports[] | null=null, otherFiles: IEquipmentReportsModel[] | null=null) => {
    const isChecked = event.target.checked;
    /*setSelectedFiles((prevSelectedFiles) =>
      isChecked ? [...prevSelectedFiles, ...files.filter(file => !prevSelectedFiles.includes(file))] : prevSelectedFiles.filter(file => !files.includes(file))
    );*/
      // Loop through labFiles and call handleFileCheck for each file
    if(labFiles){
      for (const file of labFiles) {
        handleFileCheck(event, file, "lab");
      }
    }
    if(balanceFiles){
      // Loop through balanceFiles and call handleFileCheck for each file
      for (const file of balanceFiles) {
        handleFileCheck(event, file, "balance");
     }
    }
    if(otherFiles){
    // Loop through otherFiles and call handleFileCheck for each file
      for (const file of otherFiles) {
        handleFileCheck(event, file, "other");
     }
    }
  };

  
  const handleMonthCheck = (event: any, labFiles: any[], balanceFiles: AccreditReports[], otherFiles: IEquipmentReportsModel[], monthDate: any) => {
    const isChecked = event.target.checked;
    
    setCheckedMonths((prev) => ({ ...prev, [monthDate]: isChecked }));

    setCheckAllLabReports((prev) => ({ ...prev, [monthDate]: isChecked }));
    setCheckAllBalanceReports((prev) => ({ ...prev, [monthDate]: isChecked }));
    setCheckAllOtherReports((prev) => ({ ...prev, [monthDate]: isChecked }));

    const formattedMonth = moment(monthDate).format('MM'); // Extract month as "06"
    const formattedYear = moment(monthDate).format('YYYY'); // Extract year as "2024"

    const filteredLabFiles = labFiles.filter(file => moment(file.dateofcal).format('YYYY-MM') === monthDate).map(file => file || '');
    const filteredBalanceFiles = balanceFiles.filter(file =>file.CalibrationMonth === formattedMonth && file.CalibrationYear === formattedYear).map(file => file || '')
    const filteredOtherFiles = otherFiles.filter(file =>moment(file.dateofcal).format('YYYY-MM') === monthDate).map(file => file || '')  

    let attachments : string[] = [];

    filteredLabFiles.map((file: CalibrationReportModel) => {
      // Check if attachments exist and clean up the string
      if (file.attachment) {
        file.attachment = file.attachment.replace(/^\["|"\]$/g, ''); // Remove [" at the start and "] at the end
        attachments = file.attachment.split('","'); // Split the cleaned string into an array
      } else {
        attachments = [];
      }
    });

    handleCheckAll(event, attachments, filteredBalanceFiles, filteredOtherFiles);
  }

  useEffect(() => {
    //console.log("Selected Lab Files    :", selectedFiles)
    //console.log("Selected Balance Files:", selectedBalanceFiles)
    //console.log("Selected Other Files  :", selectedOtherFiles)
  }, [selectedFiles, selectedBalanceFiles, selectedOtherFiles])  

  const handleDownLoad = useCallback((event: any, name: string) => {
    event.stopPropagation();

    downloadAttachment(name)
      .then((res) => {
        const url = window.URL.createObjectURL(res.data);
        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.onload = () => {
          window.URL.revokeObjectURL(url);
        };
        a.click();
      })
      .catch(() => {
        toast.error('This file is not found.');
      });


  }, []);

const downloadPDFs = async (data: any, downloadFile: AccreditReports) => {
      try {
        // setLoad(true) // Show loading state
        if (data) {
          const pdfBlob = await ReactPDF.pdf(<MyDocument data={data} />).toBlob();
          const pdfUrl = URL.createObjectURL(pdfBlob);
    
          // Create a temporary anchor element to trigger the download
          const link = document.createElement('a');
          link.href = pdfUrl;
          link.download = ["BalanceAndScale",
              downloadFile.ReportNo, 
              downloadFile.SerialNumber, 
              downloadFile.CalibrationMonth,
              downloadFile.CalibrationDate,
              downloadFile.CalibrationYear]
            .filter(Boolean)
            .join("-");
          
          
          document.body.appendChild(link);
          link.click(); // Programmatically click the link to trigger download
    
          // Clean up the anchor element after triggering download
          document.body.removeChild(link);
    
          //setLoad(false); // Hide loading state
        }
      } catch (error) {
        console.error('Error generating PDF:', error);
        //setLoad(false); // Hide loading state
      }
    };

  const downloadOtherReportPDFs = async (downloadFile: IEquipmentReportsModel) =>
  {
        let pdfBlob
        let result
        
        switch (downloadFile.caltype) {
          case 'Microscope':
              // Logic for microscope
              result = (await getMicroscopeReports(downloadFile.dbkey)).data[0];
              pdfBlob = await ReactPDF.pdf(<MyMicroscopeDocument data={result} commentsOn={isNBS ||false} />).toBlob();
              break;
          case 'Calibration':
              // Logic for calibration
              toast.error("Sorry, this type of file can not be downloaded from the Download Center just yet.\n\nFile Type: " + downloadFile.caltype + ". File sereal: " + downloadFile.serial);
              return;
              break;
          case 'Thermometer':
              // Logic for thermometer
              /*
              try {
                result = (await getThermalReports(downloadFile.dbkey)).data[0];
                console.log("Thermal Report", result);
                pdfBlob = await ReactPDF.pdf(<MyThermalDocument data={result} commentsOn={isNBS || false} />).toBlob();
                console.log("HERE!!!!!");
              } catch (error) {
                console.error("Error occurred:", error);  // This will print the error
                return;
              }
                */
              toast.error("Sorry, this type of file can not be downloaded from the Download Center just yet.\n\nFile Type: " + downloadFile.caltype + ". File sereal: " + downloadFile.serial);
              return;
              break;
          default:
              // Handle unknown types
              toast.error("Sorry, this type of file can not be downloaded from the Download Center just yet.\n\n");
              return; //toast.error("Unknown File Type: " + downloadFile.caltype + ". File sereal: " + downloadFile.serial);
        }

        //const pdfBlob = await ReactPDF.pdf(<MyMicroscopeDocument data={result} commentsOn={isNBS ||false} />).toBlob();
          const pdfUrl = URL.createObjectURL(pdfBlob);
    
          // Create a temporary anchor element to trigger the download
          const link = document.createElement('a');
          link.href = pdfUrl;
          link.download = downloadFile.caltype + "-" + downloadFile.serial + "-" + moment(downloadFile.dateofcal).format('MM-DD-YY') + ".pdf"; // The name of the downloaded file //TODO: Make this equal the name of the file.
          document.body.appendChild(link);
          link.click(); // Programmatically click the link to trigger download
    
          // Clean up the anchor element after triggering download
          document.body.removeChild(link);
          setUploadProgress((prev) => prev + 1);
  };

  const handleDownLoadAll = useCallback(async () => {
    setTotalCount(selectedFiles.length + selectedBalanceFiles.length + selectedOtherFiles.length);
    setUploading(true);

    for (const fileName of selectedFiles) {
      await new Promise<void>((resolve) => {
        handleDownLoad({ stopPropagation: () => {} }, fileName);
        setUploadProgress((prev) => prev + 1);
        setTimeout(resolve, 500);
      }).catch((error) => {
        toast.error(`Error downloading file ${fileName}: ${error}`);
      });
    }

    for (const downloadFile of selectedBalanceFiles) { //Temporary!!!
      axios
      .get(`${GET_REPORTS_API_URL}/getPDFdata`, {
        params: {
          IdShipping: downloadFile.IdShipping,
          IdEquipment: downloadFile.IdEquipment,
          ReportNo: downloadFile.ReportNo,
        },
      })
      .then((res) => {
        // Your logic after fetching data
        downloadPDFs(res.data[0], downloadFile)
          .then((res) => {
            // Handle success
          })
          .catch((err) => console.log(err));
        //setLoad(false); // Hide loading state
      })
      .catch((error) => {
        // Handle error
        //setLoad(false);
        console.log('err==', error);
      });
      setUploadProgress((prev) => prev + 1);
    }

    
    for (const downloadFile of selectedOtherFiles)
      {
        downloadOtherReportPDFs(downloadFile)        
      }
    
    setUploading(false);
    setUploadProgress(0)
  }, [selectedFiles, selectedBalanceFiles, selectedOtherFiles, handleDownLoad]);

  return (
    <>
      <Modal
        size="lg"
        show={show}
        dialogClassName="w-75"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header>
          <Modal.Title className="w-100" id="contained-modal-title-vcenter">
            <div className='d-flex align-items-center position-relative my-1 ms-auto ' /*style={{overflow:'hidden'}}*/>
              
                <h2>Download Center - Beta</h2>
                <div className='d-flex align-items-center position-relative my-1 ms-auto'>
                  <KTSVG
                    path='/media/icons/duotone/General/Search.svg'
                    className='svg-icon-1 position-absolute ms-6'
                  />
                  <input
                    type='text'
                    data-kt-customer-table-filter='search'
                    className='form-control form-control-solid w-20hw ps-15 border-0'
                    placeholder='Search'
                    onChange={(e) => debouncedHandleSearch(e.target.value)} 
                  />
                </div>
                <span
                  className="text-hover-primary cursor-pointer"
                  onClick={onHide}
                  >
                  <KTSVG path="/media/icons/duotone/Navigation/Close.svg" className="svg-icon-1" />
                </span>
                </div>
                <div className="d-flex justify-left align-items-center">
                  <div className="d-flex align-items-center me-6">
                    <Checkbox
                      checked={fetchOtherReports || false}
                      onChange={(e) => setFetchOtherReports(e.target.checked || false)}
                    />
                    <span className="ms-1">
                      Show Other Reports
                      <QuestionTooltip hoverText="This will slow down the report fetching process. We are working on optimizing this report type. Thank you for your patience." />
                    </span>
                  </div>

                  <div className="d-flex align-items-center me-6">
                    <Checkbox
                      checked={fetchLabReports || false}
                      onChange={(e) => setFetchLabReports(e.target.checked || false)}
                    />
                    <span className="ms-1">Show Lab Reports</span>
                  </div>

                  <div className="d-flex align-items-center">
                    <Checkbox
                      checked={fetchBalanceReports || false}
                      onChange={(e) => setFetchBalanceReports(e.target.checked || false)}
                    />
                    <span className="ms-1">Show Balance Reports</span>
                  </div>
                </div>    
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="pt-6 pb-15 px-lg-17" id="scrollableDiv" style={{ height: '75vh', overflowY: 'scroll' }}>
          <InfiniteScroll
            dataLength={Object.keys(downloadedReports).length || 0} // Length of the currently displayed reports
            next={handleFetchMoreReports} // Function to fetch the next set of reports
            hasMore={hasMore} // Boolean to indicate if more reports are available
            loader={
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <h4>Loading...</h4>
              </div>
            }
            //endMessage={<p style={{ textAlign: 'center' }}>No more reports to load</p>} // Message when no more reports are available
            scrollableTarget="scrollableDiv" // Targeting the modal's scrollable content
          >
            <Accordion defaultActiveKey="0">
              {Object.keys(downloadedReports).length > 0 ? (
                Object.keys(downloadedReports).map((key: string, index) => {
                  const reportGroup = downloadedReports[key];
                  const labFiles: CalibrationReportModel[] = reportGroup.LabData || [];
                  const balanceFiles: AccreditReports[] = reportGroup.BandSData || [];
                  const otherFiles: IEquipmentReportsModel[] = reportGroup.OtherData || [];
  
                  return (
                    <Card key={key}>
                      <Card.Header>
                        <Grid container alignItems="center">
                          <Grid item>
                            <Checkbox
                            checked = {checkedMonths[key] || false}
                              onChange={(e) => handleMonthCheck(e, labFiles, balanceFiles, otherFiles, moment(key).format('YYYY-MM'))}
                            />
                          </Grid>
                          <Grid item>
                            <Accordion.Toggle
                              as={Button}
                              variant="link"
                              eventKey={`${key}`}
                            >
                              {moment(key).format('MMM - YYYY')} Reports
                            </Accordion.Toggle>
                          </Grid>
                        </Grid>
                      </Card.Header>
                      <Accordion.Collapse eventKey={`${key}`}>
                        <Card.Body>
                          {/* Lab Reports */}
                          {labFiles.length > 0 && (
                            <Accordion>
                              <Card>
                                <Card.Header>
                                  <Grid container alignItems="center">
                                    <Grid item>
                                      <Checkbox
                                        checked={checkAllLabReports[key] || false}
                                        onChange={(e) =>{
                                          setCheckAllLabReports((prev) => ({ ...prev, [key]: e.target.checked }));
                                          if(!e.target.checked) setCheckedMonths((prev) => ({ ...prev, [key]: false }));
                                          handleCheckAll(
                                            e,
                                            labFiles
                                              .filter((file: CalibrationReportModel) =>
                                                moment(file.dateofcal).format('MMM - YYYY') === moment(key).format('MMM - YYYY') // Filter files by the clicked month
                                              )
                                              .flatMap((file: CalibrationReportModel) => {
                                                // Check if attachments exist and split them into an array
                                                const attachments: string[] = file.attachment
                                                  ?.replace(/^\["|"\]$/g, '')
                                                  .split('","')
                                                  .map((attachmentStr) => attachmentStr.trim()) || [];
                                                return attachments;
                                              })
                                          )}
                                        }
                                      />
                                    </Grid>
                                    <Grid item>
                                      <Accordion.Toggle
                                        as={Button}
                                        variant="link"
                                        eventKey={`labReports-${key}`}
                                      >
                                        Lab Reports
                                      </Accordion.Toggle>
                                    </Grid>
                                  </Grid>
                                </Card.Header>
                                <Accordion.Collapse eventKey={`labReports-${key}`}>
                                  <Card.Body>
                                    {labFiles.map((file: CalibrationReportModel) => {
                                      file.attachment = file.attachment?.replace(/^\["|"\]$/g, ''); // Clean attachment format
                                      const attachments: ICalibrationReportAttachment[] = file.attachment
                                        ?.split('","')
                                        .map((attachmentStr) => {
                                          return {
                                            id: file.dbkey,
                                            attachment: attachmentStr.trim(),
                                            calibration_date: file.dateofcal,
                                            calibration_type: file.caltype,
                                            customer_id: file.customerid,
                                            dbkey_tech: file.dbkey_tech ?? 0,
                                            other_cal_report_id: file.dbkey_id ?? 0,
                                            shipping_company_name: '',
                                            technician_fullname: file.technician_fullname,
                                          };
                                        }) || [];
  
                                      return (
                                        <div key={file.dbkey_id}>
                                          {attachments.map((attachment: ICalibrationReportAttachment) => {
                                            const fileKey = `${attachment.id}-${attachment.attachment}`;
                                            const labCalType = attachment.calibration_type || 'Unknown';
  
                                            return (
                                              <Grid container alignItems="center" key={attachment.attachment}>
                                                <Grid item>
                                                  <Checkbox
                                                    checked={selectedFiles.includes(attachment.attachment)}
                                                    onChange={(e) => handleFileCheck(e, attachment.attachment, 'lab')}
                                                  />
                                                </Grid>
                                                <Grid item>
                                                  <Typography>
                                                    {attachment.attachment} - {moment(attachment.calibration_date).format('MM/DD/YY')} - {labCalType}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            );
                                          })}
                                        </div>
                                      );
                                    })}
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            </Accordion>
                          )}
  
                          {/* Balance and Scale Reports */}
                          {balanceFiles.length > 0 && (
                            <Accordion>
                              <Card>
                                <Card.Header>
                                  <Grid container alignItems="center">
                                    <Grid item>
                                      <Checkbox
                                        checked={checkAllBalanceReports[key] || false}
                                        onChange={(e) =>{
                                          setCheckAllBalanceReports((prev) => ({ ...prev, [key]: e.target.checked }));
                                          if(!e.target.checked) setCheckedMonths((prev) => ({ ...prev, [key]: false }));
                                          handleCheckAll(
                                            e,
                                            [],
                                            balanceFiles.filter(file =>
                                              file.CalibrationMonth === moment(key).format('MM') &&
                                              file.CalibrationYear === moment(key).format('YYYY')
                                            ).map(file => file || ''),
                                            []
                                          )}
                                        }
                                      />
                                    </Grid>
                                    <Grid item>
                                      <Accordion.Toggle
                                        as={Button}
                                        variant="link"
                                        eventKey={`balanceReports-${key}`}
                                      >
                                        Balance and Scale Reports
                                      </Accordion.Toggle>
                                    </Grid>
                                  </Grid>
                                </Card.Header>
                                <Accordion.Collapse eventKey={`balanceReports-${key}`}>
                                  <Card.Body>
                                    {balanceFiles.map((file: AccreditReports) => {
                                      const fileKey = file.ReportNo !== undefined && file.ReportNo !== null ? file.ReportNo.toString() : '';
                                      return (
                                        <Grid container alignItems="center" key={fileKey}>
                                          <Grid item>
                                            <Checkbox
                                              checked={selectedBalanceFiles.includes(file)}
                                              onChange={(e) =>
                                                handleFileCheck(e, file, "balance")
                                              }
                                            />
                                          </Grid>
                                          <Grid item>
                                            <Typography>
                                              {fileKey} - {moment(file.CalibrationDate).format('MM/DD/YY') || "Can't create date"}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      );
                                    })}
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            </Accordion>
                          )}
  
                          {/* Other Reports */}
                          {otherFiles.length > 0 && (
                            <Accordion>
                              <Card>
                                <Card.Header>
                                  <Grid container alignItems="center">
                                    <Grid item>
                                      <Checkbox
                                        checked={checkAllOtherReports[key] || false}
                                        onChange={(e) => {
                                          setCheckAllOtherReports((prev) => ({ ...prev, [key]: e.target.checked }));
                                          if(!e.target.checked) setCheckedMonths((prev) => ({ ...prev, [key]: false }));
                                          handleCheckAll(
                                            e,
                                            [],
                                            [],
                                            otherFiles.filter(file =>
                                              moment(file.dateofcal).format('YYYY-MM') === moment(key).format('YYYY-MM')
                                            ).map(file => file || '')
                                          )}
                                        }
                                      />
                                    </Grid>
                                    <Grid item>
                                      <Accordion.Toggle
                                        as={Button}
                                        variant="link"
                                        eventKey={`otherReports-${key}`}
                                      >
                                        Other Reports
                                      </Accordion.Toggle>
                                    </Grid>
                                  </Grid>
                                </Card.Header>
                                <Accordion.Collapse  eventKey={`otherReports-${key}`}>
                                  <Card.Body>
                                    {otherFiles.map((file: IEquipmentReportsModel) => {
                                      const fileKey = file.serial !== undefined && file.serial !== null ? file.serial.toString() : 'No Serial';
                                      return (
                                        <Grid container alignItems="center" key={file.dbkey}> 
                                          <Grid item>
                                            <Checkbox
                                              checked={selectedOtherFiles.includes(file)}
                                              onChange={(e) =>
                                                handleFileCheck(e, file, "other")
                                              }
                                            />
                                          </Grid>
                                          <Grid item>
                                            <Typography>
                                              {file.caltype} - {file.serial} - {moment(file.dateofcal).format('MM/DD/YY')}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      );
                                    })}
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            </Accordion>
                          )}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  );
                })
              ) : (
                hasDoneInitialRun && (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <p>No reports available.</p>
                  </div>
                )
              )}
            </Accordion>
          </InfiniteScroll>
        </Modal.Body>
        <Modal.Footer>
          <Box className="mt-6 float-end">
            {(selectedFiles.length + selectedBalanceFiles.length + selectedOtherFiles.length) > 0 && (
              <Button
                variant="contained"
                onClick={handleDownLoadAll}
                color="primary"
              >
                <KTSVG
                  path="/media/icons/duotone/Files/Cloud-download.svg"
                  className="svg-icon-2"
                />
                Download Selected
              </Button>
            )}
            <Button variant="contained" onClick={onHide} color="secondary">
              Close
            </Button>
          </Box>
        </Modal.Footer>
      </Modal>
      <Dialog
        open={uploading}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
      >
        <CircularProgressWithLabel value={uploadProgress} count={totalCount} />
      </Dialog>
    </>
  );
  
};

export { AllReportsAttachmentModal };