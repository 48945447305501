/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react'
import toast from 'react-hot-toast'
import moment from 'moment'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { KTSVG } from '../../../_metronic/helpers'
import { Dropdown, Notification } from './components'
import { markAsReadNotification } from '../../services/notification'
import { RootState } from '../../../setup'
import { UserModel, NotificationModel } from '../../../types'
import { getErrorMessage} from '../../../helper/response.helper'
import * as notificationsRedux from './NotificationsRedux'

type Props = {
  className: string
}

const NotificationList: React.FC<Props> = ({className}) => {
  const [notifications, setNotifications] = useState<NotificationModel[]>([])
  const [filteredNotifications, setFilteredNotifications] = useState<NotificationModel[]>([])
  const [unReadCount, setUnreadCount] = useState<number>(0)
  const dispatch = useDispatch()

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const notificationsSelector = useSelector<RootState>((state) => state.notifications.notifications) as any
  

  const handleMarkRead = (notification: NotificationModel) => {
    markAsReadNotification(notification)
      .then((res) => {
        getNotifications()
      })
      .catch((error) => {
        const errMsg = getErrorMessage(error)
        toast.error(errMsg)
      })
  }

  const getNotifications = () => {
    dispatch(notificationsRedux.actions.getUserNotifications(user.id))
  }

  const handleFilter = (status: string, startDate: any, endDate: any) => {
    let filterResult: NotificationModel[] = []

    if (notifications.length !== 0) {
      notifications.forEach((notification: NotificationModel) => {
        if (status === '1') {
          filterResult.push(notification)
        } else if (status === '2') {
          if (notification.read_at) filterResult.push(notification)
        } else if (status === '3') {
          if (!notification.read_at) filterResult.push(notification)
        }
      })
    }

    if (startDate !== '' && startDate !== null) {
      filterResult = filterResult.filter((notification) => {
        return moment(startDate) < moment(notification.created_at)
      })
    }

    if (endDate !== '' && endDate !== null) {
      filterResult = filterResult.filter((notification) => {
        return moment(endDate) > moment(notification.created_at)
      })
    }

    setFilteredNotifications(filterResult)
  }

  useEffect(() => {
    if (notificationsSelector.length !== 0) {
      setNotifications(notificationsSelector.notifications as NotificationModel[])
      setFilteredNotifications(notificationsSelector.notifications as NotificationModel[])
    }
  }, [notificationsSelector])

  useEffect(() => {
    getNotifications()
  }, [user])

  useEffect(() => {
    if (notifications.length !== 0) {
      const count = notifications.filter((n: NotificationModel) => !n.read_at).length

      setUnreadCount(count)
    }
  }, [notifications])

  return (
    <div className='card card-xl-stretch mb-5 mb-xl-8'>
      <div className='card-header border-0'>
        <h3 className='card-title fw-bolder text-dark'>Notifications {unReadCount} unread</h3>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG
              path='/media/icons/duotone/Layout/Layout-4-blocks-2.svg'
              className='svg-icon-2'
            />
          </button>
          <Dropdown onApply={handleFilter} />
        </div>
      </div>
      <div className='card-body pt-0 overflow-auto' style={{ maxHeight: '422px' }}>
        {filteredNotifications.map((notification, index) => {
          return (
            <Notification
              key={index}
              index={index}
              notification={notification}
              markRead={handleMarkRead}
            />
          )
        })}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {NotificationList}
