import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { QuoteModel } from '../../../types'
import { getServiceOrders } from './serviceOrdersCrud'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  ServiceOrdersRequested: '[GetServiceOrders] Action',
  ServiceOrdersLoaded: '[Load Service Orders] Action',
  SetQuoteNumberSlug: '[Set Quote Number Slug on Service Orders] Action',
}

const initialQuotesState: IServiceOrdersState = {
  serviceOrders: [],
  loading: false,
  quoteNumberSlug: null,
  totals: 0,
}

export interface IServiceOrdersState {
  serviceOrders: any
  loading: boolean
  quoteNumberSlug: any
  totals: number
}

export const reducer = (
  state: IServiceOrdersState = initialQuotesState,
  action: ActionWithPayload<IServiceOrdersState>
) => {
  switch (action.type) {
    case actionTypes.ServiceOrdersRequested: {
      return {
        ...state,
        loading: true,
      }
    }

    case actionTypes.ServiceOrdersLoaded: {
      return {
        ...state,
        loading: false,
        ...action.payload,
      }
    }

    case actionTypes.SetQuoteNumberSlug: {
      const quoteNumberSlug = action.payload?.quoteNumberSlug
      return {
        ...state,
        quoteNumberSlug,
      }
    }

    default:
      return state
  }
}

export const actions = {
  getServiceOrders: (searchFilter = {}) => ({
    type: actionTypes.ServiceOrdersRequested,
    payload: { searchFilter },
  }),

  serviceOrdersLoaded: (serviceOrders: QuoteModel[], totals: number) => ({
    type: actionTypes.ServiceOrdersLoaded,
    payload: { serviceOrders, totals },
  }),

  setQuoteNumberSlug: (quoteNumberSlug: any) => ({
    type: actionTypes.SetQuoteNumberSlug,
    payload: { quoteNumberSlug },
  }),
}

export function* saga() {
  yield takeLatest(
    actionTypes.ServiceOrdersRequested,
    function* serviceOrdersRequested(action: any) {
      const { searchFilter } = action.payload
      const {
        data: { serviceOrders, totals },
      } = yield getServiceOrders(searchFilter)

      yield put(actions.serviceOrdersLoaded(serviceOrders, totals))
    }
  )
}
