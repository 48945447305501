import React, { Suspense, createContext, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import { useLocalStorage, useReadLocalStorage } from 'usehooks-ts'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import { Routes } from './routing/Routes'
import { ThemeProvider } from '@material-ui/core'
import { theme } from '../setup/theme'
//import 'react-quill/dist/quill.snow.css'

type Props = {
  basename: string
}

export const ThemeContext = createContext({
  changeTheme: (theme: string) => {},
})

const App: React.FC<Props> = ({ basename }) => {
  const themes = useReadLocalStorage('themes')
  const [, setThemesMode] = useLocalStorage('themes', themes)

  const changeTheme = (theme: string) => {
    setThemesMode(theme)
    window.location.reload()
  }

  useEffect(() => {
    if (themes) {
      if (themes === 'light') {
        import('../_metronic/assets/sass/style.scss')
      } else {
        import(`../_metronic/assets/sass/style.dark.scss`)
      }
    } else {
      import('../_metronic/assets/sass/style.scss')
    }
  }, [themes])

  return (
    <>
      {/* <link rel='stylesheet' type='text/css' href={stylePath} /> */}
      <Suspense fallback={<LayoutSplashScreen />}>
        <BrowserRouter basename={basename}>
          <I18nProvider>
            <LayoutProvider>
              <AuthInit>
                <ThemeProvider theme={theme}>
                  <ThemeContext.Provider value={{ changeTheme: changeTheme }}>
                    <Routes />
                  </ThemeContext.Provider>
                </ThemeProvider>
              </AuthInit>
            </LayoutProvider>
          </I18nProvider>
        </BrowserRouter>
        <Toaster position='top-right' />
      </Suspense>
    </>
  )
}

export { App }
