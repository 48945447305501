import { Action } from '@reduxjs/toolkit'
import { put, takeLatest } from 'redux-saga/effects'
import { AccreditReports } from '../../../types/accredit-reports/AccreditReportsTypes'
import { getAccreditReports } from './AccreditReportsCrud'

export interface ActionWithPayload<T> extends Action {
    payload?: T
}
export const actionTypes = {
    GetAccreditReports: "[GetAccreditReports] Action",
    GetAccreditReportsSuccess: '[GetAccreditReportsSuccess] Action',
}
export interface IAccreditReportsState {
  data: any
  loading: boolean
  total: number
}

const initialState: IAccreditReportsState = {
  data: [],
  loading: false,
  total: 0,
}
export const reducer = (state = initialState, action: ActionWithPayload<IAccreditReportsState>) => {
  switch (action.type) {
    case actionTypes.GetAccreditReports: {
      return { ...state, loading: true }
    }
    case actionTypes.GetAccreditReportsSuccess: {
      // const temp_accreditReports = action.payload?.accreditReports
      // const accreditReports = temp_accreditReports.map((item: any) => {
      //   Object.keys(item).map((obj) => {
      //     if (item[obj] == null) {
      //       item[obj] = ''
      //     }
      //     if (item[obj] == '0') {
      //       item[obj] = ''
      //     }
      //   })
      //   return item
      // })
      // const count = action.payload?.count
      return { ...state, ...action.payload, loading: false }
    }
    default:
      return state
  }
}

export const actions = {
  getAccreditReports: (searchFilter = {}) => ({
    type: actionTypes.GetAccreditReports,
    payload: { searchFilter },
  }),
  getAccreditReportsSuccess: (data: any) => ({
    type: actionTypes.GetAccreditReportsSuccess,
    payload: data,
  }),
}

export function* saga() {
  yield takeLatest(
    actionTypes.GetAccreditReports,
    function* getAccreditReportsRequested(action: any) {
      const { searchFilter } = action.payload
      const { data } = yield getAccreditReports(searchFilter)
      yield put(actions.getAccreditReportsSuccess(data))
    }
  )
}
