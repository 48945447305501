import { toServerUrl } from '../_metronic/helpers'

export const isImageFile = (fileName: string): boolean => {
  if (!fileName) return false

  const acceptedImageTypes = [
    'gif',
    'jpeg',
    'png',
    'tif',
    'svg',
    'pjp',
    'xbm',
    'jxl',
    'svgz',
    'jpg',
    'ico',
    'tiff',
    'jfif',
    'webp',
  ]
  const piece = fileName.split('.')
  const typeOfFile = piece[piece.length - 1]

  return acceptedImageTypes.includes(typeOfFile)
}

export const checkIfImageExists = (url: string) => {
  const img = new Image()
  img.src = url

  if (img.complete) {
    return url
  } else {
    return toServerUrl('/media/avatar/blank.png')
  }
}

export const getAvatar = (avatar: string) => {
  if (!avatar) return 'blank.png'

  return avatar
}

export const checkIfImgExists = async (url: string) => {
  const img = new Image()
  img.src = url

  const loadImage = (url: string) => {
    return new Promise((resolve, reject) => {
      let img = new Image()
      img.onload = (response) => resolve(response)
      img.onerror = reject
      img.src = url
    })
  }

  try {
    await loadImage(url)
    return url
  } catch (e) {
    return toServerUrl('/media/avatar/blank.png')
  }
}
