import { Action } from '@reduxjs/toolkit'
import { put, select, takeLatest } from 'redux-saga/effects'
import { CustomerModel } from '../../../types'
import { getNBSCustomers } from './CustomersCRUD'
import { RootState } from '../../../setup'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  NBSCustomersRequested: '[Request NBS Customers] Action',
  NBSCustomersLoaded: '[Load NBS Customers] Action',
}

const initialNBSCustomerState: INBSCustomersState = {
  loading: false,
  nbsCustomers: [],
}

export interface INBSCustomersState {
  loading: boolean
  nbsCustomers: any
}

export const reducer = (
  state: INBSCustomersState = initialNBSCustomerState,
  action: ActionWithPayload<INBSCustomersState>
) => {
  switch (action.type) {
    case actionTypes.NBSCustomersRequested: {
      return { ...state, loading: true }
    }

    case actionTypes.NBSCustomersLoaded: {
      const nbsCustomers = action.payload?.nbsCustomers

      return { loading: false, nbsCustomers }
    }

    default:
      return state
  }
}

export const actions = {
  getNBSCustomers: (
    customerIds: string[] | undefined,
    page: number,
    perPage: number,
    slug: string,
    column: string,
    sort: string,
    status = ['active', 'inactive', 'invited'],
    companyIds: string[] = []
  ) => ({
    type: actionTypes.NBSCustomersRequested,
    payload: { customerIds, status, companyIds, page, perPage, slug, column, sort },
  }),
  nbsCustomersLoaded: (nbsCustomers: CustomerModel[]) => ({
    type: actionTypes.NBSCustomersLoaded,
    payload: { nbsCustomers },
  }),
}

export function* saga() {
  yield takeLatest(
    actionTypes.NBSCustomersRequested,
    function* nbsCustomersRequested({ payload }: any) {
      // const { data: user } = yield getUserByToken()
      const state: RootState = yield select((state) => state)
      const user = state.auth.user

      const { data: nbsCustomers } = yield getNBSCustomers({
        ...payload,
        isAdmin: Boolean(user?.customerIds?.length && user.customerIds.length),
      })

      yield put(actions.nbsCustomersLoaded(nbsCustomers))
    }
  )
}
