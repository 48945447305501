import React from 'react'
import { EditorContent, useEditor, BubbleMenu } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Underline from '@tiptap/extension-underline'
import Strike from '@tiptap/extension-strike'
import './TipTapStyling.css'

type TiptapEditorProps = {
  content?: string
  onUpdate: (content: string) => void
  isReadOnly?: boolean
}

const TiptapEditor: React.FC<TiptapEditorProps> = ({ content="", onUpdate, isReadOnly = false }) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Bold,
      Italic,
      Underline,
      Strike
    ],
    content,
    editable: !isReadOnly,
    onUpdate: ({ editor }) => {
      if (!isReadOnly) {
        onUpdate(editor.getHTML())
      }
    }
  })

  if (!editor) {
    return null
  }

  return (
    <div className="editor-container tiptap">
      {!isReadOnly && editor && (
        <BubbleMenu editor={editor} className="bubble-menu" tippyOptions={{ duration: 100 }}>
          <button
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={editor.isActive('bold') ? 'is-active' : ''}
          >
            Bold
          </button>
          <button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            className={editor.isActive('italic') ? 'is-active' : ''}
          >
            Italic
          </button>
          <button
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            className={editor.isActive('underline') ? 'is-active' : ''}
          >
            Underline
          </button>
          <button
            onClick={() => editor.chain().focus().toggleStrike().run()}
            className={editor.isActive('strike') ? 'is-active' : ''}
          >
            Strike
          </button>
        </BubbleMenu>
      )}
      <EditorContent editor={editor} className="editor-content" />
    </div>
  )
}

export default TiptapEditor
