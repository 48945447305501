import React, { useEffect, useState, useRef } from 'react';
import L from 'leaflet';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import { PageTitle } from '../../../_metronic/layout/core';
import { getValidCustomerShippingData, getHeatmapInfo } from './TerritoryMapCrud';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import 'leaflet-control-geocoder/dist/Control.Geocoder.js';
import { KTSVG } from '../../../_metronic/helpers'
import MapFilter from './components/MapFilter';
import { Card } from 'react-bootstrap-v5'
import { CustomerModel, UserModel } from '../../../types';
import { Customer } from '../../../types';
import CustomerDetails from '../nbs-admin/NewNBSAdmin';
import nameToAbbreviation from "us-state-converter" //used to convert our back end state data to 
import { scaleLinear } from 'd3-scale'
import toast from 'react-hot-toast';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../setup';
import { isCustomerTechnician} from '../../../helper/level.helper';
import { QuestionTooltip } from './components/QuestionToolTip';

let customerShippingData: Array<any | null>;

let markerArray: Array<L.Marker | null > = []; // Initialize as an empty array

interface CustomerDetails {
  CustomerID: string;
  CompanyName: string;
  ShipAddres: string;
  ShipCity: string;
  ShipState: string;
  ShipZip: string;
  ShipLatitude: string;
  ShipLongitude: string;
  ShipAddressUpdated: string;
}

interface SelectedCustomers {
  [key: string]: CustomerDetails | undefined;
}

interface StateData {
  state: string;
  totals: number;
}

interface HeatMapData {
  states: StateData[];
  average: number;
  totalRev:number;
  highestStateRev: number;
  isSet: boolean;
  updated: boolean;
}

function createRedPin(desiredWidth: number): L.Icon {
  // Descrition and review needed
  const originalWidth = 66;
  const originalHeight = 143;
  const scaleFactor = desiredWidth / originalWidth;
  const desiredHeight = Math.round(originalHeight * scaleFactor);

  const pin = L.icon({
    iconUrl: '/media/logos/pin.png',
    iconSize: [desiredWidth, desiredHeight],
    iconAnchor: [desiredWidth >> 1, desiredHeight], //bitshift of 1 
    popupAnchor: [-3, -76]
  });
  return pin;
}

const TerritoryMapPage: React.FC = () => {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const [map, setMap] = useState<L.Map | null>(null);
  const [slug, setSlug] = useState<string>('');
  
  const [geojsonData, setGeojsonData] = useState<any>(null); // State for GeoJSON data
  const geoJsonHandlersAddedRef = useRef(false); // Ref to track if handlers have been added
  const geojsonRef = useRef<L.GeoJSON | null>(null); 

  const [currentFilters, setCurrentFilters] = useState({});
  const filterRef = useRef() as React.MutableRefObject<HTMLButtonElement>;
  const [dropDownCustomers, setDropDownCustomers] = useState<Customer[]>([{ id: '', name: '' }])
  const [dropDownStartDate, setDropDownStartDate] = useState<any>('')
  const [dropDownEndDate, setDropDownEndDate] = useState<any>()
  const [selectedCustomers, setSelectedCustomers] = useState<SelectedCustomers>({});
  const [isDeveloper, setIsDeveloper] = useState<boolean>(false)
  const [isTechnician, setIsTechnician] = useState<boolean>(true)

  const [showPins, setShowPins] = useState<boolean>(false)
  const [coloringOptions] = useState<any>({
    coloringMode:0,
    coloringRange:0
  });


const [heatMapData, setHeatMapData] = useState<HeatMapData>({
  states: [],
  average: 0,
  totalRev: 0,
  highestStateRev: 0,
  isSet: false,
  updated: false,
});

  const heatMapDataRef = useRef(heatMapData);

  useEffect(() => {
    const userIsTech = isCustomerTechnician(user?.level)
    setIsTechnician(userIsTech)
    if(userIsTech)
      {
        setShowPins(true)
      }
    if(user.id == 2)
      {
        setIsDeveloper(true)
      }
  }, [user])

  useEffect(() => {
    // Fetch the GeoJSON data for US states
    fetch('https://raw.githubusercontent.com/PublicaMundi/MappingAPI/master/data/geojson/us-states.json')
      .then(response => response.json())
      .then(data => {
        setGeojsonData(data); // Set the GeoJSON data
      })
      .catch(error => console.error('Error fetching GeoJSON data:', error));
  }, []);

  
const addGeoJsonEventListeners = (geojsonLayer: L.GeoJSON) => {
  if( geoJsonHandlersAddedRef.current == true) return
  geojsonLayer.eachLayer((layer) => {
    layer.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight,
      click: clickState,
    });
  });
  geoJsonHandlersAddedRef.current = true; // Mark handlers as added
};

useEffect(() => {
  const fetchHeatmapInfo = async () => {
    try {
      const response = await getHeatmapInfo(currentFilters);
      if (response && response.data) {
        const data = response.data;
        let total:number = 0;
        let average: number = 0
        let highestStateRev = data[0].totals
          if(data.length != 0)
          {    
            data.forEach((currentState: { state: string; totals: number }) => {
              total += Number(currentState.totals);
            });
            average = total / data.length;
          }
          else
          {
            toast.error("There is no revenue data associated with this range!")
          }

          setHeatMapData({
            states: data,
            average: average,
            totalRev: total,
            highestStateRev: highestStateRev,
            isSet: true,
            updated: false
          });
        }
      } catch (error) {
        console.error('Error fetching financial data:', error);
      }
    };
    if(!isTechnician)
      {
        fetchHeatmapInfo();
      }
  }, [currentFilters, isTechnician]);

  useEffect(() => {
    if(!heatMapData.updated && heatMapData.isSet)
      {
        heatMapDataRef.current = heatMapData;
    
        setHeatMapData(prevData => ({
          ...prevData,
          updated: true
        }));
      }

  }, [heatMapData]);


  function getColor(stateName: string,from:any): string {
    const stateAbbreviationObject = nameToAbbreviation(stateName);
    const stateAbbreviation = stateAbbreviationObject?.usps; // Use optional chaining to handle undefined
    let coloring

    var stateData : any = heatMapDataRef.current.states.find((item: { state: string, totals: number; }) => {
      return item.state == stateAbbreviation;
    });

    const total = stateData ? stateData.totals : 0;

    if(total==0){return '#FFFFFF'}

    const colorScale = scaleLinear()
      .clamp(false); // Clamp to ensure colors stay within the defined range   

    switch(coloringOptions.coloringRange){
      case 0:
        colorScale.range(['#ffffd6', '#5045b4', '#d73027']); // Blue to light blue to yellow to light orange to orange to red color option 1
        break
      case 1:
        colorScale.range(['#ffffd6', '#5045b4', '#d73027']);
        break
    }
    
    switch(coloringOptions.coloringMode){
      case 0:
        colorScale.domain([0, 1, 2]); // Scale from 0 to 200% of the average
        coloring = colorScale(total / heatMapDataRef.current.average);
        break
      case 1:
        colorScale.domain([0, 0.5 ,1]); // Scale from 0 to 100% <- Used for % of the total coloring.
        coloring = colorScale(total / heatMapDataRef.current.highestStateRev);
        break
      default:
        colorScale.domain([0, 1, 2]); // Scale from 0 to 200% of the average
        break
    } 

    return coloring
  }


  function highlightFeature(e: any) {
    var layer = e.target;
    //console.log("Layer", layer)
    //layer.originalStyle = { ...layer.options };

    layer.setStyle({
      weight: 5,
      color: '#666',
      dashArray: '',
      fillOpacity: 0.7
    });
  }
    function calculateStyle(feature: any) {
      const stateName = feature?.properties?.name || '';
      //console.log(stateName)
      return {
        fillColor: getColor(stateName,"calc"), // Use the getColor function
        weight: 2,
        opacity: 1,
        color: 'white',
        dashArray: '3',
        fillOpacity: 0.7
      };
    }

    function resetHighlight(e: any) {
      const layer = e.target;
      layer.setStyle(calculateStyle(layer.feature));
    }

    function clickState(e: any) {
      const layer = e.target;
      const stateAbbreviation = nameToAbbreviation(layer.feature?.properties?.name || '').usps;
    
      const stateData = heatMapDataRef.current.states.find((item: { state: string; totals: number }) => {
        return item.state === stateAbbreviation;
      });
    
      // Ensure totals is a number before formatting
      const stateTotals = stateData ? Number(stateData.totals) : 0;
    
      // Format the heatMapDataRef.current.average.current and stateData.totals to two decimal places using string formatting
      const formattedAverageRevenue = heatMapDataRef.current.average.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      const formattedTotalRevenue = heatMapDataRef.current.totalRev.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    
      const formattedStateRevenue = stateData ? stateTotals.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) : 'No data available';

      const differenceFromAverage = stateData ? "%"+((stateTotals/heatMapDataRef.current.average * 100) - 100).toFixed(2) : 'No data available'; //gets the average
      const percentageOfTotal =  stateData ? "%"+((stateTotals/heatMapDataRef.current.totalRev * 100)).toFixed(2) : 'No data available'; //gets the average

      // Set the content for the popup with left-aligned text
      const popupContent = `
      <div style="text-align: left; max-width: 100vw;">
        <h4>${layer.feature.properties.name}</h4>
        <ul style="list-style-type: disc; padding-left: 20px;">
          <li>Total Revenue: ${formattedTotalRevenue}</li>  
          <li>Average Revenue: ${formattedAverageRevenue}</li> <hr />  
      
          <li>State Revenue: ${formattedStateRevenue}</li>
          <li>Difference From Average: ${differenceFromAverage}</li>
          <li>Percentage of Revenue: ${percentageOfTotal}</li>
        </ul>
        <p>*Please give us feedback on what stats you would like to see!*</p>
      </div>
    `;
    
      // Bind the popup to the layer and open it
      layer.bindPopup(popupContent, { maxWidth: 600 }).openPopup();
    }

  function bindPopupOnClick(marker: L.Marker, customerData: CustomerDetails) {
    const isChecked = !!selectedCustomers[customerData.CustomerID];  // Convert presence of customer data to a boolean
    //see if we can conditionally render it if the 
    let customerPopupInfo = `
      <div style="text-align: left; font-size: 14px; line-height: 1.5;">
        <b>${customerData.CompanyName}</b><br />
        Shipping ID: ${customerData.CustomerID}<br />
        Shipping Address: ${customerData.ShipAddres}<br /> 
        Ship City: ${customerData.ShipCity}<br />
        Ship State: ${customerData.ShipState}<br />
        Ship Zip: ${customerData.ShipZip}<br />
        Ship Latitude: ${customerData.ShipLatitude}<br />
        Ship Longitude: ${customerData.ShipLongitude}<br />
        Ship Address Updated: ${customerData.ShipAddressUpdated}<br /><br />
      </div>
    `;
    return customerPopupInfo;
}
  
  const handleCancelCustomer = () => {
    setTimeout(() => {
      filterRef.current.click()
    }, 200)
  }

  const fetchCustomerData = async (filters: any, from: any) => {
    try {
      if(!showPins)
        {
          if (markerArray && markerArray.length > 0) { 
            markerArray.forEach(marker => {
                if (map && marker) {
                    marker.removeFrom(map);
                    marker = null;
                }
            });
            markerArray.length = 0; //used to help coerce the garbage collector into cleaning up memory.
            customerShippingData.length = 0 //used to help coerce the garbage collector into cleaning up memory.
            //markerArray = [];
          }
          return
        }

        //customerShippingData.length = 0 //used to help coerce the garbage collector into cleaning up memory.

        const response = await getValidCustomerShippingData(filters);
        customerShippingData = response.data.customerDetails;

        if (typeof customerShippingData === 'string') {
            console.log("There are no valid pin locations");
            return;
        }
      
        // Clear existing markers from the map
        if (markerArray && markerArray.length > 0) {
          markerArray.forEach(marker => {
            if (map && marker) {
              marker.removeFrom(map);
              marker = null;
            }
          });
          markerArray.length = 0;
        }

        //const existingMarkers = new Map(markerArray.map(marker => [marker.options.customerID, marker]));

        // Clear existing markers from the map
        if (markerArray && markerArray.length > 0) {
            markerArray.forEach(marker => {
                if (map && marker) {
                    marker.removeFrom(map);
                    marker.off('click'); //unbinds event listener -> can lead to memory leak
                    marker.unbindPopup(); // unbinds popup
                    marker = null;
                }
            });
            markerArray = [];
        }

        customerShippingData.forEach((location: any) => {
            if (location != undefined && location.ShipAddressUpdated) {
                const pin = createRedPin(15);
                const latitude = parseFloat(location.ShipLatitude);
                const longitude = parseFloat(location.ShipLongitude);
                const marker = L.marker([latitude, longitude], { icon: pin });

                marker.on('click', () => {
                    if (marker.getPopup()) {
                        marker.unbindPopup();
                    }

                    const popupContent = bindPopupOnClick(marker, location);
                    /*const popup =*/ marker.bindPopup(popupContent).openPopup();
                    /*
                    popup.on('popupopen', () => {
                        const checkbox = document.getElementById(`select-${location.CustomerID}`) as HTMLInputElement;

                        if (checkbox) {
                            checkbox.addEventListener('change', () => {
                                handleCheckboxChange(
                                    location.CompanyName,
                                    location.CustomerID,
                                    location.ShipAddress,
                                    location.ShipCity,
                                    location.ShipState,
                                    location.ShipZip,
                                    location.ShipLatitude,
                                    location.ShipLongitude,
                                    location.ShipAddressUpdated,
                                    checkbox.checked
                                );
                            });
                        }
                    });
                    */
                });

                if (map) {
                    //marker.addTo(map);
                    markerArray.push(marker);
                    markerArray[markerArray.length-1]?.addTo(map)
                }
            }
        });
    } catch (error) {
        console.error('Error fetching customer data:', error);
    }
};

var addtoMap = false

useEffect(() => {
  if (map === null) {
      return;
  }  
  if(addtoMap){
    addtoMap = false

  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  }).addTo(map);
  }

  fetchCustomerData(currentFilters, "1"); 
}, [map, currentFilters, showPins]);

  /*const handleCheckboxChange = (
    CompanyName: string, // Use specific types instead of 'any' for better type checking
    CustomerID: string,
    ShipAddress: string,
    ShipCity: string,
    ShipState: string,
    ShipZip: string,
    ShipLatitude: string,
    ShipLongitude: string,
    ShipAddressUpdated: string,
    isChecked: boolean
  ) => {
    //console.log(isChecked)
    //console.log(CompanyName)
    setSelectedCustomers2(prev => {
      const newState = { ...prev };
      if (isChecked) {
        // Store the full customer data with relevant properties only
        newState[CustomerID] = {
          CompanyName: CompanyName,
          CustomerID: CustomerID,
          ShipAddres: ShipAddress,
          ShipCity: ShipCity,
          ShipState: ShipState,
          ShipZip: ShipZip,
          ShipLatitude: ShipLatitude,
          ShipLongitude: ShipLongitude,
          ShipAddressUpdated: ShipAddressUpdated
          // Add other necessary properties if required
        };
      } else {
        
        // Use 'CustomerID' directly to delete the entry if unchecked
       delete newState[CustomerID];
      }
      return newState;
    });
  };*/
  
  // Adjust handleFilter to use async-await
  const handleFilter = async (dropdownFilters: any, fromSearch: boolean) => {
    if (!fromSearch) 
    {
      // Directly using dropdownFilters to set currentFilters
      const newFilters = { // 1. Filters is getting created here
        searchBar: slug,
        startDate: dropdownFilters.startDate,
        endDate: dropdownFilters.endDate,
        selectedCustomers: dropdownFilters.selectedCustomers
      };
      setDropDownCustomers(dropdownFilters.selectedCustomers);
      setDropDownEndDate(dropdownFilters.endDate);
      setDropDownStartDate(dropdownFilters.startDate);
      setCurrentFilters(newFilters);

    } else {
        // If fromSearch is true, use existing dropdown states
      const filters = {
        searchBar: slug,
        startDate: dropDownStartDate,
        endDate: dropDownEndDate,
        selectedCustomers: dropDownCustomers
      };
  
      setCurrentFilters(filters);
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>Territory Map</PageTitle>
      <Card className='min-vh-80'>
        <Card.Header className='border-0 pt-6; padding: 0.5rem 1rem; margin: 0'>
          <Card.Title>
            Territory Map - Beta Version
            <QuestionTooltip 
                hoverText={`This is the Beta version of the Territory Map.
                  Please note, since it is a beta, there may be inaccuracies or performance issues. 

                  Please submit a ticket if you find any issues!

                  In its current state, the map has two views: 

                  The Heatmap view (only available to C2CAL Admin users) by default grabs all records from the last year using stored quotes and generates a heatmap based on total revenue by state. 
                  
                  The heatmap coloring is based on what percentage of the average the data source is. Currently, there is one coloring mode, Yellow-Blue-Red. 
                  This is a color blind friendly option that scales lowest rev at yellow and highest rev at red. White means there is no sales data for that particular state.
                  
                  The Pins view places a pin on all customer locations using the saved shipping data for each customer. By default it only includes location and identification information. 
                  The locationes rely on the address in the system being accurate, so the actual location of the customer may differ.
                  
                  Planned Features:
                  * More statistics on state revenue.
                  * By-state customer info
                  * More heatmap color options (There is a planned shades of red and light blue to dark purple.)
                  * More filtering options for pins
                  Stretch Features:
                  * Customer route planning using google maps integration
                  
                  ~~Let us know what other features you would like to see~~`}
              />

            {/* //removed search feature!
            <div className='d-flex align-items-center position-relative my-1'>
              <KTSVG
                path='/media/icons/duotone/General/Search.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                type='text'
                data-kt-customer-table-filter='search'
                className='form-control form-control-solid w-250px ps-15 border-0'
                placeholder='Search'
                value={slug}
                onChange={(e: any) => setSlug(e.target.value)}
              />
               <button className='btn btn-sm btn-primary' style={{ marginLeft: '10px' }} onClick={() => handleFilter({}, true)}>Search</button>
            </div>
                      */}
          </Card.Title>
          <div className='card-toolbar'>
            <div className='card-toolbar d-flex justify-content-end w-100'> {/* Added w-100 to make the container take full width */}
              <div className='ms-auto'> {/* Changed ms-3 to ms-auto to push the button to the right */}
              <button
                type='button'
                className='btn btn-light-primary me-3'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title='Map Options'
                ref={filterRef}
              >
                <KTSVG
                  path='/media/icons/duotone/Text/Toggle-Right.svg'
                  className='svg-icon-2 text-light'
                  />
                  Map Options
                </button>
                <MapFilter
                  handleCancelCustomer={handleCancelCustomer}
                  handleFilter={handleFilter}
                  isDev={isDeveloper}
                  showPins={showPins}
                  setShowPins={setShowPins}
                  colorOptions={coloringOptions}
                  //changeColorRange={null}
                  //changeColorMode={null}
                />
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body className='pt-0' style={{ padding: '0.5' }}> {/* Adjusted inline styles here */}
          <div>
            <MapContainer
              id="map"
              center={[37.8, -96]}
              zoom={4}
              style={{ height: '70vh' }}
              whenCreated={setMap}
              //</div>maxBounds={[
              //    [5.0, -167.5], // Southwest corner of bounding box (including Hawaii)
              //  [83.0, -40.0]  // Northeast corner of bounding box
              //]} // Restrict the map bounds to North America
              //maxBoundsViscosity={1.0} // How strictly the map adheres to the bounds
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              {geojsonData && heatMapData.updated && (
              <GeoJSON
                data={geojsonData}
                style={(feature) => {
                  const stateName = feature?.properties?.name || '';
                  return {
                    fillColor: getColor(stateName,"geo"), // Use the getColor function
                    weight: 2,
                    opacity: 1,
                    color: 'white',
                    dashArray: '3',
                    fillOpacity: 0.7
                  };
                }}
               
                onEachFeature={(feature, layer) => {
                  /*layer.off({
                    mouseover: highlightFeature,
                    mouseout: resetHighlight,
                    click: clickState,
                  });*/
                  if (!layer.hasEventListeners('click')) {                
                    layer.on({
                      mouseover: highlightFeature,
                      mouseout: resetHighlight,
                      click: clickState,
                    });
                  }
                }}
              
                  ref={geojsonRef}
              />
            )}
          </MapContainer>
        </div>
      </Card.Body>
    </Card>
  </>
  );
};


export default TerritoryMapPage;