import React, { useState, useRef, useEffect } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { Formik, Form, Field } from 'formik'
import { shallowEqual, useSelector } from 'react-redux'
import { KTSVG } from '../../../_metronic/helpers'
import { SelectDropDown } from '../SelectDropDown'
import { getPaymentOptions } from '../../services/payment'
import { getCustomersByOrderId } from '../../services/service'
import { getErrorMessage } from '../../../helper'
import * as helper from '../../../helper'

const initialValues = {
  po: '',
  paymentType: '',
  hardCopyAvailable: '',
  comment: '',
  attachment: '',
  attachmentFile: '',
}

const UpdatePaymentOption = ({ orderId, show, onHide, actions }) => {
  const [initValue, setInitValue] = useState(initialValues)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [cardNumber, setCardNumber] = useState('No Number')
  const [paymentType, setPaymentType] = useState()
  const [selectedCustomer, setSelectedCustomer] = useState({})
  const [customers, setCustomers] = useState([])
  const [allFiles, setAllFiles] = useState([])
  const [selectedFiles, setSelectedFiles] = useState([])
  const [attachmentFileNames, setAttachmentFileNames] = useState([])
  const [isAdmin, setIsAdmin] = useState(false)

  const formikRef = useRef()
  const inputFileRef = useRef()

  const user = useSelector(({ auth }) => auth.user, shallowEqual)

  const openInputFile = () => {
    inputFileRef.current.click()

    return false
  }

  const handlePaymentOption = (e) => {
    formikRef.current.setFieldValue('paymentType', e.target.value)
    setPaymentType(e.target.value)
  }

  const handleChangeFiles = (files) => {
    let fetchFiles = []
    let fileNames = []

    for (let file of files) {
      fetchFiles.push({ name: file.name, size: file.size })
      fileNames.push(file.name)
    }

    setSelectedFiles([...selectedFiles, ...fetchFiles])
    setAttachmentFileNames([...attachmentFileNames, ...fileNames])
  }

  const handleCancelFile = (name) => {
    setSelectedFiles(selectedFiles.filter((file) => file.name !== name))
    setAttachmentFileNames(attachmentFileNames.filter((fn) => fn !== name))

    let removedFiles = []

    for (let file of allFiles) {
      if (file.name !== name) removedFiles.push(file)
    }

    setAllFiles(removedFiles)
  }

  useEffect(() => {
    if (show) {
      setInitValue(initialValues);
      setPaymentType('')
    }
  }, [show])

  useEffect(() => {
    const jsonAttachment = JSON.stringify(attachmentFileNames)

    if (formikRef.current) formikRef.current.setFieldValue('attachment', jsonAttachment)
  }, [attachmentFileNames])

  useEffect(() => {
    setLoading(true)
    setSelectedFiles([])
    setSelectedCustomer({})
    setOptions([])
    setIsAdmin(helper.isNBS(user?.type))
    setCardNumber('No Number')

    let customerId = 0
    let selectedCustomerId = -1

    if (actions && actions.length && actions.length != 0) {
      let action = actions.findLast((a) => JSON.parse(a?.meta).paymentType === 'cc')

      if (action && action.meta) {
        const meta = JSON.parse(action?.meta)

        selectedCustomerId = meta.customerId ? meta.customerId : -1
      }
    }

    if (orderId !== 0) {
      if (helper.isNBS(user?.type)) {
        getCustomersByOrderId(orderId).then((res) => {
          setCustomers(res.data)

          if (res.data.length !== 0) {
            if (selectedCustomerId === -1) {
              setSelectedCustomer(res.data[0])
              customerId = res.data[0].id
            } else {
              let sCustomer = res.data.find((c) => c.id == selectedCustomerId)
              setSelectedCustomer(sCustomer ? sCustomer : res.data[0])

              customerId = sCustomer ? sCustomer.id : res.data[0].id
            }

            getPaymentOptions(orderId, customerId)
              .then((res) => {
                const cardNumbers = res.data
                  .flat()
                  .map((options) => options.profile?.payment.creditCard.cardNumber)
                  .flat()

                setOptions(cardNumbers)
                setLoading(false)
              })
              .catch((error) => {
                const errMsg = getErrorMessage(error)
                console.log(errMsg)
              })
          }
        })
      } else {
        getPaymentOptions(orderId, user.id)
          .then((res) => {
            const cardNumbers = res.data
              .flat()
              .map((options) => options.profile?.payment.creditCard.cardNumber)
              .flat()

            setOptions(cardNumbers)

            setLoading(false)
          })
          .catch((error) => {
            const errMsg = getErrorMessage(error)
            console.log(errMsg)
          })
      }
    }
  }, [orderId, actions, user])

  useEffect(() => {
    let action = {}
    if(actions)
      {
        if (actions.findLast((a) => JSON.parse(a?.meta).paymentType === 'cc')?.meta) {
          action = actions.findLast((a) => JSON.parse(a?.meta).paymentType === 'cc');
        } else if (actions.findLast((a) => JSON.parse(a?.meta).paymentType === 'po')?.meta) {
          action = actions.findLast((a) => JSON.parse(a?.meta).paymentType === 'po');
        } else if (actions.findLast((a) => JSON.parse(a?.meta).paymentType === 'other')?.meta) {
          action = actions.findLast((a) => JSON.parse(a?.meta).paymentType === 'other');
        } else {
          action = {};
        }
      }

    if (actions && actions.length !== 0) {
      let pType = JSON.parse(actions[actions.length - 1].meta).paymentType

      action = actions.findLast((a) => JSON.parse(a?.meta).paymentType === pType)
    }

    if (paymentType) {
      action = actions.findLast((a) => JSON.parse(a?.meta).paymentType === paymentType)
    }

    let poNumber = ''

    if (action && action.meta) {
      const meta = JSON.parse(action?.meta)

      poNumber = meta.po

      if (meta.paymentType === 'cc') {
        setCardNumber(
          options.length === 0 ? 'No Number' : options.includes(meta.po) ? meta.po : 'Select Number'
        )

        poNumber =
          options.length === 0 ? 'No Number' : options.includes(meta.po) ? meta.po : 'Select Number'
      }

      const optionAttachment = JSON.parse(action.attachment)

      if (optionAttachment) {
        setAttachmentFileNames(optionAttachment)

        let files = []

        for (let name of optionAttachment) {
          files.push({ name: name, size: 0 })
        }

        setSelectedFiles(files)
      }

      if (formikRef.current) {
        formikRef.current.setFieldValue('po', poNumber)
        formikRef.current.setFieldValue('paymentType', meta.paymentType)
        formikRef.current.setFieldValue('comment', action.comment ? action.comment : '')
        if (action.attachment) formikRef.current.setFieldValue('attachment', action.attachment)
      }
      setLoading(false)
    } else {
      if (formikRef.current) {
        formikRef.current.setFieldValue('po', '')
        formikRef.current.setFieldValue('paymentType', paymentType ?? '')
        formikRef.current.setFieldValue('comment', '')
      }
      setLoading(false)
    }
  }, [actions, paymentType, options, show])

  return (
    <>
      <Modal
        size='lg'
        show={show}
        onHide={onHide}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          <Modal.Title>View Payment Option</Modal.Title>
          <span className='text-hover-primary cursor-pointer' onClick={onHide}>
            <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
          </span>
        </Modal.Header>
        <Formik innerRef={formikRef} initialValues={initValue}>
          {({ setFieldValue, errors, touched, values }) => (
            <>
              <Modal.Body className='overlay cursor-default'>
                {loading && (
                  <div className='overlay-layer bg-transparent'>
                    <div className='spinner spinner-lg spinner-success' />
                  </div>
                )}
                <Form className='form form-label-right'>
                  <div className='form-group row'>
                    <div className='col-lg-12'>
                      <div>ID: {orderId}</div>
                    </div>
                    <div
                      className={`col-lg-12 ${!isAdmin ? 'd-none' : values.paymentType !== 'cc' && 'd-none'
                        }`}
                    >
                      <div className='row'>
                        <div className='col-lg-6'>
                          <span className='required'>Select customer</span>
                          <SelectDropDown
                            data={customers.map((c) => c.firstname + ' ' + c.lastname)}
                            defaultValue={
                              selectedCustomer.firstname
                                ? selectedCustomer.firstname + ' ' + selectedCustomer.lastname
                                : 'No customer assigned'
                            }
                            dirUp={false}
                            selectedValue={(value) => { }}
                            disable={true}
                          />
                        </div>
                      </div>
                    </div>
                    {/* PO */}
                    <div className={`col-lg-6 mt-10 ${values.paymentType !== 'cc' && 'd-none'}`}>
                      <span className='required'>Credit Card Number</span>
                      <SelectDropDown
                        data={options}
                        defaultValue={cardNumber}
                        dirUp={false}
                        selectedValue={(value) => { }}
                        disable={true}
                      />
                      {touched.po && errors.po ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>Card Number is required</div>
                        </div>
                      ) : null}
                    </div>
                    <div className={`col-lg-6 mt-10 ${values.paymentType === 'cc' && 'd-none'}`}>
                      <span className='required'>
                        {values.paymentType === 'cc'
                          ? 'Credit Card Number'
                          : values.paymentType === 'po'
                            ? 'Purchase Order Number'
                            : 'Other'}
                      </span>
                      <Field
                        name='po'
                        as='input'
                        placeholder={
                          values.paymentType === 'cc'
                            ? 'Enter C.C Number'
                            : values.paymentType === 'po'
                              ? 'Enter P.O Number'
                              : 'Other'
                        }
                        className='form-control'
                      />
                      {touched.po && errors.po ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.po}</div>
                        </div>
                      ) : null}
                    </div>
                    {/* Payment Type */}
                    <div className='col-lg-6 mt-10 position-relative'>
                      {values.paymentType === 'cc' && (
                        <span className='fs-7 fst-italic position-absolute bottom-100'>
                          **Credit cards will not be charged until the invoice is processed and the
                          service has completed.
                        </span>
                      )}
                      <span className='required'>Payment Option</span>
                      <select
                        className='form-control'
                        name='paymentType'
                        data-control='select2'
                        label='Payment Type'
                        onChange={handlePaymentOption}
                        // defaultValue=""
                        value={values.paymentType}
                        required
                      >
                        <option value="" disabled >Select Payment Option</option>
                        <option value='po'>Purchase Order</option>
                        <option value='cc'>Credit Card</option>
                        <option value='other'>Other</option>
                      </select>
                    </div>
                  </div>

                  {/* Comment */}
                  <div className='form-group mt-5'>
                    <span>Update Comments</span>
                    <Field
                      name='comment'
                      as='textarea'
                      rows='5'
                      placeholder='Enter Comments'
                      className='form-control'
                    />
                    {touched.comment && errors.comment ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{errors.comment}</div>
                      </div>
                    ) : null}
                  </div>

                  {/* Attachment */}
                  <div className='form-group mt-5 d-none'>
                    <Field
                      name='attachment'
                      as='textarea'
                      rows='5'
                      placeholder='Enter Comments'
                      className='form-control'
                    />
                  </div>
                  {/* Attachment */}
                  <div className='form-group mt-5'>
                    <div className='d-flex justify-content-between'>
                      <span>Attachment</span>
                      <span className='btn btn-primary btn-elevate p-2' onClick={openInputFile}>
                        <KTSVG
                          path='/media/icons/duotone/Files/UPload-folder.svg'
                          className='svg-icon-2 '
                        />
                        <span>Upload Files</span>
                      </span>
                    </div>
                    <input
                      type='file'
                      name='attachmentFile[]'
                      className='form-control d-none'
                      ref={inputFileRef}
                      onChange={(event) => {
                        let files = event.currentTarget.files
                        let attachedFiles = [...allFiles, ...files]
                        let dt = new DataTransfer()

                        attachedFiles.forEach((file) => {
                          dt.items.add(file)
                        })

                        setFieldValue('attachmentFile', dt.files)
                        setAllFiles(attachedFiles)

                        handleChangeFiles(files)
                      }}
                      multiple
                    />
                    <div className='my-5'>
                      {selectedFiles?.map((file, index) => {
                        return (
                          <div
                            key={index}
                            className='d-flex justify-content-between p-3 background-light-dark my-2 cursor-pointer'
                            onClick={() => handleCancelFile(file.name)}
                          >
                            <span>
                              {file.name} {file.size > 0 && `(${file.size} KB)`}
                            </span>
                            <KTSVG
                              path='/media/icons/duotone/Navigation/Close.svg'
                              className='svg-icon-1'
                            />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type='button'
                  onClick={() => {
                    onHide()
                    setSelectedFiles([])
                  }}
                  className='btn btn-light btn-elevate'
                >
                  Close
                </button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>
    </>
  )
}

export default UpdatePaymentOption
