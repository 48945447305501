import { Action } from '@reduxjs/toolkit'
import { put, takeLatest } from 'redux-saga/effects'
import { BillingCompany } from '../../../types'
import { getBillingCompanies } from './BillingCompanyCRUD'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  BillingCompanyRequested: '[Request Billing Companies] Action',
  BillingCompanyLoaded: '[Load Billing Companies] Action',
}

const initialBillingCompanyState: IBillingCompanyState = {
  loading: false,
  billingCompanies: { data: [] },
}
export interface IBillingCompanyResponse {
  current_page?: number
  first_page_url?: string
  from?: number
  last_page?: number
  last_page_url?: string
  next_page_url?: string
  path?: string
  per_page?: string
  prev_page_url?: string
  to?: number
  total?: number
  data: BillingCompany[]
}

export interface IBillingCompanyState {
  loading: boolean
  billingCompanies: IBillingCompanyResponse
}

export const reducer = (
  state: IBillingCompanyState = initialBillingCompanyState,
  action: ActionWithPayload<IBillingCompanyState>
) => {
  switch (action.type) {
    case actionTypes.BillingCompanyRequested: {
      return { ...state, loading: true }
    }

    case actionTypes.BillingCompanyLoaded: {
      const billingCompanies = action.payload?.billingCompanies as IBillingCompanyResponse

      return { loading: false, billingCompanies }
    }

    default:
      return state
  }
}

export const actions = {
  getBillingCompanies: (
    page: number,
    perPage: number,
    slug: string,
    column: string,
    sort: string,
    companyIds: string[] = []
  ) => ({
    type: actionTypes.BillingCompanyRequested,
    payload: { page, perPage, slug, column, sort, companyIds },
  }),
  billingCompaniesLoaded: (billingCompanies: IBillingCompanyResponse) => ({
    type: actionTypes.BillingCompanyLoaded,
    payload: { billingCompanies },
  }),
}

export function* saga() {
  yield takeLatest(
    actionTypes.BillingCompanyRequested,
    function* billingCompaniesRequested({ payload }: any) {
      const { data: billingCompanies } = yield getBillingCompanies(payload)
      yield put(actions.billingCompaniesLoaded(billingCompanies as IBillingCompanyResponse))
    }
  )
}
