import { useState, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { MenuItem } from './MenuItem'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import { useIntl } from 'react-intl'
import { UserModel } from '../../../../types'
import { RootState } from '../../../../setup'
import { getCRFToken } from '../../../../app/services/service'
import * as levelHelper from '../../../../helper/level.helper'
import { KTSVG } from '../../../helpers'
import { useHistory } from 'react-router-dom'
import { Button, Popover } from 'antd'
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import axios from 'axios'
import { getUserByToken } from '../../../../app/modules/auth/redux/AuthCRUD'
import * as AuthActions from '../../../../app/modules/auth/redux/AuthRedux'

export function PaymentCheckBox() {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const dispatch = useDispatch()
  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/users/setPaymentOption`, {
      payment_option: event.target.checked,
    })
    getUserByToken()
      .then((res) => {
        dispatch(AuthActions.actions.fulfillUser(res.data))
      })
      .catch()
    // console.log('res==', res) 
  }
  return (
    <FormControlLabel
      control={<Checkbox checked={!!user?.payment_option} onChange={handleChange} />}
      label='Opt out of using Credit Cards on this account.'
    />
  )
}

export function MenuInner() {
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [isAllowedTouchpoint, setIsAllowedTouchpoint] = useState<Boolean>(false) //used to hide touchpoint while in beta.
  const [show, setShow] = useState<boolean>(false)
  const history = useHistory()
  const dispatch = useDispatch()

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  useEffect(() => {
    setIsAdmin(levelHelper.isNBS(user?.type))
  }, [user])

  useEffect(() => {
    setIsAllowedTouchpoint(true)
  }, [user])

  const openNBSOnline = () => {
    getCRFToken().then((res) => {
      window.open(`${process.env.REACT_APP_NBS_ONLINE_URL}login_common.php?loginKey=${res.data}`)
    })
  }
  const openTouchPoint = () => {
    getCRFToken().then((res) => {
      const token = res.data;
      const newWindow = window.open(process.env.REACT_APP_TOUCHPOINT_URL);

      if (newWindow) {
        console.log("New window opened, waiting for the ready message...", process.env.REACT_APP_TOUCHPOINT_URL);

        // Listen for the "ready" message from App 2
        const receiveReadyMessage = (event) => {
          if (event.origin !== process.env.REACT_APP_TOUCHPOINT_URL) {
            console.warn("Received message from untrusted origin:", event.origin);
            return;
          }

          if (event.data && event.data.message === 'readyToReceiveToken') {
            console.log("App 2 is ready, sending the CRF token...");
            newWindow.postMessage({ loginKey: token }, process.env.REACT_APP_TOUCHPOINT_URL);

            // Clean up the listener once the message is sent
            window.removeEventListener('message', receiveReadyMessage);
          }
        };

        window.addEventListener('message', receiveReadyMessage);

      } else {
        console.error("Failed to open new window");
      }
    });
  }; 
  const openMobileApp = () => {
    getCRFToken().then((res) => {
      // Create a form element
      const form = document.createElement('form')
      form.method = 'POST'
      form.action = `${process.env.REACT_APP_NBS_ONLINE_URL}nbsmobile/login.php`
      form.target = '_blank'

      // Create a hidden input for loginKey
      const loginKeyInput = document.createElement('input')
      loginKeyInput.type = 'hidden'
      loginKeyInput.name = 'loginKey'
      loginKeyInput.value = res.data

      // Append the hidden input to the form
      form.appendChild(loginKeyInput)

      // Append the form to the document and submit it
      document.body.appendChild(form)
      form.submit()

      // Remove the form from the document after submission
      document.body.removeChild(form)
    })
  }

  const intl = useIntl()
  const handleClick = () => {
    setShow(true)
  }
  const onHide = () => {
    setShow(false)
  }

  return (
    <>
      <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/dashboard' />
      {isAdmin ? (
        <MenuInnerWithSub
          title='Learning Center'
          to='/learning-center'
          menuPlacement='bottom-start'
          menuTrigger='click'
        >
          <MenuItem to='/learning-center/employees' title='Employees' hasBullet={true} />
          <MenuItem to='/learning-center/customer' title='Customers' hasBullet={true} />
        </MenuInnerWithSub>
      ) : (
        <>
          <MenuItem to='/learning-center/customer' title='Learning Center' />

          {!user.payment && user.payment_option === 0 ? (
            <Popover
              content={<PaymentCheckBox />}
              title={
                <Typography>Click this button to add a credit card to your account.</Typography>
              }
            >
              <div
                className='menu-item me-lg-1 de-wobble'
                onClick={() => {
                  history.push('/crafted/account/payment-options')
                }}
              >
                <span className='menu-link py-3 menu-title'>
                  Add Credit Card
                  <span className='menu-icon pl-4'>
                    <KTSVG
                      path={'/media/icons/duotone/General/RightArrow.svg'}
                      className='svg-icon-2'
                    />
                  </span>
                </span>
              </div>
            </Popover>
          ) : null}
        </>
      )}
      {
        isAdmin && (
          <div className='menu-item me-lg-1' onClick={openNBSOnline}>
            <span className='menu-link py-3 menu-title'>C2CAL Online</span>
          </div>

        )
        // <MenuItem to={`${process.env.REACT_APP_NBS_ONLINE_URL}`} title='NBS Online' target='_blank' />
      }

      {
        isAdmin && false && (

          <div className='menu-item me-lg-1' onClick={openTouchPoint}>
            <span className='menu-link py-3 menu-title'>TouchPoint</span>
          </div>

        )
        // <MenuItem to={`${process.env.REACT_APP_NBS_ONLINE_URL}`} title='NBS Online' target='_blank' />
      }
      {isAdmin && (
        <div className='menu-item me-lg-1' onClick={openMobileApp}>
          <span className='menu-link py-3 menu-title'>C2CAL Mobile App</span>
        </div>

      )}
      {/* <MenuItem to={`/invoice`} title='Invoice' /> */}
    </>
  )
}
