import React, { useEffect, useState, useMemo, useRef } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { Formik, Form, Field, useField, useFormikContext } from 'formik'
import * as Yup from 'yup'
import { GET_REPORTS_API_URL, updateAccreditedCrud } from '../AccreditReportsCrud'
import axios from 'axios'
import { KTSVG } from '../../../../_metronic/helpers'
//import {SearchDr}
import SearchDropDown from '../../admin-configuration-setting/component/SearchDropDown'
import { getAllUsers } from '../../admin-configuration-setting/ReceiveAlertsCrud'
import toast from 'react-hot-toast'
import { QuestionTooltip } from './QuestionToolTip'
// This is the form for editing the accredited reports

// Define the modal component props
type EditAccreditedReportModalProps = {
  data: any
  ReportNumber: number
  open: boolean
  handleEditReport?: any
  pdfAction?: any
}
 
// The EditAccreditedReportModal component
const EditAccreditedReportModal: React.FC<EditAccreditedReportModalProps> = ({
  data,
  open,
  ReportNumber,
  handleEditReport,
  pdfAction,
}) => {
  const [loading, setLoading] = useState(false)
  const [repeatabilityToggle, setRepeatabilityToggle] = useState(true)
  const [performanceChecksFound, setPerformanceChecksFound] = useState(true)
  const [performanceChecksLeft, setPerformanceChecksLeft] = useState(true)
  const [tolerance, setTolerance] = useState(true)
  const [eccentricLoadAsFound, setEccentricLoadAsFound] = useState(true)
  const [eccentricLoadAsLeft, setEccentricLoadAsLeft] = useState(true)
  const [massValue, setMassValue] = useState(true)
  const [submit, setSubmit] = useState<boolean>(false)
  const [users, setUsers] = useState<any>([])
  const [selectedUserId, setSelectedUserId] = useState<string>('')
  const formikRef: any = useRef()
  const [fetchedData, setFetchedData] = useState({
    mfg: '',
    model: '',
    serialNumber: '',
    location: '',
    equipType: '',
    capacity: '',
    um: '',
    division: '',
    divisionDual: '',
    scaleClass: '',
    wt1: '',
    wt2: '',
    wt3: '',
    wt4: '',
    wt5: '',
    wt6: '',
    wt7: '',
    wt8: '',
    wt9: '',
    wt10: '',
    wtF1: '',
    wtF2: '',
    wtF3: '',
    wtF4: '',
    wtF5: '',
    wtF6: '',
    wtL1: '',
    wtL2: '',
    wtL3: '',
    wtL4: '',
    wtL5: '',
    wtL6: '',
    tOL1: '',
    tOL2: '',
    tOL3: '',
    tOL4: '',
    tOL5: '',
    tOL6: '',
    calibrationDate: '',
    calibrationMonth: '',
    calibrationYear: '',
    dateScheduled: '',
    department: '',
    temperature: '',
    temperatureUnit: '',
    dbkey_nbs_site_admin: '',
    isSiteCalibration: '',
    isWorking: '',
    isClean: '',
    isVerifiedZero: '',
    comments: '',
    WU1: '',
    WU2: '',
    WU3: '',
    WU4: '',
    WU5: '',
    WU6: '',
    ReportNo: '',
    ynA2LACalRep: '',
    IdShipping: '',
    IdEquipment: '',
    CornerLoadFront: '',
    CornerLoadBack: '',
    CornerLoadLeft: '',
    CornerLoadRight: '',
    CornerLoadFrontAsLeft: '',
    CornerLoadBackAsLeft: '',
    CornerLoadLeftAsLeft: '',
    CornerLoadRightAsLeft: '',
    CornerLoadValue: '',
  })
 
  
  // Initial form values based on the type of operation
  const initialValues = useMemo(() => {
    return {
      customer_id: data.customerid,
      calibration_date: data.dateofcal,
      mfg: fetchedData.mfg,
      model: fetchedData.model,
      serialNumber: fetchedData.serialNumber,
      location: fetchedData.location,
      equipType: fetchedData.equipType,
      capacity: fetchedData.capacity,
      um: fetchedData.um,
      division: fetchedData.division,
      divisionDual: fetchedData.divisionDual,
      scaleClass: fetchedData.scaleClass,
      wt1: fetchedData.wt1,
      wt2: fetchedData.wt2,
      wt3: fetchedData.wt3,
      wt4: fetchedData.wt4,
      wt5: fetchedData.wt5,
      wt6: fetchedData.wt6,
      wt7: fetchedData.wt7,
      wt8: fetchedData.wt8,
      wt9: fetchedData.wt9,
      wt10: fetchedData.wt10,
      wtF1: fetchedData.wtF1,
      wtF2: fetchedData.wtF2,
      wtF3: fetchedData.wtF3,
      wtF4: fetchedData.wtF4,
      wtF5: fetchedData.wtF5,
      wtF6: fetchedData.wtF6,
      wtL1: fetchedData.wtL1,
      wtL2: fetchedData.wtL2,
      wtL3: fetchedData.wtL3,
      wtL4: fetchedData.wtL4,
      wtL5: fetchedData.wtL5,
      wtL6: fetchedData.wtL6,
      tOL1: fetchedData.tOL1,
      tOL2: fetchedData.tOL2,
      tOL3: fetchedData.tOL3,
      tOL4: fetchedData.tOL4,
      tOL5: fetchedData.tOL5,
      tOL6: fetchedData.tOL6,
      calibrationDate: fetchedData.calibrationDate,
      calibrationMonth: fetchedData.calibrationMonth,
      calibrationYear: fetchedData.calibrationYear,
      dateScheduled: fetchedData.dateScheduled,
      department: fetchedData.department,
      temperature: fetchedData.temperature,
      temperatureUnit: fetchedData.temperatureUnit,
      dbkey_nbs_site_admin: fetchedData.dbkey_nbs_site_admin,
      isSiteCalibration: fetchedData.isSiteCalibration,
      isWorking: fetchedData.isWorking,
      isClean: fetchedData.isClean,
      isVerifiedZero: fetchedData.isVerifiedZero,
      comments: fetchedData.comments,
      WU1: fetchedData.WU1,
      WU2: fetchedData.WU2,
      WU3: fetchedData.WU3,
      WU4: fetchedData.WU4,
      WU5: fetchedData.WU5,
      WU6: fetchedData.WU6,
      ReportNo: fetchedData.ReportNo,
      ynA2LACalRep: fetchedData.ynA2LACalRep,
      IdShipping: fetchedData.IdShipping,
      IdEquipment: fetchedData.IdEquipment,
      CornerLoadFront: fetchedData.CornerLoadFront,
      CornerLoadBack: fetchedData.CornerLoadBack,
      CornerLoadLeft: fetchedData.CornerLoadLeft,
      CornerLoadRight: fetchedData.CornerLoadRight,
      CornerLoadFrontAsLeft: fetchedData.CornerLoadFrontAsLeft,
      CornerLoadBackAsLeft: fetchedData.CornerLoadBackAsLeft,
      CornerLoadLeftAsLeft: fetchedData.CornerLoadLeftAsLeft,
      CornerLoadRightAsLeft: fetchedData.CornerLoadRightAsLeft,
      CornerLoadValue: fetchedData.CornerLoadValue,
    }
  }, [data, fetchedData])

  useEffect(() => {
    // Fetch data and update state

    const fetchData = async () => {
     // setLoading(true)
      try {
        const response = await axios.get(`${GET_REPORTS_API_URL}/getAccreditedReportData`, {
          params: { ReportNo: ReportNumber },
        })
        console.log(response.data[0])

        setFetchedData({
          ReportNo: response.data[0].ReportNo,
          IdShipping: response.data[0].IdShipping,
          IdEquipment: response.data[0].IdEquipment,
          mfg: response.data[0].Mfg,
          model: response.data[0].Model,
          serialNumber: response.data[0].SerialNumber,
          location: response.data[0].Location,
          equipType: response.data[0].EquipType,
          capacity: response.data[0].Capacity,
          um: response.data[0].UM,
          division: response.data[0].Division,
          divisionDual: response.data[0].DivisionDual,
          scaleClass: response.data[0].ScaleClass,
          wt1: response.data[0].Wt1,
          wt2: response.data[0].Wt2,
          wt3: response.data[0].Wt3,
          wt4: response.data[0].Wt4,
          wt5: response.data[0].Wt5,
          wt6: response.data[0].Wt6,
          wt7: response.data[0].Wt7,
          wt8: response.data[0].Wt8,
          wt9: response.data[0].Wt9,
          wt10: response.data[0].Wt10,
          wtF1: response.data[0].WtF1,
          wtF2: response.data[0].WtF2,
          wtF3: response.data[0].WtF3,
          wtF4: response.data[0].WtF4,
          wtF5: response.data[0].WtF5,
          wtF6: response.data[0].WtF6,
          wtL1: response.data[0].WtL1,
          wtL2: response.data[0].WtL2,
          wtL3: response.data[0].WtL3,
          wtL4: response.data[0].WtL4,
          wtL5: response.data[0].WtL5,
          wtL6: response.data[0].WtL6,
          tOL1: response.data[0].TOL1,
          tOL2: response.data[0].TOL2,
          tOL3: response.data[0].TOL3,
          tOL4: response.data[0].TOL4,
          tOL5: response.data[0].TOL5,
          tOL6: response.data[0].TOL6,
          WU1: response.data[0].WU1,
          WU2: response.data[0].WU2,
          WU3: response.data[0].WU3,
          WU4: response.data[0].WU4,
          WU5: response.data[0].WU5,
          WU6: response.data[0].WU6,
          calibrationDate: response.data[0].CalibrationDate,
          calibrationMonth: response.data[0].CalibrationMonth,
          calibrationYear: response.data[0].CalibrationYear,
          dateScheduled: response.data[0].DateScheduled,
          department: response.data[0].Department,
          temperature: response.data[0].Temperature,
          temperatureUnit: response.data[0].TemperatureUnit,
          dbkey_nbs_site_admin: response.data[0].dbkey_nbs_site_admin,
          isSiteCalibration: response.data[0].isSiteCalibration,
          isWorking: response.data[0].isWorking,
          isClean: response.data[0].isClean,
          isVerifiedZero: response.data[0].isVerifiedZero,
          comments: response.data[0].Comments,
          ynA2LACalRep: response.data[0].ynA2LACalRep,
          CornerLoadFront: response.data[0].CornerLoadFront,
          CornerLoadBack: response.data[0].CornerLoadBack,
          CornerLoadLeft: response.data[0].CornerLoadLeft,
          CornerLoadRight: response.data[0].CornerLoadRight,
          CornerLoadFrontAsLeft: response.data[0].CornerLoadFrontAsLeft,
          CornerLoadBackAsLeft: response.data[0].CornerLoadBackAsLeft,
          CornerLoadLeftAsLeft: response.data[0].CornerLoadLeftAsLeft,
          CornerLoadRightAsLeft: response.data[0].CornerLoadRightAsLeft,
          CornerLoadValue: response.data[0].CornerLoadValue,
        })
        // set the initial users dropdown
        getAllUsers(2).then((res) => {

          console.log(res.data)
          setUsers(res.data)
        })
      } catch (error) {
        console.error('Error fetching data', error)
      } finally {
        //setLoading(false)
      }
    }
    fetchData()
  }, [ReportNumber])

  const AccreditedReportSchema = Yup.object().shape({
    calibrationDate: Yup.string()
      .required('Day of the month is required')
      .matches(
        /^(0[1-9]|[12][0-9]|3[01])$/,
        'Day of the month must be a two-digit number between 01 and 31'
      ),

    calibrationMonth: Yup.string()
      .required('Month is required')
      .matches(/^(0[1-9]|1[0-2])$/, 'Month must be a two-digit number between 01 and 12'),

    calibrationYear: Yup.string()
      .required('Year is required')
      .matches(/^(19|20)\d{2}$/, 'Year must be a four-digit number between 1900 and 2099'),

    dateScheduled: Yup.string()
      .required('Date scheduled is required')
      .matches(
        /^(0[1-9]|1[0-2])\/(19|20)\d{2}$/,
        'Date scheduled must be in the format MM/YYYY with a valid month and year'
      ),

    mfg: Yup.string()
      .max(255, 'Manufacturing must not exceed 255 characters')
      .required('Manufacturing is required'),

    model: Yup.string()
      .max(255, 'Model must not exceed 255 characters')
      .required('Model is required'),

    serialNumber: Yup.string()
      .max(255, 'Serial must not exceed 255 characters')
      .required('Serial is required'),

    //capacity: Yup.number().typeError('Capacity must be a number').required('Capacity is required'),

    division: Yup.number().typeError('Division must be a number').required('Division is required'),

  //   divisionDual: Yup.number()
  // .transform((value, originalValue) => (String(originalValue).trim() === '' ? undefined : value))
  // .typeError('Division must be a number')
  // .nullable(true),

    um: Yup.string()
      .max(255, 'Unit Measure must not exceed 255 characters')
      .required('Unit Measure is required'),

    scaleClass: Yup.string()
      .required('Scale class is required')
      .oneOf(['I', 'II', 'III'], 'Scale class must be I, II, or III'),

      // department: Yup.string()
      // .transform(value => value.trim() === '' ? null : value)
      // .max(255, 'Department must not exceed 255 characters')
      // .nullable(true),

    temperature: Yup.number()
      .typeError('Temperature must be a number')
      .required('Temperature is required'),

    temperatureUnit: Yup.string()
      .required('Temperature unit is required')
      .oneOf(['c', 'C', 'F', 'f', 'K', 'k'], 'Temperature unit must be c, C, F, f, K, or k'),

    comments: Yup.string()
      .nullable(true) // Allow null values
      .max(20000, 'Comments must not exceed 20000 characters'),

    wt3: Yup.number().typeError('Weight must be a number'),
    wt4: Yup.number().typeError('Weight must be a number'),
    wt5: Yup.number().typeError('Weight must be a number'),
    wt6: Yup.number().typeError('Weight must be a number'),
    wt7: Yup.number().typeError('Weight must be a number'),
    wt8: Yup.number().typeError('Weight must be a number'),
    wt9: Yup.number().typeError('Weight must be a number'),
    wt10: Yup.number().typeError('Weight must be a number'),

    
  

    CornerLoadRightAsLeft: Yup.mixed().when('CornerLoadValue', {
      is: (CornerLoadValue: string) => CornerLoadValue !== 'N/A', // When CornerLoadValue is not "N/A"
      then: Yup.mixed()
        .required('Value is required')
        .test(
          'is-number-or-null',
          'Value must be a number or "NULL"',
          (value) => !isNaN(value) || value === 'NULL'
        ),
      otherwise: Yup.mixed().notRequired(), // Make the field not required if CornerLoadValue is "N/A"
    }),

    CornerLoadBackAsLeft: Yup.mixed().when('CornerLoadValue', {
      is: (CornerLoadValue: string) => CornerLoadValue !== 'N/A', // When CornerLoadValue is not "N/A"
      then: Yup.mixed()
        .required('Value is required')
        .test(
          'is-number-or-null',
          'Value must be a number or "NULL"',
          (value) => !isNaN(value) || value === 'NULL'
        ),
      otherwise: Yup.mixed().notRequired(), // Make the field not required if CornerLoadValue is "N/A"
    }),
    CornerLoadFrontAsLeft: Yup.mixed().when('CornerLoadValue', {
      is: (CornerLoadValue: string) => CornerLoadValue !== 'N/A', // When CornerLoadValue is not "N/A"
      then: Yup.mixed()
        .required('Value is required')
        .test(
          'is-number-or-null',
          'Value must be a number or "NULL"',
          (value) => !isNaN(value) || value === 'NULL'
        ),
      otherwise: Yup.mixed().notRequired(), // Make the field not required if CornerLoadValue is "N/A"
    }),
    CornerLoadLeftAsLeft: Yup.mixed().when('CornerLoadValue', {
      is: (CornerLoadValue: string) => CornerLoadValue !== 'N/A', // When CornerLoadValue is not "N/A"
      then: Yup.mixed()
        .required('Value is required')
        .test(
          'is-number-or-null',
          'Value must be a number or "NULL"',
          (value) => !isNaN(value) || value === 'NULL'
        ),
      otherwise: Yup.mixed().notRequired(), // Make the field not required if CornerLoadValue is "N/A"
    }),
    CornerLoadRight: Yup.mixed().when('CornerLoadValue', {
      is: (CornerLoadValue: string) => CornerLoadValue !== 'N/A', // When CornerLoadValue is not "N/A"
      then: Yup.mixed()
        .required('Value is required')
        .test(
          'is-number-or-null',
          'Value must be a number or "NULL"',
          (value) => !isNaN(value) || value === 'NULL'
        ),
      otherwise: Yup.mixed().notRequired(), // Make the field not required if CornerLoadValue is "N/A"
    }),
    CornerLoadBack: Yup.mixed().when('CornerLoadValue', {
      is: (CornerLoadValue: string) => CornerLoadValue !== 'N/A', // When CornerLoadValue is not "N/A"
      then: Yup.mixed()
        .required('Value is required')
        .test(
          'is-number-or-null',
          'Value must be a number or "NULL"',
          (value) => !isNaN(value) || value === 'NULL'
        ),
      otherwise: Yup.mixed().notRequired(), // Make the field not required if CornerLoadValue is "N/A"
    }),
    CornerLoadFront: Yup.mixed().when('CornerLoadValue', {
      is: (CornerLoadValue: string) => CornerLoadValue !== 'N/A', // When CornerLoadValue is not "N/A"
      then: Yup.mixed()
        .required('Value is required')
        .test(
          'is-number-or-null',
          'Value must be a number or "NULL"',
          (value) => !isNaN(value) || value === 'NULL'
        ),
      otherwise: Yup.mixed().notRequired(), // Make the field not required if CornerLoadValue is "N/A"
    }),
    CornerLoadLeft: Yup.mixed().when('CornerLoadValue', {
      is: (CornerLoadValue: string) => CornerLoadValue !== 'N/A', // When CornerLoadValue is not "N/A"
      then: Yup.mixed()
        .required('Value is required')
        .test(
          'is-number-or-null',
          'Value must be a number or "NULL"',
          (value) => !isNaN(value) || value === 'NULL'
        ),
      otherwise: Yup.mixed().notRequired(), // Make the field not required if CornerLoadValue is "N/A"
    }),

    CornerLoadValue: Yup.mixed()
      .required('Value is required')
      .test(
        'is-number-or-null',
        'Value must be a number or "N/A"',
        (value) => !isNaN(value) || value === 'N/A'
      ),
  })

  // Function to handle form submission
  const handleSubmit = async (values: any) => {
    setSubmit(true)
    console.log(values)
    updateAccreditedCrud(initialValues.ReportNo, values).then((res) => {
      toast.success(res.data.message)
      setSubmit(false)
      // force a update
      ReportNumber = ReportNumber
    })
  }
  const selectUser = (value: any) => {
    setSelectedUserId(value)
    formikRef.current.setFieldValue('dbkey_nbs_site_admin', value)
  }

  const scrollToFirstError = (errors: {}, touched: { [x: string]: unknown }) => {
    const errorFieldNames = Object.keys(errors).filter((key) => touched[key])
    if (errorFieldNames.length > 0) {
      const errorFieldName = errorFieldNames[0]
      const errorField = document.getElementsByName(errorFieldName)[0]
      if (errorField) {
        errorField.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  }

  return (
    <Modal show={open}>
 <Modal.Header>
  <Modal.Title>Edit Accredited Report</Modal.Title>
  <a href="https://www.youtube.com/watch?v=NULblC9D4gw&t=53s" target="_blank" rel="noopener noreferrer" style={{ marginLeft: '10px', textDecoration: 'underline' }}>
    How accredited reports function and how to edit them
  </a>
</Modal.Header>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={AccreditedReportSchema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, isValid, dirty, setFieldValue }) => (
          <Form>
            <Modal.Body>
              <ul>
                {Object.keys(errors).map((fieldName, index) => (
                  <li
                    key={index}
                    style={{ color: 'red' }}
                  >{`The field ${fieldName} has an issue. If it is blank and should be, try to type into it and clear out your input.`}</li>
                ))}
              </ul>

              {/* Date section */}
              <div className='row fv-plugins-icon-container'>
                <div className='col-lg-3 mb-7'>
                  <label htmlFor='calibrationDate'>Calibration Day    
                  <QuestionTooltip hoverText="This is the day of the month; it should be in DD format." />
                 </label>
                  <Field name='calibrationDate' type='text' id='email' />
                  {touched.calibrationDate && errors.calibrationDate ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.calibrationDate}</div>
                    </div>
                  ) : null}
                </div>

                <div className='col-lg-3 mb-7'>
                  <label htmlFor='calibrationMonth'>Calibration Month
                  <QuestionTooltip hoverText="This is the month of calibration; it should be in MM format." />
                  </label>
                  <Field name='calibrationMonth' type='text' />
                  {touched.calibrationMonth && errors.calibrationMonth ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.calibrationMonth}</div>
                    </div>
                  ) : null}
                </div>
                <div className='col-lg-3 mb-7'>
                  <label htmlFor='calibrationYear'>Calibration Year
                  <QuestionTooltip hoverText="This is the year of calibration; it should be in YYYY format." />
                  </label>
                  <Field name='calibrationYear' type='text' />
                  {touched.calibrationYear && errors.calibrationYear ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.calibrationYear}</div>
                    </div>
                  ) : null}
                </div> 
              </div>

              {/* Next Date and tech */}
              <div className='row fv-plugins-icon-container'>
                <div className='col-lg-6 mb-7'>
                  <label htmlFor='dateScheduled'>Next Date Of Calibration
                  <QuestionTooltip hoverText="This is the next calibration date; it should be in the format MM/YYYY." />
                  </label>
                  <Field name='dateScheduled' type='text' />
                  {touched.dateScheduled && errors.dateScheduled ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.dateScheduled}</div>
                    </div>
                  ) : null}
                </div>
                {users.length > 0 ? (
                  <>
                    <label className='required fw-bold fs-6 mb-2'>Technician
                    <QuestionTooltip hoverText="Change the tech by clicking the dropdown. You can also search for a specific tech." />
                    </label>
                    <SearchDropDown
                      data={users.filter((user: { status: string, user_id:string }) => user.status === 'A' || user.user_id === initialValues.dbkey_nbs_site_admin)}
                      //data={users}
                      selectedValue={selectUser}
                      defaultValue={initialValues.dbkey_nbs_site_admin}
                    />
                  </>
                ) : null}
              </div>

              {/* Manufacture, Model, Serial */}
              <div className='row fv-plugins-icon-container'>
                <div className='col-lg-4 mb-7'>
                  <label htmlFor='mfg'>Manufacturer
                  <QuestionTooltip hoverText="Edit the manufacturer here; note this will only affect the PDF." />
                  </label>
                  <Field name='mfg' type='text' />
                  {touched.mfg && errors.mfg ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.mfg}</div>
                    </div>
                  ) : null}
                </div>
                <div className='col-lg-4 mb-7'>
                  <label htmlFor='model'>Model<QuestionTooltip hoverText="Edit the model here; note this will only affect the PDF." /></label>
                  <Field name='model' type='text' />
                  {touched.model && errors.model ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.model}</div>
                    </div>
                  ) : null}
                </div>
                <div className='col-lg-4 mb-7'>
                  <label htmlFor='serialNumber'>Serial#<QuestionTooltip hoverText="Edit the serial number here; note this will only affect the PDF." /></label>
                  <Field name='serialNumber' type='text' />
                </div>
                {touched.serialNumber && errors.serialNumber ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.serialNumber}</div>
                  </div>
                ) : null}
              </div>

              {/* Capacity, division dual */}
              <div className='form-group'>
                <label htmlFor='capacity'>Capacity<QuestionTooltip hoverText="Edit the capacity here. This will affect the number of decimal places shown in the performance checks table. If the capacity is 60 and the division is .0001, values in the performance table will have decimal place values like 20.0000 up to a mass of 60. If the capacity is 60/200 and the division is .0001, and the division dual is .001, values in the performance table will have decimals like 30.0001 through a mass of 60 and values like 79.001 up to 200." /></label>
                <Field name='capacity' type='text' />
                {touched.capacity && errors.capacity ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.capacity}</div>
                  </div>
                ) : null}
              </div>
              <div className='form-group'>
                <label htmlFor='division'>Division<QuestionTooltip hoverText="Edit the division here, This will effect the amount of decimal places in the performance checks table, based on the set capacity. See the capacity question mark for more info." /></label>
                <Field name='division' type='text' />
                {touched.division && errors.division ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.division}</div>
                  </div>
                ) : null}
              </div>
              <div className='form-group'>
                <label htmlFor='divisionDual'>Division Dual<QuestionTooltip hoverText="Edit the division here. This will affect the number of decimal places in the performance checks table, based on the set capacity. See the capacity question mark for more info." /></label>
                <Field name='divisionDual' type='text' />
                {touched.divisionDual && errors.divisionDual ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.divisionDual}</div>
                  </div>
                ) : null}
              </div>
              <div className='form-group'>
                <label htmlFor='CornerLoadValue'>Eccentric Load Mass Value<QuestionTooltip hoverText="Edit the eccentric load mass here for the eccentric load table. If this is N/A, the eccentric load section will not show." /></label>
                <Field name='CornerLoadValue' type='text' />
                {touched.CornerLoadValue && errors.CornerLoadValue ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.CornerLoadValue}</div>
                  </div>
                ) : null}
              </div>
              {/* UM, scale class, department */}
              <div className='row fv-plugins-icon-container'>
                <div className='col-lg-4 mb-7'>
                  <label htmlFor='um'>Unit of Measure</label>
                  <Field name='um' type='text' />
                  {touched.um && errors.um ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.um}</div>
                    </div>
                  ) : null}
                </div>
                <div className='col-lg-4 mb-7'>
                  <label htmlFor='scaleClass'>Scale Class<QuestionTooltip hoverText="This can be either I, II, or III; this will affect the logic on both the PDF and the edit report. For example, the 10 repeatability checks will only show for scale class I or II if the report is accredited." /></label>
                  <Field name='scaleClass' type='text' />
                  {touched.scaleClass && errors.scaleClass ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.scaleClass}</div>
                    </div>
                  ) : null}
                </div>
                <div className='col-lg-4 mb-7'>
                  <label htmlFor='department'>Department<QuestionTooltip hoverText="This will only effect the pdf" /></label>
                  <Field name='department' type='text' />
                  {touched.department && errors.department ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.department}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              {/* Site calibration, temp #, temp unit */}

              <div className='row fv-plugins-icon-container'>
                <div className='col-lg-4 mb-7'>
                  <label htmlFor='location'>Location<QuestionTooltip hoverText="This will only effect the pdf" /></label>
                  <Field name='location' type='text' />
                  {touched.location && errors.location ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.location}</div>
                    </div>
                  ) : null}
                </div>
                <div className='col-lg-4 mb-7'>
                  <label htmlFor='temperature'>Temp #<QuestionTooltip hoverText="This will only effect the pdf" /></label>
                  <Field name='temperature' type='text' />
                  {touched.temperature && errors.temperature ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.temperature}</div>
                    </div>
                  ) : null}
                </div>
                <div className='col-lg-4 mb-7'>
                  <label htmlFor='temperatureUnit'>Temp Unit<QuestionTooltip hoverText="This will only effect the pdf, but can be a value of C c F f K k" /></label>
                  <Field name='temperatureUnit' type='text' />
                  {touched.temperatureUnit && errors.temperatureUnit ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.temperatureUnit}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              {/* Working, Clean */}
              <div className='row fv-plugins-icon-container'>
                <div className='col-lg-3 mb-7'>
                  <label htmlFor='isSiteCalibration'>Site Calibration<QuestionTooltip hoverText="Effects the condition section" /></label>
                  <Field
                    name='isSiteCalibration'
                    type='checkbox'
                    checked={values.isSiteCalibration == '1'}
                    onChange={() =>
                      setFieldValue('isSiteCalibration', values.isSiteCalibration == '1' ? 0 : 1)
                    }
                  />
                </div>
                <div className='col-lg-3 mb-7'>
                  <label htmlFor='isWorking'>Working<QuestionTooltip hoverText="Effects the condition section" /></label>
                  <Field
                    name='isWorking'
                    type='checkbox'
                    checked={values.isWorking == '1'}
                    onChange={() => setFieldValue('isWorking', values.isWorking == '1' ? 0 : 1)}
                  />
                </div>
                <div className='col-lg-3 mb-7'>
                  <label htmlFor='isClean'>Clean<QuestionTooltip hoverText="Effects the condition section" /></label>
                  <Field
                    name='isClean'
                    type='checkbox'
                    checked={values.isClean == '1'}
                    onChange={() => setFieldValue('isClean', values.isClean == '1' ? 0 : 1)}
                  />
                </div>
                <div className='col-lg-3 mb-7'>
                  <label htmlFor='isVerifiedZero'>Verified Zero<QuestionTooltip hoverText="Effects the condition section" /></label>
                  <Field
                    name='isVerifiedZero'
                    type='checkbox'
                    checked={values.isVerifiedZero == '1'}
                    onChange={() =>
                      setFieldValue('isVerifiedZero', values.isVerifiedZero == '1' ? 0 : 1)
                    }
                  />
                </div>
              </div>
              {/* Accredited Checkbox */}
              <div className='row fv-plugins-icon-container'>
                <div className='col-lg-3 mb-7'>
                  <label htmlFor='ynA2LACalRep'>Accredited<QuestionTooltip hoverText="This will affect a lot of the report logic. If accredited and scale class is I or II, repeatability checks will show. Also, there will be 6 of the following: mass values, performance checks found, performance checks left, and tolerance." /></label>
                  <Field
                    name='ynA2LACalRep'
                    type='checkbox'
                    checked={values.ynA2LACalRep == '1'}
                    onChange={() =>
                      setFieldValue('ynA2LACalRep', values.ynA2LACalRep == '1' ? 0 : 1)
                    }
                  />
                </div>
              </div>
              {/* Comments Section */}
              <div className='form-group'>
                <label htmlFor='comments'>Comments</label>
                <Field name='comments' type='text' />
              </div>
              {/* Scale Class, Wt1 to Wt10 for repeatability checks*/}
              <div className='d-flex' onClick={() => setRepeatabilityToggle(!repeatabilityToggle)}>
                <h3>Repeatability Checks<QuestionTooltip hoverText="This will show if the report is accredited and the scale class is I or II. These values are used for the calculation of standard deviation and measurement uncertainty." /></h3>
                <KTSVG
                  path={`/media/icons/duotone/Navigation/${
                    repeatabilityToggle ? 'Angle-up' : 'Angle-down'
                  }.svg`}
                  className='svg-icon-1'
                />
              </div>
              {values.ynA2LACalRep == '1' &&
                (values.scaleClass == 'II' || values.scaleClass == 'I') && (
                  <>
                    <div
                      className={`${
                        repeatabilityToggle ? 'd-none form-group' : 'd-block form-group'
                      } ps-5`}
                      key='wt1'
                    >
                      <label htmlFor='wt1'>Weight 1</label>
                      <Field name='wt1' type='number' />
                      {touched.wt1 && errors.wt1 ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.wt1}</div>
                        </div>
                      ) : null}
                    </div>
                    <div
                      className={`${
                        repeatabilityToggle ? 'd-none form-group' : 'd-block form-group'
                      } ps-5`}
                      key='wt2'
                    >
                      <label htmlFor='wt2'>Weight 2</label>
                      <Field name='wt2' type='number' />
                      {touched.wt2 && errors.wt2 ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.wt2}</div>
                        </div>
                      ) : null}
                    </div>
                    <div
                      className={`${
                        repeatabilityToggle ? 'd-none form-group' : 'd-block form-group'
                      } ps-5`}
                      key='wt3'
                    >
                      <label htmlFor='wt3'>Weight 3</label>
                      <Field name='wt3' type='number' />
                      {touched.wt3 && errors.wt3 ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.wt3}</div>
                        </div>
                      ) : null}
                    </div>
                    <div
                      className={`${
                        repeatabilityToggle ? 'd-none form-group' : 'd-block form-group'
                      } ps-5`}
                      key='wt4'
                    >
                      <label htmlFor='wt4'>Weight 4</label>
                      <Field name='wt4' type='number' />
                      {touched.wt4 && errors.wt4 ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.wt4}</div>
                        </div>
                      ) : null}
                    </div>
                    <div
                      className={`${
                        repeatabilityToggle ? 'd-none form-group' : 'd-block form-group'
                      } ps-5`}
                      key='wt5'
                    >
                      <label htmlFor='wt5'>Weight 5</label>
                      <Field name='wt5' type='number' />
                      {touched.wt5 && errors.wt5 ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.wt5}</div>
                        </div>
                      ) : null}
                    </div>
                    <div
                      className={`${
                        repeatabilityToggle ? 'd-none form-group' : 'd-block form-group'
                      } ps-5`}
                      key='wt6'
                    >
                      <label htmlFor='wt6'>Weight 6</label>
                      <Field name='wt6' type='number' />
                      {touched.wt6 && errors.wt6 ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.wt6}</div>
                        </div>
                      ) : null}
                    </div>
                    <div
                      className={`${
                        repeatabilityToggle ? 'd-none form-group' : 'd-block form-group'
                      } ps-5`}
                      key='wt7'
                    >
                      <label htmlFor='wt7'>Weight 7</label>
                      <Field name='wt7' type='number' />
                      {touched.wt7 && errors.wt7 ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.wt7}</div>
                        </div>
                      ) : null}
                    </div>
                    <div
                      className={`${
                        repeatabilityToggle ? 'd-none form-group' : 'd-block form-group'
                      } ps-5`}
                      key='wt8'
                    >
                      <label htmlFor='wt8'>Weight 8</label>
                      <Field name='wt8' type='number' />
                      {touched.wt8 && errors.wt8 ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.wt8}</div>
                        </div>
                      ) : null}
                    </div>
                    <div
                      className={`${
                        repeatabilityToggle ? 'd-none form-group' : 'd-block form-group'
                      } ps-5`}
                      key='wt9'
                    >
                      <label htmlFor='wt9'>Weight 9</label>
                      <Field name='wt9' type='number' />
                      {touched.wt9 && errors.wt9 ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.wt9}</div>
                        </div>
                      ) : null}
                    </div>
                    {/* Repeat for each weight from wt3 to wt10 */}
                    <div
                      className={`${
                        repeatabilityToggle ? 'd-none form-group' : 'd-block form-group'
                      } ps-5`}
                      key='wt10'
                    >
                      <label htmlFor='wt10'>Weight 10</label>
                      <Field name='wt10' type='number' />
                      {touched.wt10 && errors.wt10 ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.wt10}</div>
                        </div>
                      ) : null}
                    </div>
                  </>
                )}
              {/* Mass Value */}
              <div className='d-flex' onClick={() => setMassValue(!massValue)}>
                <h3>Mass Values<QuestionTooltip hoverText="This will set the 6 or 4 mass values in the performance checks section of the report. The way this happens on the backend is as follows: If the report is accredited, there will be values 1-6. If the report is unaccredited, it will use values 3-6, but they are actually displayed as values 1-4." /></h3>
                <KTSVG
                  path={`/media/icons/duotone/Navigation/${
                    massValue ? 'Angle-up' : 'Angle-down'
                  }.svg`}
                  className='svg-icon-1'
                />
              </div>
              {values.ynA2LACalRep == '1' && (
                <>
                  <div
                    className={`${massValue ? 'd-none form-group' : 'd-block form-group'}`}
                    key='WU1'
                  >
                    <label htmlFor='WU1'>Mass Value 1</label>
                    <Field name='WU1' type='number' />
                  </div>
                  <div
                    className={`${massValue ? 'd-none form-group' : 'd-block form-group'}`}
                    key='WU2'
                  >
                    <label htmlFor='WU2'>Mass Value 2</label>
                    <Field name='WU2' type='number' />
                  </div>
                </>
              )}
              <div
                className={`${massValue ? 'd-none form-group' : 'd-block form-group'}`}
                key='WU3'
              >
                <label htmlFor='WU3'>
                  {values.ynA2LACalRep == '1' ? 'Mass Value 3' : 'Mass Value 1'}
                </label>
                <Field name='WU3' type='number' />
              </div>
              <div
                className={`${massValue ? 'd-none form-group' : 'd-block form-group'}`}
                key='WU4'
              >
                <label htmlFor='WU4'>
                  {values.ynA2LACalRep == '1' ? 'Mass Value 4' : 'Mass Value 2'}
                </label>
                <Field name='WU4' type='number' />
              </div>
              <div
                className={`${massValue ? 'd-none form-group' : 'd-block form-group'}`}
                key='WU5'
              >
                <label htmlFor='wU5'>
                  {values.ynA2LACalRep == '1' ? 'Mass Value 5' : 'Mass Value 3'}
                </label>
                <Field name='WU5' type='number' />
              </div>
              <div
                className={`${massValue ? 'd-none form-group' : 'd-block form-group'}`}
                key='WU6'
              >
                <label htmlFor='WU6'>
                  {values.ynA2LACalRep == '1' ? 'Mass Value 6' : 'Mass Value 4'}
                </label>
                <Field name='WU6' type='number' />
              </div>

              {/* Performance checks found section */}
              <div
                className='d-flex'
                onClick={() => setPerformanceChecksFound(!performanceChecksFound)}
              >
                <h3>Performance Checks Found<QuestionTooltip hoverText="This will set the 6 or 4 'as found' values in the performance checks section of the report. The process on the backend is as follows: If the report is accredited, there will be values 1-6. If the report is unaccredited, it will use values 3-6, but they will actually be displayed as values 1-4. This will trigger an 'in tolerance yes or no' directly next to 'as-found' if the value is out of tolerance. However, it will not affect the 'in tolerance yes or no' on the right. This represents the value that the technician found upon their initial assessment." /></h3>
                <KTSVG
                  path={`/media/icons/duotone/Navigation/${
                    performanceChecksFound ? 'Angle-up' : 'Angle-down'
                  }.svg`}
                  className='svg-icon-1'
                />
              </div>
              {values.ynA2LACalRep == '1' && (
                <>
                  <div
                    className={`${
                      performanceChecksFound ? 'd-none form-group' : 'd-block form-group'
                    }`}
                    key='wtF1'
                  >
                    <label htmlFor='wtF1'>Weight Found 1</label>
                    <Field name='wtF1' type='text' />
                  </div>
                  <div
                    className={`${
                      performanceChecksFound ? 'd-none form-group' : 'd-block form-group'
                    }`}
                    key='wtF2'
                  >
                    <label htmlFor='wtF2'>Weight Found 2</label>
                    <Field name='wtF2' type='text' />
                  </div>
                </>
              )}
              <div
                className={`${performanceChecksFound ? 'd-none form-group' : 'd-block form-group'}`}
                key='wtF3'
              >
                <label htmlFor='wtF3'>
                  {values.ynA2LACalRep == '1' ? 'Weight Found 3' : 'Weight Found 1'}
                </label>
                <Field name='wtF3' type='text' />
              </div>
              <div
                className={`${performanceChecksFound ? 'd-none form-group' : 'd-block form-group'}`}
                key='wtF4'
              >
                <label htmlFor='wtF4'>
                  {values.ynA2LACalRep == '1' ? 'Weight Found 4' : 'Weight Found 2'}
                </label>
                <Field name='wtF4' type='text' />
              </div>
              <div
                className={`${performanceChecksFound ? 'd-none form-group' : 'd-block form-group'}`}
                key='wtF5'
              >
                <label htmlFor='wtF5'>
                  {values.ynA2LACalRep == '1' ? 'Weight Found 5' : 'Weight Found 3'}
                </label>
                <Field name='wtF5' type='text' />
              </div>
              <div
                className={`${performanceChecksFound ? 'd-none form-group' : 'd-block form-group'}`}
                key='wtF6'
              >
                <label htmlFor='wtF6'>
                  {values.ynA2LACalRep == '1' ? 'Weight Found 6' : 'Weight Found 4'}
                </label>
                <Field name='wtF6' type='text' />
              </div>
              {/* Performance Checks left section */}
              <div
                className='d-flex'
                onClick={() => setPerformanceChecksLeft(!performanceChecksLeft)}
              >
                <h3>Performance Checks Left<QuestionTooltip hoverText="This will set the 6 or 4 'as left' values in the performance checks section of the report. The process on the backend is as follows: If the report is accredited, there will be values 1-6. If the report is unaccredited, it will use values 3-6, but they will actually be displayed as values 1-4. This indicates how the technician left the equipment; so, if it is out of tolerance, it will show 'in tolerance: no' on the right side of the report." /></h3>
                <KTSVG
                  path={`/media/icons/duotone/Navigation/${
                    performanceChecksLeft ? 'Angle-up' : 'Angle-down'
                  }.svg`}
                  className='svg-icon-1'
                />
              </div>
              {values.ynA2LACalRep == '1' && (
                <>
                  <div
                    className={`${
                      performanceChecksLeft ? 'd-none form-group' : 'd-block form-group'
                    }`}
                    key='wtL1'
                  >
                    <label htmlFor='wtL1'>Weight Left 1</label>
                    <Field name='wtL1' type='text' />
                  </div>
                  <div
                    className={`${
                      performanceChecksLeft ? 'd-none form-group' : 'd-block form-group'
                    }`}
                    key='wtL2'
                  >
                    <label htmlFor='wtL2'>Weight Left 2</label>
                    <Field name='wtL2' type='text' />
                  </div>
                </>
              )}
              <div
                className={`${performanceChecksLeft ? 'd-none form-group' : 'd-block form-group'}`}
                key='wtL3'
              >
                <label htmlFor='wtL3'>
                  {values.ynA2LACalRep == '1' ? 'Weight Left 3' : 'Weight Left 1'}
                </label>
                <Field name='wtL3' type='text' />
              </div>
              <div
                className={`${performanceChecksLeft ? 'd-none form-group' : 'd-block form-group'}`}
                key='wtL4'
              >
                <label htmlFor='wtL4'>
                  {values.ynA2LACalRep == '1' ? 'Weight Left 4' : 'Weight Left 2'}
                </label>
                <Field name='wtL4' type='text' />
              </div>
              <div
                className={`${performanceChecksLeft ? 'd-none form-group' : 'd-block form-group'}`}
                key='wtL5'
              >
                <label htmlFor='wtL5'>
                  {values.ynA2LACalRep == '1' ? 'Weight Left 5' : 'Weight Left 3'}
                </label>
                <Field name='wtL5' type='text' />
              </div>
              <div
                className={`${performanceChecksLeft ? 'd-none form-group' : 'd-block form-group'}`}
                key='wtL6'
              >
                <label htmlFor='wtL6'>
                  {values.ynA2LACalRep == '1' ? 'Weight Left 6' : 'Weight Left 4'}
                </label>
                <Field name='wtL6' type='text' />
              </div>
              {/* Tolerance Section */}
              <div className='d-flex' onClick={() => setTolerance(!tolerance)}>
                <h3>Tolerance<QuestionTooltip hoverText="This will set the 6 or 4 tolerance values in the performance checks section of the report. The process on the backend is as follows: If the report is accredited, there will be values 1-6. If the report is unaccredited, it will use values 3-6, but they will actually be displayed as values 1-4. This affects the 'in tolerance yes/no' for 'as found' and 'as left'. If the value falls out of tolerance, it will show 'in tolerance: no'." /></h3>
                <KTSVG
                  path={`/media/icons/duotone/Navigation/${
                    tolerance ? 'Angle-up' : 'Angle-down'
                  }.svg`}
                  className='svg-icon-1'
                />
              </div>

              <div className={`${tolerance ? 'd-none' : 'd-block'}`}>
                {values.ynA2LACalRep == '1' && (
                  <>
                    <div className='form-group'>
                      <label htmlFor='tOL1'>Tolerance 1</label>
                      <Field name='tOL1' type='text' />
                      {touched.tOL1 && errors.tOL1 ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.tOL1}</div>
                        </div>
                      ) : null}
                    </div>
                    <div className='form-group'>
                      <label htmlFor='tOL2'>Tolerance 2</label>
                      <Field name='tOL2' type='text' />
                      {touched.tOL2 && errors.tOL2 ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.tOL2}</div>
                        </div>
                      ) : null}
                    </div>
                  </>
                )}
                <div className='form-group'>
                  <label htmlFor='tOL3'>
                    {values.ynA2LACalRep == '1' ? 'Tolerance 3' : 'Tolerance 1'}
                  </label>
                  <Field name='tOL3' type='text' />
                  {touched.tOL3 && errors.tOL3 ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.tOL3}</div>
                    </div>
                  ) : null}
                </div>
                <div className='form-group'>
                  <label htmlFor='tOL4'>
                    {values.ynA2LACalRep == '1' ? 'Tolerance 4' : 'Tolerance 2'}
                  </label>
                  <Field name='tOL4' type='text' />
                  {touched.tOL4 && errors.tOL4 ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.tOL4}</div>
                    </div>
                  ) : null}
                </div>
                <div className='form-group'>
                  <label htmlFor='tOL5'>
                    {values.ynA2LACalRep == '1' ? 'Tolerance 5' : 'Tolerance 3'}
                  </label>
                  <Field name='tOL5' type='text' />
                  {touched.tOL5 && errors.tOL5 ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.tOL5}</div>
                    </div>
                  ) : null}
                </div>
                <div className='form-group'>
                  <label htmlFor='tOL6'>
                    {values.ynA2LACalRep == '1' ? 'Tolerance 6' : 'Tolerance 4'}
                  </label>
                  <Field name='tOL6' type='text' />
                  {touched.tOL6 && errors.tOL6 ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.tOL6}</div>
                    </div>
                  ) : null}
                </div>
              </div>

              {/* Eccentric Load As Found*/}
              <div
                className='d-flex'
                onClick={() => setEccentricLoadAsFound(!eccentricLoadAsFound)}
              >
                <h3>Eccentric Load As Found<QuestionTooltip hoverText="If the eccentric load mass value is set, this will be displayed. It will affect quadrants 1-4 of 'as found'. Note that the tolerance for accredited reports comes from performance checks #4 and for unaccredited reports from #2. If these values are out of tolerance, it will affect the two 'in-tolerance' boxes next to 'as found'. They can either be a number or NULL." /></h3>
                <KTSVG
                  path={`/media/icons/duotone/Navigation/${
                    eccentricLoadAsFound ? 'Angle-up' : 'Angle-down'
                  }.svg`}
                  className='svg-icon-1'
                />
              </div>
              <div
                className={`${
                  eccentricLoadAsFound || isNaN(+values.CornerLoadValue) ? 'd-none' : 'd-block'
                }`}
              >
                <div className='form-group'>
                  <label htmlFor='CornerLoadLeft'>Quadrant 1</label>
                  <Field name='CornerLoadLeft' type='text' />
                  {touched.CornerLoadLeft && errors.CornerLoadLeft ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.CornerLoadLeft}</div>
                    </div>
                  ) : null}
                </div>
                <div className='form-group'>
                  <label htmlFor='CornerLoadFront'>Quadrant 2</label>
                  <Field name='CornerLoadFront' type='text' />
                  {touched.CornerLoadFront && errors.CornerLoadFront ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.CornerLoadFront}</div>
                    </div>
                  ) : null}
                </div>
                <div className='form-group'>
                  <label htmlFor='CornerLoadBack'>Quadrant 3</label>
                  <Field name='CornerLoadBack' type='text' />
                  {touched.CornerLoadBack && errors.CornerLoadBack ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.CornerLoadBack}</div>
                    </div>
                  ) : null}
                </div>
                <div className='form-group'>
                  <label htmlFor='CornerLoadRight'>Quadrant 4</label>
                  <Field name='CornerLoadRight' type='text' />
                  {touched.CornerLoadRight && errors.CornerLoadRight ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.CornerLoadRight}</div>
                    </div>
                  ) : null}
                </div>
              </div>

              {/* Eccentric Load As Left*/}
              <div className='d-flex' onClick={() => setEccentricLoadAsLeft(!eccentricLoadAsLeft)}>
                <h3>Eccentric Load As Left<QuestionTooltip hoverText="If the eccentric load mass value is set, this will be displayed. It will affect quadrants 1-4 of 'as left'. Note that the tolerance for accredited reports comes from performance check #4 and for unaccredited reports from #2. If these values are out of tolerance, it will affect the 'in tolerance' indicator on the right. They can either be a number or NULL." /></h3>
                <KTSVG
                  path={`/media/icons/duotone/Navigation/${
                    eccentricLoadAsLeft ? 'Angle-up' : 'Angle-down'
                  }.svg`}
                  className='svg-icon-1'
                />
              </div>
              <div
                className={`${
                  eccentricLoadAsLeft || isNaN(+values.CornerLoadValue) ? 'd-none' : 'd-block'
                }`}
              >
                <div className='form-group'>
                  <label htmlFor='CornerLoadLeftAsLeft'>Quadrant 1</label>
                  <Field name='CornerLoadLeftAsLeft' type='text' />
                  {touched.CornerLoadLeftAsLeft && errors.CornerLoadLeftAsLeft ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.CornerLoadLeftAsLeft}</div>
                    </div>
                  ) : null}
                </div>
                <div className='form-group'>
                  <label htmlFor='CornerLoadFrontAsLeft'>Quadrant 2</label>
                  <Field name='CornerLoadFrontAsLeft' type='text' />
                  {touched.CornerLoadFrontAsLeft && errors.CornerLoadFrontAsLeft ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.CornerLoadFrontAsLeft}</div>
                    </div>
                  ) : null}
                </div>
                <div className='form-group'>
                  <label htmlFor='CornerLoadBackAsLeft'>Quadrant 3</label>
                  <Field name='CornerLoadBackAsLeft' type='text' />
                  {touched.CornerLoadBackAsLeft && errors.CornerLoadBackAsLeft ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.CornerLoadBackAsLeft}</div>
                    </div>
                  ) : null}
                </div>
                <div className='form-group'>
                  <label htmlFor='CornerLoadRightAsLeft'>Quadrant 4</label>
                  <Field name='CornerLoadRightAsLeft' type='text' />
                  {touched.CornerLoadRightAsLeft && errors.CornerLoadRightAsLeft ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.CornerLoadRightAsLeft}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              <h3>How eccentric in tolerance works<QuestionTooltip hoverText="To determine if an eccentric load is within tolerance, multiply the Division value by the Eccentric Load value and compare the result to the decimal part of the
              2nd tolerance for non-A2LA or the 4th tolerance for A2LA. If the absolute value of the product is larger than the decimal part of the tolerance value, it is out of tolerance; if smaller, it is within tolerance. 
              This method was verified by Jacob." /></h3>
            </Modal.Body>
            <Modal.Footer>
              <button
                type='button'
                className='btn btn-secondary'
                onClick={() => handleEditReport(1, false)}
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-secondary'
                onClick={() =>
                  pdfAction({
                    IdShipping: initialValues.IdShipping,
                    IdEquipment: initialValues.IdEquipment,
                    ReportNo: initialValues.ReportNo,
                  })
                }
              >
                PDF
              </button>

              <button
                type='submit'
                className={`btn ${submit ? 'btn-light' : 'btn-primary'}`}
                onClick={() => scrollToFirstError(errors, touched)}
              >
                Submit
                {submit && (
                  <div className='spinner-border w-17px h-17px ms-2 text-primary' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                )}
              </button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export { EditAccreditedReportModal }
