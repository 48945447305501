import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
type Props = {
    columns?: (string | number)[],
    rows: (string | number | undefined | null)[]

}

const PdfTable = ({ columns, rows }: Props) => {

    const createTableHeader = () => {
        return (
            <View style={styles.tableRowStyle} fixed>
                {
                    columns?.map((item, index) => (
                        <View style={styles.firstTableColHeaderStyle} key={index}>
                            <Text style={styles.tableCellHeaderStyle}>{item}</Text>
                        </View>
                    ))
                }


            </View>
        );
    };

    const createTableRow = () => {
        return (
            <View style={styles.tableRowStyle} >
                {
                    rows?.map((item, index) => (
                        <View style={styles.firstTableColStyle} key={index}>
                            <Text style={styles.tableCellStyle}>{item === null ? "null" : item}</Text>
                        </View>
                    ))
                }
            </View>
        );
    };

    return (


        <View style={styles.tableStyle}>
            {createTableHeader()}
            {createTableRow()}
            {/* {createTableRow()}
            {createTableRow()}
            {createTableRow()}
            {createTableRow()} */}
        </View>


    );

};
const styles = StyleSheet.create({

    pageStyle: {
        paddingTop: 16,
        paddingHorizontal: 40,
        paddingBottom: 56
    },

    tableStyle: {
        display: "flex",
        width: "100%"
    },

    tableRowStyle: {
        flexDirection: "row",
        display: "flex",
        alignItems: 'stretch'
    },

    firstTableColHeaderStyle: {
        // width: "20%",
        flexGrow: 1,
        borderStyle: "solid",
        borderColor: "#000",
        borderBottomColor: "#000",
        borderWidth: 1,
        backgroundColor: "#bdbdbd"
    },

    tableColHeaderStyle: {
        width: "20%",
        borderStyle: "solid",
        borderColor: "#000",
        borderBottomColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 0,
        backgroundColor: "#bdbdbd"
    },

    firstTableColStyle: {
        // maxWidth: "20%",
        borderStyle: "solid",
        borderColor: "#000",
        borderWidth: 1,
        flexGrow: 1
        // flex: 1
        // width: 'auto',
        // display: 'flex'

    },

    tableColStyle: {
        width: "20%",
        borderStyle: "solid",
        borderColor: "#000",
        borderWidth: 1,
        borderLeftWidth: 0,
    },

    tableCellHeaderStyle: {
        textAlign: "center",
        margin: 4,
        fontSize: 12,
        fontWeight: "bold"
    },

    tableCellStyle: {
        textAlign: "center",
        margin: 2,
        fontSize: 8,
        display: 'flex'
    },
})

export default PdfTable;
