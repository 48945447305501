import axios from 'axios'

export const ASSET_MANAGEMENT_URL = `${process.env.REACT_APP_API_URL}/asset-management` 
//First part is the API url in the frontend.env file. 
//  
export const TERRITORY_MAP_URL = `${process.env.REACT_APP_API_URL}/territory-map` //The location of my controller in the api.php

export function getCustomerData() {
    console.log("in")
    return axios.get(`${ASSET_MANAGEMENT_URL}/getCustomerData`)
}

export function getAllCustomerLocationUpdate() {
    console.log("in")
    return axios.get(`${TERRITORY_MAP_URL}/getAllCustomerLocationUpdate`) //This specifies and calls the function I wish to run on the backend. 
}

export function getValidCustomerShippingData(searchFilter: any) { // going into here
    console.log("in")
    return axios.get(`${TERRITORY_MAP_URL}/getValidCustomerShippingData`, { params: searchFilter })
}

export function getHeatmapInfo(searchFilter: any) //Grabs all the data for the heat map
{
    console.log("in")
    return axios.get(`${TERRITORY_MAP_URL}/getHeatmapInfo`, { params: searchFilter })
}

export function getRoutingInfo(routingInfo: any) //Grabs all the data for the heat map
{
    console.log("in")
    return axios.get(`${TERRITORY_MAP_URL}/getRoutingInfo`, { params: routingInfo })
}

export function getAllCustomerIds() {
    return axios.get(`${process.env.REACT_APP_API_URL}/customers/ids`)
}