import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { UserModel } from '../../../../types'
import { RootState } from '../../../../setup'
import { isNBS, isCustomerTechnician } from '../../../../helper/level.helper'

export function AsideMenuMain() {
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [isTechnician, setIsTechnician] = useState<boolean>(false)

  const intl = useIntl()
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  useEffect(() => {
    setIsAdmin(isNBS(user?.type))
    setIsTechnician(isCustomerTechnician(user?.level))
  }, [user])

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            Calibration Reports Center
          </span>
        </div>
      </div>
      <div className='menu-item'>
        <div className='menu-content pt-4 pb-2'>
          <span className='menu-section text-muted ps-4 fs-8 d-block text-uppercase'>
            Check both locations for reports
          </span>
        </div>
      </div>

      {user.customerIds?.length && user.customerIds.length > 0 ? (
        // <AsideMenuItemWithSub
        //   to='#'
        //   title='Balance & Scale Reports'
        //   icon='/media/icons/duotone/General/User.svg'
        // >
        //   {user.customerIds?.map((item, index) => (
        //     <AsideMenuItem
        //       key={index}
        //       to='#'
        //       icon='/media/icons/duotone/Design/PenAndRuller.svg'
        //       data={item}
        //       title={`${item.customerId}-${item.companyName}`}
        //       fontIcon='bi-app-indicator'
        //     />
        //   ))}
        // </AsideMenuItemWithSub>
        <>
          <AsideMenuItem
            to='/accredit-reports'
            icon='/media/icons/duotone/Design/PenAndRuller.svg'
            title='Balance & Scale Reports'
            fontIcon='bi-app-indicator'
          />
        </>
      ) : (
        <>
        {/*
        <AsideMenuItem
        to='/all-reports'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title='Download Center'
        fontIcon='bi-app-indicator'
        />
        */}
        
        <AsideMenuItem
            to='/accredit-reports'
            icon='/media/icons/duotone/Design/PenAndRuller.svg'
            title='Balance & Scale Reports'
            fontIcon='bi-app-indicator'
          />
        </>
      )}
      {/* <AsideMenuItem
        to='/other-cal-reports'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title='Other Calibration Reports'
        fontIcon='bi-app-indicator'
      /> */}
      {/* <AsideMenuItem
        to='/accredit-reports'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title='New Balance & Scale Reports'
        fontIcon='bi-app-indicator'
      /> */}
      <AsideMenuItem
        to='/calibration-reports'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title='Laboratory Equipment Reports'
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/equipment-reports'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title='Other Reports'
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Work Orders</span>
        </div>
      </div>
      <AsideMenuItem
        to='/service-quotes'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title='Service Quotes'
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/sales-quotes'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title='Sales Quotes'
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/sales-orders'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title='Sales Orders'
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/service-orders'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        title='Service Orders'
        fontIcon='bi-app-indicator'
      />
      {(isAdmin || isTechnician) && (
      <>
      <AsideMenuItem
        to='/completed-orders-page'
        title='Orders to be Paid'
        icon='/media/icons/duotone/Design/PenAndRuller.svg'
        fontIcon='bi-person'
        
      />
        </>
      )}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Profile</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotone/General/User.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem
          to='/crafted/account/assigned-customer-ids'
          title='Customer IDs'
          hasBullet={true}
        />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            Customer Management
          </span>
        </div>
      </div>
      {(isAdmin || isTechnician) && (
        <>
          <AsideMenuItem
            to='/nbs-billing-companies'
            title='Billing Company'
            icon='/media/icons/duotone/General/Bank.svg'
          />
        </>
      )}
      <AsideMenuItem
        to='/nbs-customers'
        title='Customers'
        icon='/media/icons/duotone/General/User.svg'
        fontIcon='bi-person'
      />
      <AsideMenuItem
        to='/asset-management'
        title='Assets'
        icon='/media/icons/duotone/General/Shield-protected.svg'
        fontIcon='bi-person'
      />
        {isAdmin && (
        <>
        <AsideMenuItem
        to='/territory-map'
        title='Territory Map'
        icon='/media/icons/duotone/General/binocular.svg'
        fontIcon='bi-map'
        /> 
        </>
      )}
      {isAdmin && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                Admin Configuration Setting
              </span>
            </div>
          </div>
          <AsideMenuItemWithSub
            to='/admin-configuration-setting'
            title='Receive Notifications and Alerts'
            icon='/media/icons/duotone/General/User.svg'
            fontIcon='bi-person'
          >
            <AsideMenuItem
              to='/admin-configuration-setting/receive-service'
              icon='/media/icons/duotone/Design/PenAndRuller.svg'
              title='Receive Service Quote Approvals'
            />
            <AsideMenuItem
              to='/admin-configuration-setting/receive-sales'
              icon='/media/icons/duotone/Design/PenAndRuller.svg'
              title='Receive Sales Quote Approvals'
            />
            <AsideMenuItem
              to='/admin-configuration-setting/receive-asset'
              icon='/media/icons/duotone/Design/PenAndRuller.svg'
              title='Receive Asset Updates'
            />
            <AsideMenuItem
              to='/admin-configuration-setting/receive-new-service'
              icon='/media/icons/duotone/Design/PenAndRuller.svg'
              title='Receive New Service Requests'
            />
            <AsideMenuItem
              to='/admin-configuration-setting/receive-get-service'
              icon='/media/icons/duotone/Design/PenAndRuller.svg'
              title='Receive Contact requests'
            />
            <AsideMenuItem
              to='/admin-configuration-setting/receive-contactus'
              icon='/media/icons/duotone/Design/PenAndRuller.svg'
              title='Receive Contact Us Requests'
            />
            <AsideMenuItem
              to='/admin-configuration-setting/receive-registration-alerts'
              icon='/media/icons/duotone/Design/PenAndRuller.svg'
              title='Receive Registration Alerts'
            />
          </AsideMenuItemWithSub>

          <AsideMenuItem
            to='/customer-friendly-status-codes'
            title='Manage Customer Friendly Status Codes'
            icon='/media/icons/duotone/Design/PenAndRuller.svg'
            fontIcon='bi-person'
          />


          <div className='menu-item'>
            <div className='menu-content'>
              <div className='separator mx-1 my-4'></div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
