import clsx from 'clsx'
import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import {useLayout} from '../../../core/LayoutProvider'
import {usePageData} from '../../../core/PageData'
import {connect} from 'react-redux'
import {RootState} from '../../../../../setup'
import * as auth from '../../../../../app/modules/auth/redux/AuthRedux'
import CustomerIdSelect from './CustomerIdSelect'

const DefaultTitleComponent: FC = (props: any) => {
  const {pageTitle, pageDescription, pageBreadcrumbs} = usePageData()
  const {config, attributes, classes} = useLayout()

  return (
    <div
      {...attributes.pageTitle}
      className={clsx('page-title d-flex', classes.pageTitle.join(' '))}
    >
      {/* begin::Title */}
      {pageTitle && (
        <h1 className='d-flex align-items-center text-dark fw-bolder my-1 fs-3 ml-3 me-3'>
          {pageTitle}
          {pageDescription && config.pageTitle && config.pageTitle.description && (
            <>
              <span className='h-20px border-gray-200 border-start ms-3'></span>
              <small className='text-muted fs-7 fw-bold my-1 ms-1'>{pageDescription}</small>
            </>
          )}
        </h1>
      )}
      {/* end::Title */}

      {/* begin::Customer ID select */}
      {props.customerIds && props.customerIds.length && (
        <CustomerIdSelect
          options={props.customerIds}
          value={props.activeCustomerIds}
          onChange={(selected) => {
            props.setActiveCustomerIds(selected)
          }}
        />
      )}
      {/* end::Customer ID select */}

      {pageBreadcrumbs &&
        pageBreadcrumbs.length > 0 &&
        config.pageTitle &&
        config.pageTitle.breadCrumbs && (
          <>
            {config.pageTitle.direction === 'row' && (
              <span className='h-20px border-gray-200 border-start mx-4'></span>
            )}
            <ul className='breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1'>
              {Array.from(pageBreadcrumbs).map((item, index) => (
                <li
                  className={clsx('breadcrumb-item', {
                    'text-dark': !item.isSeparator && item.isActive,
                    'text-muted': !item.isSeparator && !item.isActive,
                  })}
                  key={`${item.path}${index}`}
                >
                  {!item.isSeparator ? (
                    <Link className='text-muted text-hover-primary' to={item.path}>
                      {item.title}
                    </Link>
                  ) : (
                    <span className='bullet bg-gray-200 w-5px h-2px'></span>
                  )}
                </li>
              ))}
              <li className='breadcrumb-item text-dark'>{pageTitle}</li>
            </ul>
          </>
        )}
    </div>
  )
}

const mapState = (state: RootState) => ({
  activeCustomerIds: state.auth?.activeCustomerIds,
  customerIds: state.auth?.user?.customerIds,
})
const connector = connect(mapState, {setActiveCustomerIds: auth.actions.setActiveCustomerIds})
const DefaultTitle = connector(DefaultTitleComponent)

export {DefaultTitle}
