import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import { updateCustomer } from '../CustomersCRUD'
import { Modal } from 'react-bootstrap-v5'
import { KTSVG } from '../../../../_metronic/helpers'
import { Formik, Form, Field } from 'formik'
import { SelectDropDown } from '../../../components/SelectDropDown'
import { CustomerModel } from '../../../../types'
import { STATES } from '../../../../data/constants'
import { CustomDropDown } from './CustomDropDown'
import { getBillingCompanyWithId } from '../../billing-companies/BillingCompanyCRUD'
import { Checkbox, FormControlLabel, LinearProgress } from '@material-ui/core'
import { Typography } from 'antd'
import ContactsPage from '../../contacts/ContactsPage'
import { useSelector } from 'react-redux'
import { BillingCompanyModal } from '../../billing-companies/component/BillingCompanyModal'
import { BillingCompany } from '../../../../types'
import { getBillingCompanyID } from '../../billing-companies/BillingCompanyCRUD'
import TipTapEditor from '../../../components/TiptapEditor/TipTapEditor'
type Props = {
  show: boolean
  customer: CustomerModel
  onHide: any
  userId: number | undefined
  getCustomers: () => void
  fetchBillingCompanies : () => void
  billingCompanies: BillingCompany[]
}

const CustomerEditSchema = Yup.object().shape({
  companyId: Yup.string().required('Company ID is required'),
})

const EditCustomerModal: React.FC<Props> = (props) => {
  const initialBillingCompany = useMemo(() => {
    return {
      CompanyName: '',
      BillingCompanyID: '',
      BillAttn_email: '',
      BillPhone: '',
      BillFax: '',
      BillAddress: '',
      BillAddress2: '',
      BillCity: '',
      BillState: '',
      BillZip: '',
      BillNotes: '',
      taxExempt: 'No',
      netTerms: 30,
      expirationDate: '',
    }
  }, [])
  const { show, customer, onHide, getCustomers, billingCompanies } = props
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowBillingCompanyModal, setIsShowBillingCompanyModal] = useState(false);

  // Function to open the modal and fetch data 
      const openBillingCompanyModal = () => {
        getBillingCompanyID()
          .then((res) => {
            console.log(res.data)
            setCurrentBillingCompany({
              ...initialBillingCompany,
              BillingCompanyID: res.data,// Merge fetched data with initial data
            });
            console.log(currentBillingCompany)
            setIsShowBillingCompanyModal(true); // Open the modal
          })
          .catch((err) => {
            console.error(err);
            // Handle error (e.g., show notification)a
          });
      };
  const handleCloseModal = useCallback((isShowConfirm: boolean | undefined) => {
    if (isShowConfirm) {
      setIsShowConfirmModal(true)
    } else {
      setIsShowModal(false)
    }
  }, [])
  const handleCloseBillingCompanyModal = useCallback(() => {
    // Closes the billing modal
    setIsShowBillingCompanyModal(false);
   }, []);
  const [currentBillingCompany, setCurrentBillingCompany] =
    useState<BillingCompany>(initialBillingCompany)
  const initialValues = useMemo(() => {
    return {
      ...customer,
      taxExempt: customer?.taxExempt === 1 ? 'Yes' : 'No',
      netTerms: customer?.netTerms ?? 30,
      ynFaxEmailOK: Number(customer?.ynFaxEmailOK) == 1 ? true : false,
      ynMailOK: Number(customer?.ynMailOK) == 1 ? true : false,
    }
  }, [customer])

  const [submit, setSubmit] = useState<boolean>(false)
  const [shipToggle, setShipToggle] = useState<boolean>(false)
  const [billToggle, setBillToggle] = useState<boolean>(false)
  const [contactToggle, setContactToggle] = useState<boolean>(false)
  const [billState, setBillState] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [companyIds, setCompanyIds] = useState<any>([])
  const [check, setCheck] = useState<boolean>(false)


  const [add, setAdd] = useState<boolean>(false)
  const { nbsContacts } = useSelector(({ nbsContacts }: any) => nbsContacts)
  // const [billingCompanies, setBillingCompanies] = useState<any>([])
  const [isShowConfirmModal, setIsShowConfirmModal] = useState<boolean>(false)
  const [isShowConfirmModal2, setIsShowConfirmModal2] = useState<boolean>(false)
  const [tempBillingId, setTempBillingId] = useState<string>('');
  const handleChangeCompanyId = (BillingCompanyID: string, setFieldValue: any) => {
    // const billingCompany: any = billingCompanies.find(
    //   (company: BillingCompany) => company.BillingCompanyID === BillingCompanyID
    // )
    setLoading(true)
    getBillingCompanyWithId(BillingCompanyID)
      .then((res) => {
        setTempBillingId(BillingCompanyID)
        setFieldValue("companyId",BillingCompanyID)
        setFieldValue('billingCompanyName', res.data.CompanyName ?? '')
        setFieldValue('billPhoneNumber', res.data.BillPhone)
        setFieldValue('billFaxNumber', res.data.BillFax)
        setFieldValue('billAddress', res.data.BillAddress)
        setFieldValue('billAddress2', res.data.BillAddress2)
        setFieldValue('billCity', res.data.BillCity)
        setFieldValue('billZip', res.data.BillZip)
        // setFieldValue('billNotes', res.data.BillNotes)
        setFieldValue('billContact', res.data.BillAttn_email)
        setFieldValue('billState', res.data.BillState)
        setBillState(res.data.BillState)
        setLoading(false)
        if (check) {
          setFieldValue('shipCompanyName', res.data.CompanyName)
          // setFieldValue('contactFirstName', values.billContact)
          // setFieldValue('contactLastName', values.billContact)
          setFieldValue('shipPhoneNumber', res.data.BillPhone)
          setFieldValue('shipFaxNumber', res.data.BillFax)
          setFieldValue('shipAddress', res.data.BillAddress)
          setFieldValue('shipAddress2', res.data.BillAddress2)
          setFieldValue('shipCity', res.data.BillCity)
          setFieldValue('shipState', res.data.BillState)
          setFieldValue('shipZip', res.data.BillZip)
          // setFieldValue('shipNotes', res.data.BillNotes)
        }
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  const handleNew = (state: boolean) => {
    setAdd(state)
  }

  const handleSubmit = (values: any) => {
    setSubmit(true);
  
    updateCustomer(customer.dbkey, values).then((res) => {
      if(values.shipNotes && values.shipNotes.length >= 1000)
        {
          toast.error("The text in Shipping Notes is too long and has been shortened! Ensure you have required data in the field!")
        }
      toast.success(res.data.message);
      getCustomers();
      onHide(false); // This is a synchronous call
      //setSubmit(false); // This will run immediately after onHide(false)
    }).catch((error) => {
      // Handle any errors that occur during the updateCustomer call
      console.error("Error updating customer:", error);
      setSubmit(false); // Ensure submit is set to false in case of error
    });
  };

  useEffect(() => {
    setBillState(initialValues?.billState)
  }, [initialValues])
  useEffect(() => {
    //console.log(currentBillingCompany);
   }, [currentBillingCompany]);
   
  // Get company Ids from billing
  useEffect(() => {
    setCompanyIds(
      billingCompanies.map(({ BillingCompanyID, CompanyName }: BillingCompany) => ({
        code: BillingCompanyID,
        name: `${BillingCompanyID} - ${CompanyName}`,
      }))
    )
    initialValues.companyId = tempBillingId
  }, [billingCompanies])
  
  useEffect(() => {
    if (show) {
      setSubmit(false) // Ensure submit is false when the modal appears
    }
  }, [show])


  return (
    <Modal
      size='lg'
      show={show}
      aria-labelledby='contained-modal-title-vcenter'
      onHide={() => {
        setCheck(false)
        onHide(true)
      }}
      centered
    >
      <Modal.Header>
        <Modal.Title className='w-100' id='contained-modal-title-vcenter'>
          <div className='d-flex justify-content-between'>
            <h2>Edit Customer</h2>
            <span
              className='text-hover-primary cursor-pointer'
              onClick={() => {
                setCheck(false)
                onHide(true)
                setSubmit(false)
              }}
            >
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='pt-10 pb-15 px-lg-17'>
        <Formik
          validationSchema={CustomerEditSchema}
          onSubmit={handleSubmit}
          initialValues={initialValues}
        >
          {({ handleSubmit, setFieldValue, errors, touched, values }) => {
            const handleCheck = (e: any) => {
              setCheck(e.target.checked)
              if (e.target.checked) {
                setFieldValue('shipCompanyName', values.billingCompanyName)
                setFieldValue('shipPhoneNumber', values.billPhoneNumber)
                setFieldValue('shipFaxNumber', values.billFaxNumber)
                setFieldValue('shipAddress', values.billAddress)
                setFieldValue('shipAddress2', values.billAddress2)
                setFieldValue('shipCity', values.billCity)
                setFieldValue('shipState', values.billState)
                setFieldValue('shipZip', values.billZip)
              } else {
                setFieldValue('shipCompanyName', initialValues.shipCompanyName)
                setFieldValue('shipPhoneNumber', initialValues.shipPhoneNumber)
                setFieldValue('shipFaxNumber', initialValues.shipFaxNumber)
                setFieldValue('shipAddress', initialValues.shipAddress)
                setFieldValue('shipAddress2', initialValues.shipAddress2)
                setFieldValue('shipCity', initialValues.shipCity)
                setFieldValue('shipState', initialValues.shipState)
                setFieldValue('shipZip', initialValues.shipZip)
                setFieldValue('shipNotes', initialValues.shipNotes)
              }
            }
            return (
              <Form>
                <div className='d-flex flex-column scroll-y me-n7 pe-7 max-height-60'>
                  <div className='d-flex justify-content-between mb-7 fv-plugins-icon-container'>
                    <h5 className='fw-bold mb-2'>{`Customer ID: ${customer?.customerId}`}</h5>
                    <h5 className='fw-bold mb-2'>{`Customer Shipping ID: ${customer?.idShipping}`}</h5>
                  </div>
                  <div className='row fv-plugins-icon-container'>
                    <div className='col-lg-12 mb-7'>
                      <label className='fw-bold fs-6 required mb-2'>Assigned Billing Company ID</label>
                      <CustomDropDown
                        data={companyIds}
                        defaultValue={initialValues.companyId}
                        dirUp={false}
                        showButton={true}
                        selectedValue={(code: string) => {
                          setFieldValue('companyId', code);
                          handleChangeCompanyId(code, setFieldValue);
                        }}
                        isSearchable={true}
                        onShowButtonClick={openBillingCompanyModal} // Call the function to open the modal and fetch data
                      />
                      <BillingCompanyModal
                        isShow={isShowBillingCompanyModal}
                        onHide={handleCloseBillingCompanyModal}
                        billingCompany={currentBillingCompany} // Pass the updated state
                        billingCompanies={[]}
                        getBillingCompanies={() => { throw new Error('Function not implemented.'); }}
                        isCommingFromCustomers={true}
                        fetchBillingCompanies={props.fetchBillingCompanies}
                        handleChangeCompanyId={(companyId) => handleChangeCompanyId(companyId, setFieldValue)}
                        setCustomerFieldValue={setFieldValue}
                      />
                      <Field
                        as='input'
                        name='companyId'
                        className='form-control form-control-solid d-none'
                      />
                      {loading && <LinearProgress />}
                      {touched.companyId && errors.companyId ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.companyId}</div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='row fv-plugins-icon-container'>
                    <div className='col-lg-12 mb-7'>
                      <label className='fw-bold fs-6 mb-2'>Active</label>
                      <SelectDropDown
                        data={['active', 'inactive']}
                        defaultValue={initialValues.status}
                        dirUp={false}
                        selectedValue={(value: string | number) => setFieldValue('status', value)}
                      />
                      <Field
                        as='input'
                        name='status'
                        className='form-control form-control-solid d-none'
                      />
                      {touched.status && errors.status ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.status}</div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='col-lg-12 mb-7'>
                    <label className='fw-bold fs-6 mb-2'>Email Address</label>
                    <Field
                      as='input'
                      name='email'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                    />
                    {touched.email && errors.email ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{errors.email}</div>
                      </div>
                    ) : null}
                  </div>
                  <div className='d-flex mb-5 cursor-pointer align-items-center'>
                    <div className='d-flex' onClick={() => setShipToggle(!shipToggle)}>
                      <h3>Shipping Information</h3>
                      <KTSVG
                        path={`/media/icons/duotone/Navigation/${shipToggle ? 'Angle-up' : 'Angle-down'}.svg`}
                        className='svg-icon-1'
                      />
                    </div>
                    <FormControlLabel
                      className='ms-6'
                      control={<Checkbox color='primary' onChange={handleCheck} value={check} />}
                      label='Load from Billing Information'
                    />
                  </div>
                  <div className={`${shipToggle ? 'd-none' : 'd-block'} ps-5`}>
                    <div className='row fv-plugins-icon-container'>
                      <div className='col-lg-12 mb-7'>
                        <label className='fw-bold fs-6 mb-2'>Ship Company Name</label>
                        <Field
                          as='input'
                          name='shipCompanyName'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                        />
                        {touched.shipCompanyName && errors.shipCompanyName ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{errors.shipCompanyName}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className='row fv-plugins-icon-container'>
                      <div className='col-lg-6 mb-7'>
                        <label className='fw-bold fs-6 mb-2'>Contact First Name</label>
                        <Field
                          as='input'
                          name='contactFirstName'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                        />
                        {touched.contactFirstName && errors.contactFirstName ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{errors.contactFirstName}</div>
                          </div>
                        ) : null}
                      </div>
                      <div className='col-lg-6 mb-7'>
                        <label className='fw-bold fs-6 mb-2'>Contact Last Name</label>
                        <Field
                          as='input'
                          name='contactLastName'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                        />
                        {touched.contactLastName && errors.contactLastName ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{errors.contactLastName}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className='row fv-plugins-icon-container'>
                      <div className='col-lg-6 mb-7'>
                        <label className='fw-bold fs-6 mb-2'>Phone Number</label>
                        <Field
                          as='input'
                          name='shipPhoneNumber'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                        />
                        {touched.shipPhoneNumber && errors.shipPhoneNumber ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{errors.shipPhoneNumber}</div>
                          </div>
                        ) : null}
                      </div>
                      <div className='col-lg-6 mb-7'>
                        <label className='fw-bold fs-6 mb-2'>Fax Number</label>
                        <Field
                          as='input'
                          name='shipFaxNumber'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                        />
                        {touched.shipFaxNumber && errors.shipFaxNumber ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{errors.shipFaxNumber}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className='row fv-plugins-icon-container'>
                      <div className='col-lg-12 mb-7'>
                        <label className='fw-bold fs-6 mb-2'>Address Line1</label>
                        <Field
                          as='input'
                          name='shipAddress'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                        />
                        {touched.shipAddress && errors.shipAddress ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{errors.shipAddress}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className='row fv-plugins-icon-container'>
                      <div className='col-lg-12 mb-7'>
                        <label className='fw-bold fs-6 mb-2'>Address Line2</label>
                        <Field
                          as='input'
                          name='shipAddress2'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                        />
                        {touched.shipAddress && errors.shipAddress ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{errors.shipAddress}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className='row fv-plugins-icon-container'>
                      <div className='col-lg-12 mb-7'>
                        <label className='fw-bold fs-6 mb-2'>City</label>
                        <Field
                          as='input'
                          name='shipCity'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                        />
                        {touched.shipCity && errors.shipCity ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{errors.shipCity}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className='row fv-plugins-icon-container'>
                      <div className='col-lg-6 mb-7'>
                        <label className='fw-bold fs-6 mb-2'>State</label>
                        <CustomDropDown
                          data={STATES}
                          defaultValue={values.shipState ? values.shipState : initialValues.shipState}
                          dirUp={false}
                          selectedValue={(code: string) => {
                            setFieldValue('shipState', code)
                          }}
                        />
                        <Field
                          as='input'
                          name='shipState'
                          className='form-control form-control-solid d-none'
                        />
                        {touched.shipState && errors.shipState ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{errors.shipState}</div>
                          </div>
                        ) : null}
                      </div>
                      <div className='col-lg-6 mb-7'>
                        <label className='fw-bold fs-6 mb-2'>Zip Code</label>
                        <Field
                          as='input'
                          name='shipZip'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                        />
                        {touched.shipZip && errors.shipZip ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{errors.shipZip}</div>
                          </div>
                        ) : null}
                      </div>
                      <div className='col-lg-12 mb-5'>
                        <label className='fw-bold fs-6 mb-2'>Shipping Notes</label>
                        <TipTapEditor
                          content={values.shipNotes ?? ''}
                          onUpdate={(content) => {if (content.length <= 1000) {
                            setFieldValue('shipNotes', content)
                          } else {
                            setFieldValue('shipNotes', content.slice(0, 1000))
                            
                          }
                        }}
                          isReadOnly={false}
                        />
                        <Field
                          as='input'
                          name='shipNotes'
                          className='form-control form-control-solid mb-3 d-none'
                        />                        
                      </div>
                    </div>
                  </div>
                  <div className='d-flex mb-5 cursor-pointer'>
                    <div className='d-flex' onClick={() => setBillToggle(!billToggle)}>
                      <h3>Billing Information</h3>
                      <KTSVG
                        path={`/media/icons/duotone/Navigation/${billToggle ? 'Angle-up' : 'Angle-down'}.svg`}
                        className='svg-icon-1'
                      />
                    </div>
                  </div>
                  <div className={`${billToggle ? 'd-none' : 'd-block'} ps-5`}>
                    <div className='row fv-plugins-icon-container'>
                      <div className='col-lg-12 mb-7'>
                        <label className='fw-bold fs-6 mb-2'>Company Name</label>
                        <Field
                          as='input'
                          name='billingCompanyName'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          readOnly={true}
                        />
                        {touched.billingCompanyName && errors.billingCompanyName ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{errors.billingCompanyName}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className='row fv-plugins-icon-container'>
                      <div className='col-lg-12 mb-7'>
                        <label className='fw-bold fs-6 mb-2'>Contact Name</label>
                        <Field
                          as='input'
                          name='billContact'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          readOnly={true}
                        />
                        {touched.billContact && errors.billContact ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{errors.billContact}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className='row fv-plugins-icon-container'>
                      <div className='col-lg-6 mb-7'>
                        <label className='fw-bold fs-6 mb-2'>Phone Number</label>
                        <Field
                          as='input'
                          name='billPhoneNumber'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          readOnly={true}
                        />
                        {touched.billPhoneNumber && errors.billPhoneNumber ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{errors.billPhoneNumber}</div>
                          </div>
                        ) : null}
                      </div>
                      <div className='col-lg-6 mb-7'>
                        <label className='fw-bold fs-6 mb-2'>Fax Number</label>
                        <Field
                          as='input'
                          name='billFaxNumber'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          readOnly={true}
                        />
                        {touched.billFaxNumber && errors.billFaxNumber ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{errors.billFaxNumber}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className='row fv-plugins-icon-container'>
                      <div className='col-lg-12 mb-7'>
                        <label className='fw-bold fs-6 mb-2'>Address Line1</label>
                        <Field
                          as='input'
                          name='billAddress'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          readOnly={true}
                        />
                        {touched.billAddress && errors.billAddress ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{errors.billAddress}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className='row fv-plugins-icon-container'>
                      <div className='col-lg-12 mb-7'>
                        <label className='fw-bold fs-6 mb-2'>Address Line2</label>
                        <Field
                          as='input'
                          name='billAddress2'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          readOnly={true}
                        />
                        {touched.billAddress && errors.billAddress ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{errors.billAddress}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className='row fv-plugins-icon-container'>
                      <div className='col-lg-12 mb-7'>
                        <label className='fw-bold fs-6 mb-2'>City</label>
                        <Field
                          as='input'
                          name='billCity'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          readOnly={true}
                        />
                        {touched.billCity && errors.billCity ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{errors.billCity}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className='row fv-plugins-icon-container'>
                      <div className='col-lg-6 mb-7'>
                        <label className='fw-bold fs-6 mb-2'>State</label>
                        <CustomDropDown
                          data={STATES}
                          defaultValue={billState}
                          dirUp={true}
                          selectedValue={(code: string) => {
                            setFieldValue('billState', code)
                            setBillState(code)
                          }}
                          disable={true}
                        />
                        <Field
                          as='input'
                          name='billState'
                          className='form-control form-control-solid d-none'
                        />
                        {touched.billState && errors.billState ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{errors.billState}</div>
                          </div>
                        ) : null}
                      </div>
                      <div className='col-lg-6 mb-7'>
                        <label className='fw-bold fs-6 mb-2'>Zip Code</label>
                        <Field
                          as='input'
                          name='billZip'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                          readOnly={true}
                        />
                        {touched.billZip && errors.billZip ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{errors.billZip}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className='d-flex justify-content-between mb-5 mt-5 cursor-pointer'>
                      <div
                        className='d-flex align-items-center'
                        onClick={() => setContactToggle(!contactToggle)}
                      >
                        <h3> Contacts({nbsContacts.length})</h3>
                        <KTSVG
                          path={`/media/icons/duotone/Navigation/${contactToggle ? 'Angle-up' : 'Angle-down'}.svg`}
                          className='svg-icon-1'
                        />
                      </div>
                      <button
                        type='button'
                        className='btn btn-primary'
                        onClick={() => handleNew(true)}
                      >
                        <KTSVG
                          path='/media/icons/duotone/Navigation/Plus.svg'
                          className='svg-icon-2'
                        />
                        Add New Associate
                      </button>
                    </div>
                    <div
                      className={`${contactToggle ? 'd-none' : 'd-block'} ps-5`}
                      style={{ maxHeight: 260, overflowY: 'scroll' }}
                    >
                      <ContactsPage
                        CustomerID={values.customerId}
                        add={add}
                        handleNew={handleNew}
                      />
                    </div>
                  </div>
                </div>
                <div className='pt-15 fw-bold fs-5 mx-4'>
                  <div>
                    Updated {!!values.updator ? 'by ' + values.updator : ''} on {values.updatedAt}
                  </div>
                  <div>
                    Created {!!values.creator ? 'by ' + values.creator : ''} on {values.createdAt}
                  </div>
                </div>
                <div className='text-center pt-15'>
                  <button type='button' className='btn btn-light me-3' onClick={() => onHide(true)}>
                    Discard
                  </button>
                  <button type='submit' className='btn btn-primary' disabled={submit}>
                    Submit
                    {submit && (
                      <div className='spinner-border w-17px h-17px ms-2 text-primary' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export { EditCustomerModal }
