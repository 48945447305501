import React from 'react'
import { Dropdown } from 'react-bootstrap-v5'
import { QuoteModel } from '../../../../types'

import { IAuthState } from '../../../modules/auth'
import * as levelHelper from '../../../../helper/level.helper'
import { PersistPartial } from 'redux-persist/es/persistReducer'

type Props = {
  data: QuoteModel
  auth: IAuthState & PersistPartial
  handleReject: () => void
  handleComment: () => void
  handleApprove: () => void
  handleUpdatePayment: (data: QuoteModel) => void
}

const ActionDropDown: React.FC<Props> = ({
  data,
  auth,
  handleReject,
  handleComment,
  handleApprove,
  handleUpdatePayment,
}) => {
  return (
    <Dropdown key={data.dbkey}>
      <Dropdown.Toggle id='dropdown-basic' variant='light' size='sm'>
        Actions
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {data.confirmed === 'No' && data.status === 'Active' && (
          <Dropdown.Item
            href='#'
            onClick={handleApprove}
            disabled={levelHelper.isOnlyViewable(auth.user?.level)}
          >
            Approve
          </Dropdown.Item>
        )}
        {data.confirmed === 'Yes' && (
          <Dropdown.Item
            href='#'
            onClick={() => handleUpdatePayment(data)}
            disabled={levelHelper.isOnlyViewable(auth.user?.level)}
          >
            Update Payment Method
          </Dropdown.Item>
        )}
        <Dropdown.Item
          href={`${process.env.REACT_APP_NBS_ONLINE_URL}processing_pdf.php?dbkey_quote_b=${data.dbkey}&order_type=quote_portal&pdf=y`}
          target='_blank'
        >
          View
        </Dropdown.Item>
        <Dropdown.Item
          href='#'
          onClick={handleComment}
          disabled={levelHelper.isOnlyViewable(auth.user?.level)}
        >
          Comment
        </Dropdown.Item>
        {data.status === 'Active' && (
          <Dropdown.Item
            href='#'
            onClick={handleReject}
            disabled={levelHelper.isOnlyViewable(auth.user?.level)}
          >
            Reject
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export { ActionDropDown }
