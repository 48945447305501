import axios from 'axios'

const GET_REPORTS_API_URL = `${process.env.REACT_APP_API_URL}/calibration-reports`
const GET_CUSTOMER_IDS_API_URL = `${process.env.REACT_APP_API_URL}/calibration-reports/get-customer-ids`
const GET_GET_CALIBRATION_TYPES = `${process.env.REACT_APP_API_URL}/calibration-type/get`

export function getCalibrationReports(searchFilter = {}) {
  return axios.get(`${GET_REPORTS_API_URL}`, { params: searchFilter })
}

export function getCalibrationRepotCustomerIds() {
  return axios.get(`${GET_CUSTOMER_IDS_API_URL}`)
}

export function getCalibrationTypes() {
  return axios.get(GET_GET_CALIBRATION_TYPES)
}
