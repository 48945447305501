import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router'
import toast from 'react-hot-toast'
import ConfirmAlert from '../../components/ConfirmAlert'
import { removeNBSAdmin, getNBSAdmins } from './redux/nbsAdminCRUD'
import { UserModel } from '../../../types'
import { connect } from 'react-redux'
import { RootState } from '../../../setup'

type Props = {
  nbsAdmins: UserModel[],
  auth: any
}
const DeleteCustomer: React.FC<Props> = ({ nbsAdmins, auth }) => {
  const {id}: any = useParams()
  const history = useHistory()
  const [showConfirmAlert, setConfirmAlert] = useState<boolean>(true)
  const nbsAdmin = nbsAdmins.find((nbsAdmin: UserModel) => nbsAdmin.id === parseInt(id))

  const onConfirm = () => {
    removeNBSAdmin(id)
      ?.then(() => {
        toast.success('Successfully removed the C2CAL Admin')

        return getNBSAdmins()
      })
      .catch(() => toast.error('Failed to remove the C2CAL Admin'))
      .finally(() => {
        setConfirmAlert(false)
        history.push('/dashboard')
      })
  }
  const onCancel = () => {
    setConfirmAlert(false)
    history.push('/dashboard')
  }
  const content = `Are you sure to change the status of customer ${nbsAdmin?.firstname} ${nbsAdmin?.lastname}`
  return (
    <ConfirmAlert
      open={showConfirmAlert}
      onConfirm={onConfirm}
      onHide={onCancel}
      content={content}
    />
  )
}

const mapState = (state: RootState) => ({
  nbsAdmins: state.nbsAdmins?.nbsAdmins,
  auth: state.auth,
})
const connector = connect(mapState, null)
export default connector(DeleteCustomer)
