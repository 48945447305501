import { Action } from '@reduxjs/toolkit'
import { put, takeLatest } from 'redux-saga/effects'
import { CalibrationReportModel } from '../../../types/calibration-reports/CalibrationReportModel'
import { getCalibrationReports } from './CalibrationReportsCrud'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  GetCalibrationReports: '[GetCalibrationReports] Action',
  GetCalibrationReportsSuccess: '[GetCalibrationReportsSuccess] Action',
}

export interface ICalibrationReportState {
  calibrationReports: any
  loading: boolean
  pagination: any
}

const initialQuotesState: ICalibrationReportState = {
  calibrationReports: [],
  loading: false,
  pagination: [],
}

export const reducer = (
  state = initialQuotesState,
  action: ActionWithPayload<ICalibrationReportState>
) => {
  switch (action.type) {
    case actionTypes.GetCalibrationReports: {
      return { ...state, loading: true }
    }

    case actionTypes.GetCalibrationReportsSuccess: {
      const calibrationReports = action.payload?.calibrationReports

      return { ...state, calibrationReports, loading: false }
    }
    default:
      return state
  }
}

export const actions = {
  getCalibrationReports: (searchFilter = {}) => ({
    type: actionTypes.GetCalibrationReports,
    payload: { searchFilter },
  }),
  getCalibrationReportsSuccess: (calibrationReports: CalibrationReportModel[]) => ({
    type: actionTypes.GetCalibrationReportsSuccess,
    payload: { calibrationReports },
  }),
}

export function* saga() {
  yield takeLatest(
    actionTypes.GetCalibrationReports,
    function* getCalibrationReportsRequested(action: any) {
      const { searchFilter } = action.payload
      const { data } = yield getCalibrationReports(searchFilter)
      yield put(actions.getCalibrationReportsSuccess(data))
    }
  )
}
