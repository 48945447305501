import { useState, useRef, useEffect } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { Formik, Form, Field } from 'formik'
import { KTSVG } from '../../../_metronic/helpers'
import { SelectDropDown } from '../SelectDropDown'
import { getPaymentOptions, createPaymentOption } from '../../services/payment'
import { getCustomerById, getCustomersByOrderId } from '../../services/service'
import { AddCardModal } from '../../modules/accounts/components/AddCardModal'
import { getErrorMessage } from '../../../helper'
import { CONFIRM_CHARGE_PAYMENT } from '../../../data/constants'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import { TextField } from '@material-ui/core'

// Validation schema
const CustomerEditSchema = Yup.object().shape({
  po: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('PO is required'),
  paymentType: Yup.string().required('Payment Option is required')

})

const initialValues = {
  po: '',
  paymentType: '',
  comment: '',
}

const CompletePay = ({
  submit,
  status,
  orderId,
  quoteNumber,
  totalAmount,
  show,
  onHide,
  actions,
  customerId,
  showConfirmAlert,po2
}) => {
  const [initValue, setInitValue] = useState(initialValues)
  const [loading, setLoading] = useState(false)
  const [showThisModal, setShowThisModal] = useState(true)
  const [showAddModal, setAddModal] = useState(false)
  const [customerOptions, setCustomerOptions] = useState([])
  const [cardOptions, setCardOptions] = useState([])
  const [customer, setCustomer] = useState()
  const [paymentType, setPaymentType] = useState()
  const [paymentProfileId, setPaymentProfileId] = useState('')
  const [customerProfileId, setCustomerProfileId] = useState('')
  const [cardNumber, setCardNumber] = useState('No Number')
  const [inValidMessage, setInValidMessage] = useState('')
  const [create, setCreate] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState({})
  const [selectedCustomerId, setSelectedCustomerId] = useState(-1)
  const [customers, setCustomers] = useState([])

  const formikRef = useRef()

  const handlePaymentOption = (e) => {
    formikRef.current.setFieldValue('paymentType', e.target.value)
    setPaymentType(e.target.value)
  }

  const handleCardNumber = (value, options = []) => {
    setCardNumber(value)

    let customerProfile = options
      .flat()
      .find((o) => o.profile.payment.creditCard.cardNumber === value)

    if (options.length === 0) {
      customerProfile = customerOptions
        .flat()
        .find((o) => o.profile.payment.creditCard.cardNumber === value)
    }

    setPaymentProfileId(customerProfile ? customerProfile.profile.customerPaymentProfileId : '')
    setCustomerProfileId(customerProfile ? customerProfile.customerProfileId : '')
  }

  const handleSubmit = (values) => {
    if (values.paymentType == 'cc' && customers.length === 0) {
      toast.error('You can not update payment option because there is not assigned customer.')
      return
    }

    if (values.paymentType == 'cc' && !selectedCustomer.id) {
      toast.error('Select a customer')
      return
    }

    if (values.po == 'No Number' || values.po == 'Select Number') {
      toast.error('Select card number')
      return
    }

    setLoading(true)

    const payload = {
      orderId,
      paymentProfileId,
      amount: totalAmount,
      comment: values.comment,
      paymentType: values.paymentType,
      paymentNumber: values.po,
      customerProfileId,
    }
    submit(payload)
  }

  const handleHide = () => {
    setAddModal(false)
    setShowThisModal(true)
  }

  const handleSelectCustomer = (value) => {
    let sCustomer = customers.find(
      (c) => c.firstname == value.split(' ')[0] && c.lastname == value.split(' ')[1]
    )
    setSelectedCustomer(sCustomer)

    getPaymentOptions(orderId, sCustomer.id)
      .then((res) => {
        setCustomerOptions(res.data)
        const cardNumbers = res.data
          .flat()
          .map((options) => options.profile?.payment.creditCard.cardNumber)
          .flat()
        setCardOptions(cardNumbers)
        setCardNumber(cardNumbers.length === 0 ? 'No Number' : 'Select Number')
        setLoading(false)
      })
      .catch((error) => {
        const errMsg = getErrorMessage(error)
        console.log(errMsg)
      })
  }

  const handleCreate = (payload) => {
    setCreate(true)
    setInValidMessage('')
    createPaymentOption(payload)
      .then((res) => {
        if (typeof res.data === 'string') {
          setCreate(false)
          toast.success(res.data)
          setAddModal(false)
          setShowThisModal(true)
          setLoading(true)

          getPaymentOptions(orderId, selectedCustomer.id)
            .then((res) => {
              setCustomerOptions(res.data)
              const cardNumbers = res.data
                .flat()
                .map((options) => options.profile?.payment.creditCard.cardNumber)
                .flat()
              setCardOptions(cardNumbers)
              setCardNumber(
                cardNumbers.length === 0 ? 'No Number' : cardNumbers[cardNumbers.length - 1]
              )
              if (formikRef)
                formikRef.current.setFieldValue(
                  'po',
                  cardNumbers.length === 0 ? 'No Number' : cardNumbers[cardNumbers.length - 1]
                )
              setLoading(false)
            })
            .catch((error) => {
              const errMsg = getErrorMessage(error)
              console.log(errMsg)
            })
        } else {
          setCreate(false)
          setInValidMessage(res.data[0].text)
          toast.error(res.data[0].text)
        }
      })
      .catch((error) => {
        const errMsg = getErrorMessage(error)
        toast.error(errMsg)
        setCreate(false)
      })
  }

  useEffect(() => {
    if (status) setLoading(false)
  }, [status])
  useEffect(() => {
    if (show) {
      setInitValue(initialValues);
      setPaymentType('')
    }
  }, [show])

  // useEffect(() => {
  //   if (option.meta) {
  //     const meta = JSON.parse(option?.meta)

  //     if (meta.paymentType === 'cc') {
  //       setCardNumber(meta.po)
  //     } else setCardNumber('No Number')

  //     if (formikRef.current) {
  //       formikRef.current.setFieldValue('po', meta.po)
  //       formikRef.current.setFieldValue('paymentType', meta.paymentType)
  //       formikRef.current.setFieldValue('comment', option.comment)
  //       if (option.attachment) formikRef.current.setFieldValue('attachment', option.attachment)
  //     }
  //   } else {
  //     if (formikRef.current) {
  //       formikRef.current.setFieldValue('po', '')
  //       formikRef.current.setFieldValue('paymentType', 'po')
  //       formikRef.current.setFieldValue('comment', '')
  //     }
  //   }
  // }, [option])

  useEffect(() => {
    if (customerId !== '') {
      if (selectedCustomer.billInfo) {
        setCustomer(selectedCustomer.billInfo)
      } else {
        getCustomerById(customerId).then((res) => {
          setCustomer(res.data[0])
        })
      }
    }
  }, [selectedCustomer, customerId])

  useEffect(() => {
    setLoading(true)
    setShowThisModal(true)
    setAddModal(false)
    setSelectedCustomer({})
    setCardOptions([])

    let customerId = 0
    let selectedCustomerId = -1

    if (actions && actions.length !== 0) {
      let action = actions.findLast((a) => JSON.parse(a?.meta).paymentType === 'cc')

      if (action && action.meta) {
        const meta = JSON.parse(action?.meta)

        selectedCustomerId = meta.customerId ? meta.customerId : -1
      }
    }

    if (orderId !== 0) {
      getCustomersByOrderId(orderId).then((res) => {
        setCustomers(res.data)

        if (res.data.length !== 0) {
          if (selectedCustomerId === -1) {
            setSelectedCustomer(res.data[0])
            customerId = res.data[0].id
          } else {
            let sCustomer = res.data.find((c) => c.id == selectedCustomerId)
            setSelectedCustomer(sCustomer ? sCustomer : res.data[0])

            customerId = sCustomer ? sCustomer.id : res.data[0].id
          }

          getPaymentOptions(orderId, customerId)
            .then((res) => {
              const cardNumbers = res.data
                .flat()
                .map((options) => options.profile?.payment.creditCard.cardNumber)
                .flat()

              setCustomerOptions(res.data)
              setCardOptions(cardNumbers)

              setLoading(false)
            })
            .catch((error) => {
              const errMsg = getErrorMessage(error)
              console.log(errMsg)
            })
        }
      })
    }
  }, [orderId, actions])

  useEffect(() => {
    /*let action = actions.findLast((a) => JSON.parse(a?.meta).paymentType === 'cc')?.meta
      ? actions.findLast((a) => JSON.parse(a?.meta).paymentType === 'cc')
      : actions.findLast((a) => JSON.parse(a?.meta).paymentType === 'po')?.meta
        ? actions.findLast((a) => JSON.parse(a?.meta).paymentType === 'po')
        : actions.findLast((a) => JSON.parse(a?.meta).paymentType === 'other')?.meta
          ? actions.findLast((a) => JSON.parse(a?.meta).paymentType === 'other')
          : {}
          let action = {}
    */
    let action = {}
      if(actions){
        if (actions.findLast((a) => JSON.parse(a?.meta).paymentType === 'cc')?.meta) {
          action = actions.findLast((a) => JSON.parse(a?.meta).paymentType === 'cc');
        } else if (actions.findLast((a) => JSON.parse(a?.meta).paymentType === 'po')?.meta) {
          action = actions.findLast((a) => JSON.parse(a?.meta).paymentType === 'po');
        } else if (actions.findLast((a) => JSON.parse(a?.meta).paymentType === 'other')?.meta) {
          action = actions.findLast((a) => JSON.parse(a?.meta).paymentType === 'other');
        } else {
          action = {};
        }
      }
      

    let poNumber = ''

    if (paymentType) {
      action = actions.findLast((a) => JSON.parse(a?.meta).paymentType === paymentType)
    }

    if (action && action.meta) {
      const meta = JSON.parse(action?.meta)
      poNumber = meta.po

      if (meta.paymentType === 'cc') {
        handleCardNumber(
          cardOptions.length === 0
            ? 'No Number'
            : cardOptions.includes(meta.po)
              ? meta.po
              : 'Select Number',
          customerOptions
        )

        poNumber =
          cardOptions.length === 0
            ? 'No Number'
            : cardOptions.includes(meta.po)
              ? meta.po
              : 'Select Number'
      }

      if (formikRef.current) {
        formikRef.current.setFieldValue('po', poNumber)
        formikRef.current.setFieldValue('paymentType', meta.paymentType)
        formikRef.current.setFieldValue('comment', action.comment ? action.comment : '')
        if (action.attachment) formikRef.current.setFieldValue('attachment', action.attachment)
      }
      setLoading(false)
    } else {
      if (formikRef.current) {
        formikRef.current.setFieldValue('po', '')
        formikRef.current.setFieldValue('paymentType', paymentType ?? '')
        formikRef.current.setFieldValue('comment', '')
      }
      setLoading(false)
    }
  }, [actions, customerOptions, cardOptions, paymentType, show])

  return (
    <>
      <Modal
        size='lg'
        show={show}
        onHide={onHide}
        className={`${!showThisModal && 'opacity-0'}`}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          <Modal.Title>Order Number: {quoteNumber}</Modal.Title>
          <span className='text-hover-primary cursor-pointer' onClick={onHide}>
            <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
          </span>
        </Modal.Header>
        <Formik
          innerRef={formikRef}
          validationSchema={CustomerEditSchema}
          onSubmit={handleSubmit}
          initialValues={initialValues}
        >
          {({ handleSubmit, handleChange, setFieldValue, errors, touched, values }) => (
            <>
              <Modal.Body className='overlay cursor-default'>
                {loading && (
                  <div className='overlay-layer bg-transparent'>
                    <div className='spinner spinner-lg spinner-success' />
                  </div>
                )}
                <Form className='form form-label-right'>
                  <div className='form-group row'>
                    <div className='col-lg-12'>
                      <span>ID: {orderId}</span>
                    </div>
                    <div className={`col-lg-12 ${values.paymentType !== 'cc' && 'd-none'}`}>
                      <div className='row'>
                        <div className='col-lg-6'>
                          <span className='required'>Select customer</span>
                          <SelectDropDown
                            data={customers.map((c) => c.firstname + ' ' + c.lastname)}
                            defaultValue={
                              selectedCustomer.firstname
                                ? selectedCustomer.firstname + ' ' + selectedCustomer.lastname
                                : 'No customer assigned'
                            }
                            dirUp={false}
                            selectedValue={(value) => handleSelectCustomer(value)}
                          />
                        </div>
                      </div>
                    </div>
                    {/* PO */}
                    <div className={`col-lg-6 mt-10 ${values.paymentType !== 'cc' && 'd-none'}`}>
                      <span className='required'>Credit Card Number</span>
                      <SelectDropDown
                        data={cardOptions}
                        defaultValue={cardNumber}
                        dirUp={false}
                        selectedValue={(value) => {
                          handleCardNumber(value)
                          setFieldValue('po', value)
                        }}
                      />
                      {touched.po && errors.po ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>Card Number is required</div>
                        </div>
                      ) : null}
                      <div
                        className={`mt-2 text-primary text-decoration-underline cursor-pointer ${customers.length === 0 && 'd-none'
                          }`}
                        onClick={() => {
                          setShowThisModal(false)
                          setAddModal(true)
                        }}
                      >
                        Create new payment option
                      </div>
                      <div className='mt-6 text-primary text-decoration-underline cursor-pointer'>
                        <a
                          href={`${process.env.REACT_APP_NBS_ONLINE_URL}processing_pdf.php?dbkey_quote_b=${orderId}&dbkey_calreports=&order_type=quote_portal&pdf=y`}
                          rel='noreferrer'
                          target='_blank'
                        >
                          View invoice
                        </a>
                      </div>
                    </div>
                    <div className={`col-lg-6 mt-10 ${values.paymentType === 'cc' && 'd-none'}`}>
                      <span className='required'>
                        {values.paymentType === 'cc'
                          ? 'Credit Card Number'
                          : values.paymentType === 'po'
                            ? 'Purchase Order Number'
                            : 'Other'}
                      </span>
                      <Field
                        name='po'
                        as='input'
                        placeholder={
                          values.paymentType === 'cc'
                            ? 'Enter C.C Number'
                            : values.paymentType === 'po'
                              ? 'Enter P.O Number'
                              : 'Other'
                        }
                        className='form-control'
                      />
                      {touched.po && errors.po ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.po}</div>
                        </div>
                      ) : null}
                    </div>
                    {/* Payment Type */}
                    <div className='col-lg-6 mt-10 position-relative'>
                      <span className='fs-7 fst-italic position-absolute bottom-100'>
                        **By selecting "Pay Now" below, you will be charged the amount provided.
                      </span>
                      <span className='required'>Payment Option</span>
                      <select
                        className='form-control'
                        name='paymentType'
                        data-control='select2'
                        label='Payment Type'
                        onChange={handlePaymentOption}
                        // defaultValue=""
                        value={values.paymentType}
                      >
                        <option value='' disabled>Select Payment Options</option>
                        <option value='po'>Purchase Order</option>
                        <option value='cc'>Credit Card</option>
                        <option value='other'>Other</option>
                      </select>
                      {touched.paymentType && errors.paymentType ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.paymentType}</div>
                        </div>
                      ) : null}
                      {
                        values.paymentType === "cc" && po2 ? <TextField value={po2} fullWidth style={{marginTop:'12px'}} disabled label="P.O.#"/>:""
                      }
                      <div className='mt-2 fs-5'>
                        {`Total Amount: ${totalAmount ? `$${totalAmount}` : ''}`}
                      </div>
                    </div>
                    <div className='form-group mt-5'>
                      <span>Update Comments</span>
                      <Field
                        name='comment'
                        as='textarea'
                        rows='5'
                        placeholder='Enter Comments'
                        className='form-control'
                      />
                      {touched.comment && errors.comment ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.comment}</div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type='button'
                  onClick={() => {
                    onHide()
                  }}
                  className='btn btn-light btn-elevate'
                >
                  Cancel
                </button>
                <> </>
                <button
                  type='submit'
                  onClick={() => {
                    if (values.po !== '') {
                      const CONFIRM_ALERT_CONTENT = CONFIRM_CHARGE_PAYMENT.replace(
                        '@quoteNumber',
                        orderId
                      )
                        .replace('@cardNumber', cardNumber)
                        .replace('@amount', totalAmount)
                      showConfirmAlert(CONFIRM_ALERT_CONTENT, handleSubmit)
                    } else {
                      handleSubmit()
                    }
                  }}
                  className='btn btn-primary btn-elevate'
                  disabled={loading}
                >
                  {!loading && <span className='indicator-label'>Pay Now</span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>
      <AddCardModal
        show={showAddModal}
        onHide={handleHide}
        title='Add New Card'
        invalidMessage={inValidMessage}
        quoteId={orderId}
        option={cardOptions}
        submit={create}
        customer={customer}
        onCreate={handleCreate}
      />
    </>
  )
}

export default CompletePay
