import axios from 'axios'
import { BlogFormData } from '..'

export const BLOGS_URL = `${process.env.REACT_APP_API_URL}/blogs`

export function getBlogs(searchFilter: any = {}) {
  return axios.get(BLOGS_URL, {params: searchFilter})
}

export function updateBlog(id: string | number = -1, values: BlogFormData) {
  let formData = new FormData()
  if (typeof values.attachment !== 'string') {
    formData.append('attachment', values.attachment)
  }
  formData.append('title', values.title)
  formData.append('content', values.content)

  const API_URL = `${BLOGS_URL}/${id}`

  return axios.post(API_URL, formData)
}

export function createBlog(values: BlogFormData) {
  let formData = new FormData()
  if (typeof values.attachment !== 'string') {
    formData.append('attachment', values.attachment)
  }
  formData.append('title', values.title)
  formData.append('content', values.content)
  formData.append('type', 'text')

  return axios.post(BLOGS_URL, formData)
}

export function removeBlog(id: string | number = -1) {
  const API_URL = `${BLOGS_URL}/${id}`

  return axios.delete(API_URL)
}
