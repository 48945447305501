import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
import React, { useMemo } from 'react'
import {
  AccreditReports,
  IArrayEquipmentTest,
} from '../../../../types/accredit-reports/AccreditReportsTypes'
import { CheckIsAdjusted, PerformanceAsLeftTolerance } from '../helper/PdfStylingCheck'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
const styles = StyleSheet.create({
  header: {
    display: 'flex',
    flexDirection: 'row',
  },
  cell1: {
    border: '1px solid black',
    textAlign: 'center',
    alignItems: 'center',
    width: '15%',
    padding: 2,
  },
  cell2: {
    border: '1px solid black',
    textAlign: 'center',
    alignItems: 'center',
    width: '15%',
    padding: 2,
  },
  cell3: {
    border: '1px solid black',
    textAlign: 'center',
    alignItems: 'center',

    width: '15%',
    padding: 2,
  },
  cell4: {
    border: '1px solid black',
    textAlign: 'center',
    alignItems: 'center',

    width: '40%',
    padding: 2,
  },
  cell5: {
    border: '1px solid black',
    textAlign: 'center',
    alignItems: 'center',

    width: '15%',
    padding: 2,
  },
  big_cell: {
    alignItems: 'center',
    justifyContent: 'center',
  },

  body: {
    display: 'flex',
    flexDirection: 'row',
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
})
type Props = { data: IArrayEquipmentTest }
function defaultIfEmpty(value: string | null | undefined, defaultValue: string): string {
  return value === null || value === undefined || value === '' ? defaultValue : value;
}
const PerformanceCheckTable = (props: Props) => {
  const { data } = props
    //Set default values
    data.Wt1 = defaultIfEmpty(data.Wt1, "0")
    data.Wt2 = defaultIfEmpty(data.Wt2, "0")
    data.Wt3 = defaultIfEmpty(data.Wt3, "0")
    data.Wt4 = defaultIfEmpty(data.Wt4, "0")
    data.Wt5 = defaultIfEmpty(data.Wt5, "0")
    data.Wt6 = defaultIfEmpty(data.Wt6, "0")
    data.Wt7 = defaultIfEmpty(data.Wt7, "0")
    data.Wt8 = defaultIfEmpty(data.Wt8, "0")
    data.Wt9 = defaultIfEmpty(data.Wt9, "0")
    data.Wt10 = defaultIfEmpty(data.Wt10, "0")
    data.WU1 = defaultIfEmpty(data.WU1, "0")
    data.WU2 = defaultIfEmpty(data.WU2, "0")
    data.WU3 = defaultIfEmpty(data.WU3, "0")
    data.WU4 = defaultIfEmpty(data.WU4, "0")
    data.WU5 = defaultIfEmpty(data.WU5, "0")
    data.WU6 = defaultIfEmpty(data.WU6, "0")
    data.WtF1 = defaultIfEmpty(data.WtF1 , "0")
    data.WtF2 = defaultIfEmpty(data.WtF2 , "0")
    data.WtF3 = defaultIfEmpty(data.WtF3 , "0")
    data.WtF4 = defaultIfEmpty(data.WtF4 , "0")
    data.WtF5 = defaultIfEmpty(data.WtF5 , "0")
    data.WtF6 = defaultIfEmpty(data.WtF6 , "0")
    data.WtL1 = defaultIfEmpty(data.WtL1, "0")
    data.WtL2 = defaultIfEmpty(data.WtL2, "0")
    data.WtL3 = defaultIfEmpty(data.WtL3, "0")
    data.WtL4 = defaultIfEmpty(data.WtL4, "0")
    data.WtL5 = defaultIfEmpty(data.WtL5, "0")
    data.WtL6 = defaultIfEmpty(data.WtL6, "0")
    data.TOL1 = defaultIfEmpty(data.TOL1, "0 - 0")
    data.TOL2 = defaultIfEmpty(data.TOL2, "0 - 0")
    data.TOL3 = defaultIfEmpty(data.TOL3, "0 - 0")
    data.TOL4 = defaultIfEmpty(data.TOL4, "0 - 0")
    data.TOL5 = defaultIfEmpty(data.TOL5, "0 - 0")
    data.TOL6 = defaultIfEmpty(data.TOL6, "0 - 0")
  // Use nullish coalescing to provide a default empty string if data.Division is null or undefined.
    // Ensure data.Division is a string before calling replace. If it's null or undefined, use an empty string.
    data.Division = typeof data?.Division === 'string' ? data.Division.replace(/0+$/, '') : '';

    // Apply the same logic for data.DivisionDual
    data.DivisionDual = typeof data?.DivisionDual === 'string' ? data.DivisionDual.replace(/0+$/, '') : '';
  let decimalPlaces = data?.Division.includes('.') ? data?.Division.split('.')[1].length : 0
  let decimalPlacesDual = data?.DivisionDual.includes('.')
    ? data?.DivisionDual.split('.')[1].length
    : 0
    const handleInTolerance = (WtF: string, TOL: string) => {
      // Step 3: If TOL is "0", return true
      if (TOL === "0") return true;
    
      // Parse WtF as a floating-point number
      const value = parseFloat(WtF);
    
      // Step 1 & 2: Extract the tolerance range from the TOL string
      const [minTol, maxTol] = TOL.split(' - ').map(Number);
    
      // Step 4: Check if value falls within the tolerance range
      return value >= minTol && value <= maxTol;
    };
 const formatTolerance = (tol: string) => {
  // Handle zero case
  if (tol === '0') {
    return '0 - 0';
  }

  // Split input based on '-' to handle negative and positive mix
  const parts = tol.split('-');

  // Check the parts to decide how to format them with the correct spacing
  const formattedParts = parts.map((part, index) => {
    // Trim to remove any leading/trailing spaces
    part = part.trim();
    // For the first part, just return it as is
    if (index === 0) {
      return part;
    }
    // For the second part, check if it's negative
    if (part.startsWith('-')) {
      // If it's negative, prepend space only before the dash
      return ` - ${part}`;
    } else {
      // If it's not negative, ensure space around the dash
      return ` - ${part}`;
    }
  });

  // Join the parts without additional manipulation
  return formattedParts.join('');
};

    
    
  const determineDecimalPlaces = (
    number: number,
    capacity: string,
    divisionLen: number,
    dualLen: number
  ): string => {
    let result

    if (capacity.includes('/')) {
      const capacityValues = capacity.split('/').map(Number)
      if (number < capacityValues[0]) {
        result = number.toFixed(divisionLen)
      } else {
        result = number.toFixed(dualLen)
      }
    } else {
      result = number.toFixed(divisionLen)
    }

    return isNaN(parseFloat(result)) ? 'OVERLOAD' : result
  }

  return (
    <View style={styles.root}>
      <View>
        <View style={styles.header}>
          <View style={styles.cell1}>
            <Text style={{ textAlign: 'center' }}>Mass Value</Text>
          </View>
          <View style={styles.cell2}>
            <Text>As-Found</Text>
          </View>
          <View style={styles.cell3}>
            <Text>In-Tolerance</Text>
          </View>
          <View style={styles.cell4}>
            <Text>Tolerance</Text>
          </View>
          <View style={styles.cell5}>
            <Text>As-Left</Text>
          </View>
        </View>
        {console.log()}
        {console.log('YN', data.ynA2LACalRep)}
        {data.ynA2LACalRep != 0 && (
          <>
            <View style={styles.body}>
              <View style={styles.cell1}>
                <Text>{Number(data?.WU1) || '0'} </Text>
              </View>
              <View style={styles.cell2}>
                <Text>
                  {determineDecimalPlaces(
                    Number(data?.WtF1),
                    data?.Capacity,
                    decimalPlaces,
                    decimalPlacesDual
                  )}{' '}
                </Text>
              </View>
              <View style={styles.cell3}>
                <Text style={{ color: handleInTolerance(data.WtF1, data.TOL1) ? 'green' : 'red' }}>
                  {handleInTolerance(data.WtF1, data.TOL1) ? 'Yes' : 'No'}
                </Text>
              </View>
              <View style={styles.cell4}>
                <Text>{formatTolerance(data.TOL1) ?? '0'}</Text>
              </View>
              <View style={styles.cell5}>
                <Text>
                  {determineDecimalPlaces(
                    Number(data?.WtL1),
                    data?.Capacity,
                    decimalPlaces,
                    decimalPlacesDual
                  )}{' '}
                </Text>
              </View>
            </View>
            <View style={styles.body}>
              <View style={styles.cell1}>
                <Text>{Number(data?.WU2) || '0'} </Text>
              </View>
              <View style={styles.cell2}>
                <Text>
                  {determineDecimalPlaces(
                    Number(data?.WtF2),
                    data?.Capacity,
                    decimalPlaces,
                    decimalPlacesDual
                  )}{' '}
                </Text>
              </View>
              <View style={styles.cell3}>
                <Text style={{ color: handleInTolerance(data.WtF2, data.TOL2) ? 'green' : 'red' }}>
                  {handleInTolerance(data.WtF2, data.TOL2) ? 'Yes' : 'No'}
                </Text>
              </View>
              <View style={styles.cell4}>
                <Text>
                  <Text>{formatTolerance(data.TOL2) ?? '0'}</Text>
                </Text>
              </View>
              <View style={styles.cell5}>
                <Text>
                  {determineDecimalPlaces(
                    Number(data?.WtL2),
                    data?.Capacity,
                    decimalPlaces,
                    decimalPlacesDual
                  )}{' '}
                </Text>
              </View>
            </View>
          </>
        )}
        <View style={styles.body}>
          <View style={styles.cell1}>
            <Text>{Number(data?.WU3) || '0'} </Text>
          </View>
          <View style={styles.cell2}>
            <Text>
              {determineDecimalPlaces(
                Number(data?.WtF3),
                data?.Capacity,
                decimalPlaces,
                decimalPlacesDual
              )}{' '}
            </Text>
          </View>
          <View style={styles.cell3}>
            <Text style={{ color: handleInTolerance(data.WtF3, data.TOL3) ? 'green' : 'red' }}>
              {handleInTolerance(data.WtF3, data.TOL3) ? 'Yes' : 'No'}
            </Text>
          </View>
          <View style={styles.cell4}>
            <Text>
              <Text>{formatTolerance(data.TOL3) ?? '0'}</Text>
            </Text>
          </View>
          <View style={styles.cell5}>
            <Text>
              {determineDecimalPlaces(
                Number(data?.WtL3),
                data?.Capacity,
                decimalPlaces,
                decimalPlacesDual
              )}{' '}
            </Text>
          </View>
        </View>
        <View style={styles.body}>
          <View style={styles.cell1}>
            <Text>{Number(data?.WU4) || '0'} </Text>
          </View>
          <View style={styles.cell2}>
            <Text>
              {determineDecimalPlaces(
                Number(data?.WtF4),
                data?.Capacity,
                decimalPlaces,
                decimalPlacesDual
              )}{' '}
            </Text>
          </View>
          <View style={styles.cell3}>
            <Text style={{ color: handleInTolerance(data.WtF4, data.TOL4) ? 'green' : 'red' }}>
              {handleInTolerance(data.WtF4, data.TOL4) ? 'Yes' : 'No'}
            </Text>
          </View>
          <View style={styles.cell4}>
            <Text>
              <Text>{formatTolerance(data.TOL4) ?? '0'}</Text>
            </Text>
          </View>
          <View style={styles.cell5}>
            <Text>
              {determineDecimalPlaces(
                Number(data?.WtL4),
                data?.Capacity,
                decimalPlaces,
                decimalPlacesDual
              )}{' '}
            </Text>
          </View>
        </View>

        <View style={styles.body}>
          <View style={styles.cell1}>
            <Text>{Number(data?.WU5) || '0'} </Text>
          </View>
          <View style={styles.cell2}>
            <Text>
              {determineDecimalPlaces(
                Number(data?.WtF5),
                data?.Capacity,
                decimalPlaces,
                decimalPlacesDual
              )}{' '}
            </Text>
          </View>
          <View style={styles.cell3}>
            <Text style={{ color: handleInTolerance(data.WtF5, data.TOL5) ? 'green' : 'red' }}>
              {handleInTolerance(data.WtF5, data.TOL5) ? 'Yes' : 'No'}
            </Text>
          </View>
          <View style={styles.cell4}>
            <Text>
              <Text>{formatTolerance(data.TOL5) ?? '0'}</Text>
            </Text>
          </View>
          <View style={styles.cell5}>
            <Text>
              {determineDecimalPlaces(
                Number(data?.WtL5),
                data?.Capacity,
                decimalPlaces,
                decimalPlacesDual
              )}
            </Text>
          </View>
        </View>
        <View style={styles.body}>
          <View style={styles.cell1}>
            <Text>{Number(data?.WU6) || '0'} </Text>
          </View>
          <View style={styles.cell2}>
            <Text>
              {determineDecimalPlaces(
                Number(data?.WtF6),
                data?.Capacity,
                decimalPlaces,
                decimalPlacesDual
              )}{' '}
            </Text>
          </View>
          <View style={styles.cell3}>
            <Text style={{ color: handleInTolerance(data.WtF6, data.TOL6) ? 'green' : 'red' }}>
              {handleInTolerance(data.WtF6, data.TOL6) ? 'Yes' : 'No'}
            </Text>
          </View>
          <View style={styles.cell4}>
            <Text>
              <Text>{formatTolerance(data.TOL6) ?? '0'}</Text>
            </Text>
          </View>
          <View style={styles.cell5}>
            <Text>
              {determineDecimalPlaces(
                Number(data?.WtL6),
                data?.Capacity,
                decimalPlaces,
                decimalPlacesDual
              )}{' '}
            </Text>
          </View>
        </View>
      </View>
      <View style={[styles.cell4, styles.big_cell]}>
        {PerformanceAsLeftTolerance(
          data.WtL1,
          data.WtL2,
          data.WtL3,
          data.WtL4,
          data.WtL5,
          data.WtL6,
          data.TOL1,
          data.TOL2,
          data.TOL3,
          data.TOL4,
          data.TOL5,
          data.TOL6,
          data.ynA2LACalRep
        ) == 'Yes' ? (
          <Text>
            In Tolerance <Image src={toAbsoluteUrl('/media/arrow.png')} />:{' '}
            <span style={{ color: 'green' }}>Yes</span>
          </Text>
        ) : (
          <Text>
            In Tolerance: <span style={{ color: 'red' }}>No</span>
          </Text>
        )}

        <Text>
          Adjusted Calibration:{' '}
          <span>
            {CheckIsAdjusted(
              [data.WtF1, data.WtF2, data.WtF3, data.WtF4, data.WtF5, data.WtF6],
              [data.WtL1, data.WtL2, data.WtL3, data.WtL4, data.WtL5, data.WtL6]
            )}
          </span>
        </Text>
      </View>
    </View>
  )
}

export default PerformanceCheckTable
