/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */

import React, { FC, useEffect, useMemo, useState, useRef, useCallback } from 'react'
import { connect, ConnectedProps, useDispatch } from 'react-redux'
import { Card } from 'react-bootstrap-v5'
import { PageTitle } from '../../../_metronic/layout/core'
import { KTSVG } from '../../../_metronic/helpers'
import { IAuthState } from '../../modules/auth'
import { PersistPartial } from 'redux-persist/es/persistReducer'
import { CalibrationReportModel, Customer, ICalibrationReportAttachment, fileType } from '../../../types'
import { RootState } from '../../../setup'
import { TableColumn } from 'react-data-table-component'
import { ActionDropDown } from './component/ActionDropDown'
import moment from 'moment'
import DataTableBase from '../../components/DataTableBase'
import FilterDropDown from '../../modules/calibration/FilterDropDown'
import * as calibrationReportsRedux from './CalibrationReportsRedux'
import * as levelHelper from '../../../helper/level.helper'
import { Badge, Button, Dialog } from '@material-ui/core'
import AddCalReportModal from './component/AddCalReportModal'
import { getAttachment, getOtherCalReportAttachments } from '../other-cal-reports/OtherCalReportsCrud'
import { getCalibrationReports as getDirectCalibrationReports} from './CalibrationReportsCrud'
import { getErrorMessage } from '../../../helper'
import toast from 'react-hot-toast'
import { AttachmentModal } from '../service-quotes/components/AttachmentModal'
import { CalibrationAttachmentModal } from './component/CalibrationAttachmentModal'
import useAuthCheck from '../../modules/auth/helpers/AuthCheckHook'

interface ReduxStateValueProps {
  calibrationReports: CalibrationReportModel[]
  loading: boolean
  customerIds: any
  auth: IAuthState & PersistPartial
}
interface customerFilters {
  customerIds: string[]
  dateFrom: string
  dateTo: string
  filterIds: string[]
  nbsCustomerNames: string[]
  equipmentTypes: string[]
  keyword:string
  currentPage: number
  perPage: number
  hasLoaded: boolean
}

const mapState = (state: RootState) => ({
  calibrationReports: state.calibrationReports.calibrationReports,
  loading: state.calibrationReports.loading,
  customerIds: state.auth?.activeCustomerIds,
  auth: state.auth,
})

const connector = connect(mapState, { ...calibrationReportsRedux.actions })

type PropsFromRedux = ConnectedProps<typeof connector>
type CalibrationReportsProps = ReduxStateValueProps //& PropsFromRedux

const CalibrationReportsPage: FC<CalibrationReportsProps> = (props) => {
  const { /*getCalibrationReports, calibrationReports,*/ customerIds, auth, /*loading*/ } = props
  const initialFilter = {
    from: '',
    to: '',
    isReset: false,
  }


  const [isNBS, setIsNBS] = useState<boolean>(false)
  const [keyword, setKeyword] = useState<string>('')
  const [keywordSearchTerm, setKeywordSearchTerm] = useState<string>('')
  const [nbsCustomerIds, setNbsCustomerIds] = useState<string[]>(['nbs'])
  const [nbsCustomerNames, setNbsCustomerNames] = useState<string[]>(['nbs'])
  // const [reportsData, setReportsData] = useState<any>([])
  const [open, setOpen] = useState<boolean>(false)
  const [showAttachmentModal, setShowAttachmentModal] = useState<boolean>(false)
  const [attachQuoteId, setAttachQuoteId] = useState<number>(0)
  const [attachment, setAttachment] = useState<any>([])
  const [editData, setEditData] = useState<any>([])
  const [type, setType] = useState<'add' | 'edit'>('add')
  const [openFilter, setOpenFilter] = useState<boolean>(false)
  const [fileInfo, setFileInfo] = useState<CalibrationReportModel>();
  const [labratoryReports, setLabratoryReports] = useState<any>();

  //Pagination Information
  const [perPage, setPerPage] = useState<number>(10)
  const [totalNumber, setTotalNumber] = useState<number>(10)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [filterIds, setFilterIds] = useState<string[]>([])
  //Pagination information

const [isLoading, setIsLoading] = useState<boolean>(true)
const [showSortArrows, setShowSortArrows] = useState<boolean>(false)
const [sortColumnConfig, setSortColumnConfig] = useState({ key: 'dateOfCal', direction: 'DESC' });


const [customerFilters, setCustomerFilters] = useState<customerFilters>({
  customerIds: [],
  dateFrom: "",
  dateTo: "",
  filterIds: [],
  nbsCustomerNames: [],
  equipmentTypes: [],
  keyword:"",
  currentPage: 1,
  perPage: 10,
  hasLoaded: false,
})

  const filterRef = useRef() as React.MutableRefObject<HTMLButtonElement>
  const dispatch = useDispatch()

  const checkAuth = useAuthCheck()

  ////THIS IS THE FILTERING FUNCTION.
  const handleFilter = (filters: any) => {
    const { selectedCustomers, from, to, types, isReset } = filters

    let filterIds: string[] = isNBS ? ['nbs'] : []
    let filterNames: string[] = []

    if (!isReset) {
      selectedCustomers.forEach((customer: Customer) => {
        filterIds.push(customer.id)
        filterNames.push(customer.name ?? '')
      })
   
      //filterIds = isNBS && filterIds.length === 0 ? ['nbs'] : filterIds
      if (isNBS && filterIds.length === 0) {
        filterIds = ['nbs'];
      }
    } else {
      filterIds = levelHelper.isNBS(props.auth.user?.type) ? ['nbs'] : customerIds
    }
    
    setCustomerFilters(prevState => ({
      ...prevState,
      filterIds:filterIds,
      nbsCustomerNames:filterNames,
      currentPage: 1,
      dateFrom:from,
      dateTo:to,
      equipmentTypes: types
  }));
  
  }

  const debounce = (func: Function, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: any) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleSearch = (value: string) => {
    setKeyword(value);
  }
  const handleSearchManual = () => {
    setKeyword(keywordSearchTerm)
  }
  
  const debouncedHandleSearch = useCallback(debounce(handleSearch, 500), []);


  const handleCancelCustomer = () => {
    setTimeout(() => {
      filterRef.current.click()
      setOpenFilter(false)
    }, 200)
  }
  
 ////THIS FUNCTION IS LIKELY THE MAIN DATA GATHERING ONE.
 const fetchData = async () => {
  const { from, to } = initialFilter
  setIsLoading(true)
  try {
    let effectiveFilter;
    if (levelHelper.isNBS(auth.user?.type)) {
      // If user is of NBS type and currentCustomerFilters is empty, set filter to 'nbs'
      if (customerFilters.filterIds.length === 0){
        effectiveFilter = ['nbs'];
      } else {
        effectiveFilter = customerFilters.filterIds;
      }
    } else {
      // If user is not of NBS type, use customerPayload
      if (customerFilters.filterIds.length === 0){
        effectiveFilter = customerIds
      } else {
        effectiveFilter = customerFilters.filterIds;  
      }
      
    }
      const results = await getDirectCalibrationReports({
        customerIds: effectiveFilter,
        //nbsCustomerNames: customerFilters.nbsCustomerNames,
        from: customerFilters.dateFrom,
        to: customerFilters.dateTo,
        currentPage: customerFilters.currentPage,
        perPage: customerFilters.perPage,
        keyword: customerFilters.keyword,
        types:customerFilters.equipmentTypes,
        sortColumn:sortColumnConfig.key,
        sortDirection:sortColumnConfig.direction,
      })
      
      setLabratoryReports(results.data.data)
      setTotalNumber(results.data.pagination.total)
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching assets:', error);
      setIsLoading(false)
    }
}
useEffect(() => {
  if(customerFilters.hasLoaded)
    {
      fetchData();
    }
}, [customerFilters])

useEffect(() => {
  const customerData = auth.user?.customerIds;
  setIsNBS(levelHelper.isNBS(auth.user?.type))

  setCustomerFilters(prevState => ({
    ...prevState,
    isNBS: levelHelper.isNBS(auth.user?.type), // Change `isNBS` to true
    customerIds: customerData?.map(item => item.customerId) || [],
    nbsCustomerNames: nbsCustomerNames,    
    perPage: perPage,
    keyword: keyword,
    filterIds: filterIds,
    currentPage: currentPage,
    hasLoaded: true,
  }));
}, [auth, currentPage, perPage, filterIds, /*nbsCustomerNames,*/ keyword, sortColumnConfig])

  const handleClose = () => {
    setOpen(false)
  }

  const handleAttachmentModal = useCallback((data: CalibrationReportModel) => {
    setAttachment([])
    setShowAttachmentModal(true)
    setAttachQuoteId(data.dbkey)
    console.log("HERE!")
    console.log("info", {dateofcal: data.dateofcal, customerid: data.customerid})
    getOtherCalReportAttachments({ dateofcal: data.dateofcal, customerid: data.customerid }).then(
      (res) => {
        console.log("res", res)
        let attachments: fileType[] = []

        res.data.map((item: ICalibrationReportAttachment, index1: number) => {
          JSON.parse(item?.attachment as string).map((name: string, index2: number) => {
            attachments.push({
              id: `${index1}-${index2}`,
              name: name,
              size: 0,
              type: 'old',
              checked: false,
              cal_date: item.calibration_date,
              cal_type: item.calibration_type,
              author: item.technician_fullname,
              quoteId: item.id,
            })
            console.log(attachments[attachments.length-1])
          })
        })

        //setAttachment(attachments)
        setAttachment((prev: any) => [...prev, ...attachments]);
      }
    )
    setFileInfo(data)
  }, [])

  const handleEdit = (data: any) => {
    setType('edit')
    setOpen(true)
    setEditData(data)
  }

   const handleCount = (row: CalibrationReportModel) => {
    let count =  JSON.parse(row.attachment as string).length 
    //  if (row.otherAttachment.length > 0) { 
    //    row.otherAttachment.map((f: ICalibrationReportAttachment, index: number) => {
    //      count += JSON.parse(f.attachment).length
    //    })
    //  }
     return count
}

const checkUserAuth = async (): Promise<boolean> => {
      
  try {
    await checkAuth(() => {
      // Authentication successful
      console.log('Authentication successful')
    })
    return true
  } catch (error) {
    // Authentication failed
    console.error('Authentication failed', error)
    return false
  }
}

const handleClick = async () => {
  const isAuthenticated = await checkUserAuth()
  if (isAuthenticated) {
    setType('add')
    setOpen(true)
  }
}

const handleColumnSort = (column: string) => {
  setSortColumnConfig((prevConfig) => {
    // Determine the new direction based on the previous configuration
    if(showSortArrows == false) {setShowSortArrows(true)}
    let newDirection = 'ASC';
    if (prevConfig.key === column && prevConfig.direction === 'ASC') {
      newDirection = 'DESC';
    }

    // Log the previous configuration and the new values for debugging
    console.log(prevConfig.key, "==", column, "\n", prevConfig.direction, "==", newDirection);
    console.log(prevConfig.key === column, "\n", prevConfig.direction === newDirection);

    // Return the new configuration object
    return { key: column, direction: newDirection };
  });
}

/*
const handleClick = async () => {
  const isAuthenticated = await toast.error("This is currently disabled!!")
}*/

  const columns: TableColumn<CalibrationReportModel>[] = useMemo(
    () => [
      {
        name: 'Actions',
        cell: (row) => (
          <Badge
          variant='standard'
          badgeContent={row.count > 0 ? '+' : "+"}
          color='secondary'
        >
            <ActionDropDown
              isNBS={isNBS}
              data={row}
              handleAttachmentModal={handleAttachmentModal}
              handleEdit={handleEdit}
            />
          </Badge>
        ),
        width: '200px',
        style: {
          minWidth: '200px !important',
          maxWidth: '200px !important',
        },
      },
      {
        name: (
          <div onClick={() => handleColumnSort('dateOfCal')}
          style={{ cursor: 'pointer' }}
          >
            Month Calibrated
            {sortColumnConfig.key === 'dateOfCal' && showSortArrows && (
              sortColumnConfig.direction === 'ASC' ? ' ▲' : ' ▼'
            )}
          </div>
        ),
        selector: (row: CalibrationReportModel) => row.dateofcal,
        cell: (row: CalibrationReportModel) => <span>{moment(row.dateofcal).format('MMM - YYYY')}</span>,
        //sortable: true,
        width: '20%',
        style: {
          minWidth: '20% !important',
          maxWidth: '20% !important',
        },
      },
      {
        name: (
          <div onClick={() => handleColumnSort('customerId')}
          style={{ cursor: 'pointer' }}>
            Customer ID
            {sortColumnConfig.key === 'customerId' && showSortArrows && (
              sortColumnConfig.direction === 'ASC' ? ' ▲' : ' ▼'
            )}
          </div>
        ),
        selector: (row: CalibrationReportModel) => row.customerid,
        cell: (row: CalibrationReportModel) => <span>{row.customerid}</span>,
        //sortable: true,
        
        width: '20%',
        style: {
          minWidth: '20% !important',
          maxWidth: '20% !important',
        },
      },
      {
        name: (
          <div onClick={() => handleColumnSort('customerName')}
          style={{ cursor: 'pointer' }}>
            Customer Name
            {sortColumnConfig.key === 'customerName' && showSortArrows && (
              sortColumnConfig.direction === 'ASC' ? ' ▲' : ' ▼'
            )}
          </div>
        ),
        selector: (row: CalibrationReportModel) => row.customer,
        cell: (row: CalibrationReportModel) => <span>{row.customer}</span>,
        //sortable: true,
        width: '30%',
        style: {
          minWidth: '30% !important',
          maxWidth: '30% !important',
          justifyContent: 'flex-start !important',
        },
      },
    ],
    [isNBS, sortColumnConfig]
  )

  return (
    <>
      <PageTitle breadcrumbs={[]}>Calibration Reports</PageTitle>
      <Card className='min-vh-100'>
        <Card.Header className='border-0 pt-6'>
          <Card.Title>
          <div className='d-flex align-items-center position-relative my-1'>
              <KTSVG
                path='/media/icons/duotone/General/Search.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                type='text'
                data-kt-customer-table-filter='search'
                className='form-control form-control-solid w-250px ps-15 border-0'
                placeholder='Search asset'
                //onChange={(e) =>setSlug(e.target.value)}
                onChange={(e: any) => {
                  debouncedHandleSearch(e.target.value);
                  setKeywordSearchTerm(e.target.value);}}
              />
              <button className='btn btn-primary' onClick={handleSearchManual}>
                Search
              </button>
            </div>
          </Card.Title>
          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-customer-table-toolbar='base'>
              <div className='filter-dropdown ms-3'>
                {isNBS && (
                  <Button
                    style={{ marginRight: '8px' }}
                    size='large'
                    variant='outlined'
                    color='primary'
                    startIcon={
                        <KTSVG
                            path='/media/icons/duotone/Interface/Plus-Square.svg'
                            className='svg-icon-2 text-light'
                        />
                    }
                    onClick={handleClick}
                >
                    Upload Reports
                </Button>
                )}
                <button
                  type='button'
                  onClick={() => {
                    setOpenFilter(true)
                  }}
                  className='btn btn-light-primary me-3'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Filter options'
                  ref={filterRef}
                >
                  <KTSVG
                    path='/media/icons/duotone/Text/Filter.svg'
                    className='svg-icon-2 text-light'
                  />
                  Filter
                </button>
                <FilterDropDown
                  open={openFilter}
                  handleFilter={handleFilter}
                  handleCancelCustomer={handleCancelCustomer}
                />
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body className='pt-0 calibration-reports-body'>
          <DataTableBase
            columns={columns}
            data={labratoryReports}
            progressPending={isLoading}
            pagination
            paginationTotalRows={totalNumber}
            paginationPerPage={perPage}
            paginationDefaultPage={1}
            paginationServer
            sortServer
            onChangePage={(m, n) => {
              setCurrentPage(m)
            }}
            onChangeRowsPerPage={(e, n) => {
              setPerPage(e)
            }}
          />
        </Card.Body>
      </Card>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='sm'>
        <AddCalReportModal open={open} handleClose={handleClose} data={editData} type={type} />
      </Dialog>
      <CalibrationAttachmentModal
        labFileInfo={fileInfo}
        show={showAttachmentModal}
        onHide={() => setShowAttachmentModal(false)}
        data={attachment}
        quoteId={attachQuoteId}
        isNBS={isNBS}
        getQuotes={(fetchData)}
      />
    </>
  )
}

export default connector(CalibrationReportsPage)
//export default CalibrationReportsPage